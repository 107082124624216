import "./Interacted.css"
import { baseUrl } from "../../../Config/env";
import { useEffect,useState } from "react";
import {usersInteraction} from '../../../Services/Storys/Storys'
import InlineLoader from "../../Loader/InlineLoader";
import {utils,writeFile} from 'xlsx';


const Interacted = (props) => {
    console.log(props);
    const getExcelData = () => {
    // Assuming your data is stored in the `data` array
    const formattedData = usersInteracted.map(item => {
        return [
        item.id,
        `https://www.facebook.com/${item.id}`,
        item.name,
        // Add more columns as needed
        ]
    });

    // Add a header row to your Excel file
    const headers = [
        'Facebook ID',
        'Facebook Profile Link',
        'Name',
        'Label Name',
        'Tags',
        'Email'
        // Add more headers as needed
    ];

    return [headers, ...formattedData];
    }

    const [usersInteracted,setUsersInteracted] = useState([])
    const [isLoading,setLoading] = useState(false)
    const loadUsersInteracted = async () => {
        setLoading(true)
        let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
        if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
            let payload = {
                lsd_token : userInfo.loggedInUserInfo.lsd_token,
                fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
            }
      
            usersInteraction(payload).then(res =>{
                setLoading(false)
              console.log("response ______",res)
              // alert("hy")
                // setLoading(false)
      
                // console.log("response sidepanel automation insights ",res.data.data)
                // setSyncing(false)
                if(res.data.data.status){
                  setUsersInteracted(res.data.data.data)
                }
            })
        }else{
            // setLoading(false)
            // setSyncing(false)
        }
      }
      
    useEffect(()=>{
        loadUsersInteracted()
        },[])
    return (
        
        <div className={props?.addClass ? `${props.addClass} side-modal-container` : "side-modal-container"}>
               
            <div className="side-modal-overlay"></div>
            <div className="side-modal-outer d-flex f-column  side-modal-shrinked">
                <div className="side-modal-header d-flex f-justify-between f-align-center">
                    <h4>
                        Users Interacted with stories
                    </h4>
                    <button
                        className="btn btn-close-modal"
                        onClick={()=>props.modal(false)}
                    >
                        <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                            fill="white"
                        />
                        </svg>
                    </button>
                </div>
                
                {/* <button >
                Export to Excel
                </button> */}

                <div className="side-modal-body f-1 scroll-ui-thick o-a">
                    <div className="side-modal-inner d-flex f-column">
                        <div className="table table-sortable">
                            <div className="table-header">
                                <div className="table-row">
                                    <div className="table-cell table-cell-header">User <span className="header-sort">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1003_1641)">
                                                <path d="M9.00012 4.3725L11.3776 6.75L12.4351 5.6925L9.00012 2.25L5.55762 5.6925L6.62262 6.75L9.00012 4.3725ZM9.00012 13.6275L6.62262 11.25L5.56512 12.3075L9.00012 15.75L12.4426 12.3075L11.3776 11.25L9.00012 13.6275Z" fill="#323232"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1003_1641">
                                                    <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg></span>
                                        {isLoading?"": 
                                        usersInteracted.length == 0?<div className="exportButton">No data to export</div>:
                                        // <button className="exportButton">Exort to CSV</button>       
                                        <img  className="exportButton" onClick={() => {
                                                        const worksheet = utils.aoa_to_sheet(getExcelData());
                                                        const workbook = utils.book_new();
                                                        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                                                        writeFile(workbook, 'lsd_unique_users.xlsx');
                                                        }}
                                                         style={{"maxHeight":'30px','maxWidth':'30px'}} src="../images/export.png">
                                                </img>
                                            }
                                    </div>
                                    
                                    {/* {!isLoading && usersInteracted.length == 0?"No data to export":
                                    ""} */}
                                    
                                    
                                   { }
                                </div>
                                
                            </div>
                            <div className="table-body">
                           
                               {usersInteracted!=undefined && usersInteracted.length?usersInteracted.map(top=>{
                                return(
                                    // active
                                    <div className="table-row ">
                                    <div className="table-cell">
                                        <div className="table-cell-content d-flex">
                                            <figure className="table-user-image" style={{
                                                backgroundImage: `url(./images/user-image-1.png)`
                                            }}>
                                                <span className="user-status available"></span>
                                            </figure>

                                            <div className="table-cell-textBody">
                                                 <a className="anchorName" href={baseUrl+"/user-details/"+top.id}  target="_blank">{top.name}</a>

                                                <span className="active-tick"></span>

                                                <p>
                                                <a
                        href={"https://www.facebook.com/"+ top.id}
                        target="_blank"
                        rel="noreferrer"
                      >
                        facebook.com/{top.name}
                        <svg
                          className="open-link"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.25 1.75V1.25H12.75V1.75H12.25ZM6.77022 7.93689C6.57496 8.13215 6.25837 8.13215 6.06311 7.93689C5.86785 7.74163 5.86785 7.42504 6.06311 7.22978L6.77022 7.93689ZM11.75 6.41667V1.75H12.75V6.41667H11.75ZM12.25 2.25H7.58333V1.25H12.25V2.25ZM12.6036 2.10355L6.77022 7.93689L6.06311 7.22978L11.8964 1.39645L12.6036 2.10355Z"
                            fill="#7D8FB3"
                          />
                          <path
                            d="M11.6667 8.75004V8.75004C11.6667 10.3609 10.3609 11.6667 8.75008 11.6667H7.00008C5.44558 11.6667 4.66833 11.6667 4.06556 11.3856C3.42634 11.0876 2.91257 10.5738 2.61449 9.93456C2.33342 9.33179 2.33342 8.55454 2.33342 7.00004V5.25004C2.33342 3.63921 3.63925 2.33337 5.25008 2.33337V2.33337"
                            stroke="#7D8FB3"
                            strokeLinecap="round"
                          />
                        </svg>
                      </a>
                                                   
                                                </p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                )
                               }):""}
                               
                            </div>
                        </div>
                       
                        { isLoading? <div className="loader-inline">Loading ...</div>:""    }
                        {!isLoading && usersInteracted.length == 0?<div className="no-content-listing">
                            <p>No data found</p>
                        </div>:""}
                    </div>
                </div>
           
            </div>

        </div>
    );
};

export default Interacted;