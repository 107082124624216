import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import SidePanel from "../Sidepanel/SidePanel";
import Story from "../Story/Story";
import { Storys } from "../../Services/Storys/Storys";
import { talkToExtension } from "../../Services/ChromeStore/ChromeStore";
import Loader from "../Loader/Loader";
import Config from "../../Config/env"
const Home = () => {
    document.title = `${Config.name} - Dashboard`;
    const [isLoggedIn,setIsLoggedIn] = useState(true)
    const [isLoading,setLoading] = useState(true)
    const [topEngagersModal, setTopEngagersModal] = useState(false)
    const [stories,setStories] = useState([])
    const [totalNoOfStories,setTotalStoriesCount] = useState(0)
    const [vitamins, setVitamins] = useState({
        "appName": "Let Story Drive",
        "logo": "./images/logo.png",
        "extensionId": "",
        "changePasswordUrl": "",
        "backendUrl": "https://letstorydrive.io",
        "mailTo": "",
        "description": {
            "short_description": "",
            "long_description": ""
        },
        preLoader: '',
        secondaryLogo : "./images/logo-in.png"
    })

    const [isSyncing,setSyncing] = useState(false)

    const getVitamins = () => {
        if(Object.keys(JSON.parse(localStorage.getItem('vitaminsForPortal'))).length){
            let vitamins = JSON.parse(localStorage.getItem('vitaminsForPortal'))
            document.title = `${vitamins.appName} - Dashboard`;
            setVitamins(vitamins)
        }
    }

    const onLogout = () => {
        localStorage.clear('_lsd_token')
        setIsLoggedIn(false)
    }

    const sync = () => {
        setSyncing(true)
        talkToExtension('sync',extension=>{
            if(extension?.response?.response?.type == "error"){
                alert(extension?.response?.response?.data?.error)
            }else{
                alert(extension?.response?.response?.data?.success)
                setTimeout(()=>{
                    loadAllStorys()
                },30000)

            }
            console.log("respnse from sync",extension?.response?.response?.data)
        })
        // setLoading(true)
    }

    const loadAllStorys = async () => {
        let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
        if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
            let payload = {
                lsd_token : userInfo.loggedInUserInfo.lsd_token,
                fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId
            }
            console.log("payload fetch all storys",payload)
            Storys(payload).then(res =>{
            setLoading(false)

                console.log("response in HOME ",res.data.data)
                setSyncing(false)
                if(res.data.data.status)
                setStories(res.data.data.payload.stories)
                setTotalStoriesCount(res.data.data.payload.totalNoOfStories)
            })
            setLoading(false)
        }else{
            setLoading(false)
            setSyncing(false)
        }
    }

    useEffect(()=>{
        getVitamins()
        loadAllStorys()

    },[])

    return(
        <>
      
        {!isLoggedIn?   <Navigate to="/login" replace={true} />: ''}
        {/* <button onClick={onLogout}>SignOUT</button> */}
        {/* <div className="main-container vh-100 d-flex f-column">
            <Header
                onLogout={onLogout}
            ></Header> */}
      
                {isLoading?<Loader loaderImg={vitamins.preLoader}/>:''}
                <div className="content-container-main d-flex f-1">
                    <div className="content-space f-1">
                        <div className="header-content-container d-flex">
                            <div className="header-content">
                                <h3>Stories</h3>
                                <span className="num-header">{totalNoOfStories}</span>
                            </div>
                            <div className="header-content-options">
                                <div className="date-range-select">

                                </div>
                                {/* <button onClick={sync} className={isSyncing?"btn btn-gradient btn-header-main btn-sync-title syncing":"btn btn-gradient btn-header-main btn-sync-title"}> *Class - syncing FOR LOADING PHASE
                                    <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 10L5.70711 9.29289L5 8.58579L4.29289 9.29289L5 10ZM9.70711 13.2929L5.70711 9.29289L4.29289 10.7071L8.29289 14.7071L9.70711 13.2929ZM4.29289 9.29289L0.292893 13.2929L1.70711 14.7071L5.70711 10.7071L4.29289 9.29289Z" fill="white"/>
                                        <path d="M15.5 18.0622C14.4359 18.6766 13.2288 19 12 19C10.7712 19 9.56413 18.6766 8.5 18.0622C7.43587 17.4478 6.5522 16.5641 5.93782 15.5C5.32344 14.4359 5 13.2288 5 12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        <path d="M19 14L18.2929 14.7071L19 15.4142L19.7071 14.7071L19 14ZM14.2929 10.7071L18.2929 14.7071L19.7071 13.2929L15.7071 9.29289L14.2929 10.7071ZM19.7071 14.7071L23.7071 10.7071L22.2929 9.29289L18.2929 13.2929L19.7071 14.7071Z" fill="white"/>
                                        <path d="M8.5 5.93782C9.56413 5.32344 10.7712 5 12 5C13.2288 5 14.4359 5.32344 15.5 5.93782C16.5641 6.5522 17.4478 7.43587 18.0622 8.5C18.6766 9.56414 19 10.7712 19 12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                    </span>
                                    Sync</button> */}
                            </div>
                        </div>
                        <div className="dash-main-content dashboard-content">
                        <div className="stories-container">
                         {stories.length>0?stories.map((x,i)=>{
                            return (
                                <Story key={i} info={x}/>
                            )
                         }):'No Stories to show'}
                         </div>
                        </div>
                    </div>
                    
                    <SidePanel
                        setTopEngagersModal={setTopEngagersModal}
                    />
                </div>
                
               
        {/* </div> */}
        </>
    )
}



export default Home