import "./topTwenty.css"
import { baseUrl } from "../../../Config/env";
import { useEffect,useState } from "react";
import {topTwentyUsers} from '../../../Services/Storys/Storys'


const TopTwenty = (props) => {
    console.log(props);
const [isTopTwentyInsightLoading,setIsTopTwentyInsightLoading] = useState(false)
const [ topTwenty,setTopTwentyUsers] =useState([])
const loadTop20Users = async () => {
  setIsTopTwentyInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
      }

      topTwentyUsers(payload).then(res =>{
        setIsTopTwentyInsightLoading(false)
        console.log("response ______",res)
        // alert("hy")
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setTopTwentyUsers(res.data.data.data)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}

  useEffect(()=>{
    loadTop20Users()
  },[])

    return (
        <div className={props?.addClass ? `${props.addClass} side-modal-container` : "side-modal-container"}>
            <div className="side-modal-overlay"></div>
            <div className="side-modal-outer d-flex f-column">
                <div className="side-modal-header d-flex f-justify-between f-align-center">
                    <h4>
                        Top 20 engagers
                    </h4>
                    <button
                        className="btn btn-close-modal"
                        onClick={()=>props.modal(false)}
                    >
                        <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                            fill="white"
                        />
                        </svg>
                    </button>
                </div>
                <div className="side-modal-body f-1 scroll-ui-thick o-a">
                    <div className="side-modal-inner">
                        <div className="table table-sortable">
                            <div className="table-header">
                                <div className="table-row">
                                    <div className="table-cell table-cell-header">User<span className="header-sort">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1003_1641)">
                                                <path d="M9.00012 4.3725L11.3776 6.75L12.4351 5.6925L9.00012 2.25L5.55762 5.6925L6.62262 6.75L9.00012 4.3725ZM9.00012 13.6275L6.62262 11.25L5.56512 12.3075L9.00012 15.75L12.4426 12.3075L11.3776 11.25L9.00012 13.6275Z" fill="#323232"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1003_1641">
                                                    <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg></span>
                                    </div>
                                    <div className="table-cell table-cell-header">Total reactions given<span className="header-sort">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            {/* <g clipPath="url(#clip0_1003_1641)">
                                                <path d="M9.00012 4.3725L11.3776 6.75L12.4351 5.6925L9.00012 2.25L5.55762 5.6925L6.62262 6.75L9.00012 4.3725ZM9.00012 13.6275L6.62262 11.25L5.56512 12.3075L9.00012 15.75L12.4426 12.3075L11.3776 11.25L9.00012 13.6275Z" fill="#323232"/>
                                            </g> */}
                                            <defs>
                                                <clipPath id="clip0_1003_1641">
                                                    <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg></span>
                                    </div>

                                    <div className="table-cell table-cell-header">Total votes given<span className="header-sort">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            {/* <g clipPath="url(#clip0_1003_1641)">
                                                <path d="M9.00012 4.3725L11.3776 6.75L12.4351 5.6925L9.00012 2.25L5.55762 5.6925L6.62262 6.75L9.00012 4.3725ZM9.00012 13.6275L6.62262 11.25L5.56512 12.3075L9.00012 15.75L12.4426 12.3075L11.3776 11.25L9.00012 13.6275Z" fill="#323232"/>
                                            </g> */}
                                            <defs>
                                                <clipPath id="clip0_1003_1641">
                                                    <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg></span>
                                    </div>
                                    <div className="table-cell table-cell-header">Total interacted stories<span className="header-sort">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            {/* <g clipPath="url(#clip0_1003_1641)">
                                                <path d="M9.00012 4.3725L11.3776 6.75L12.4351 5.6925L9.00012 2.25L5.55762 5.6925L6.62262 6.75L9.00012 4.3725ZM9.00012 13.6275L6.62262 11.25L5.56512 12.3075L9.00012 15.75L12.4426 12.3075L11.3776 11.25L9.00012 13.6275Z" fill="#323232"/>
                                            </g> */}
                                            <defs>
                                                <clipPath id="clip0_1003_1641">
                                                    <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg></span>
                                    </div>
                                    <div className="table-cell table-cell-header">Messenger Link<span className="header-sort">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            {/* <g clipPath="url(#clip0_1003_1641)">
                                                <path d="M9.00012 4.3725L11.3776 6.75L12.4351 5.6925L9.00012 2.25L5.55762 5.6925L6.62262 6.75L9.00012 4.3725ZM9.00012 13.6275L6.62262 11.25L5.56512 12.3075L9.00012 15.75L12.4426 12.3075L11.3776 11.25L9.00012 13.6275Z" fill="#323232"/>
                                            </g> */}
                                            <defs>
                                                <clipPath id="clip0_1003_1641">
                                                    <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg></span>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="table-body">
                               {topTwenty!=undefined && topTwenty.length?topTwenty.map((top,i)=>{
                                return(
                                    // active
                                    <div className="table-row">
                                    <div className="table-cell">
                                        <div className="table-cell-content d-flex">
                                            <figure className="table-user-image" style={{
                                                backgroundImage: `url(../images/user-image-1.png)`
                                            }}>
                                                <span className="user-status available"></span>
                                            </figure>

                                            <div className="table-cell-textBody">
                                            <span className="engager-badge top-engager-badge">
                                                    Top {i+1} Engager
                                                </span>
                                                <div></div>
                                                 <a className="anchorName" href={baseUrl+"/user-details/"+top.id}  target="_blank">{top.name}</a>

                                                {/* <span className="active-tick"></span> */}
                                                
                                                <p>
                                                <a
                        href={"https://www.facebook.com/"+ top.id}
                        target="_blank"
                        rel="noreferrer"
                      >
                        facebook.com/{top.name}
                        <svg
                          className="open-link"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.25 1.75V1.25H12.75V1.75H12.25ZM6.77022 7.93689C6.57496 8.13215 6.25837 8.13215 6.06311 7.93689C5.86785 7.74163 5.86785 7.42504 6.06311 7.22978L6.77022 7.93689ZM11.75 6.41667V1.75H12.75V6.41667H11.75ZM12.25 2.25H7.58333V1.25H12.25V2.25ZM12.6036 2.10355L6.77022 7.93689L6.06311 7.22978L11.8964 1.39645L12.6036 2.10355Z"
                            fill="#7D8FB3"
                          />
                          <path
                            d="M11.6667 8.75004V8.75004C11.6667 10.3609 10.3609 11.6667 8.75008 11.6667H7.00008C5.44558 11.6667 4.66833 11.6667 4.06556 11.3856C3.42634 11.0876 2.91257 10.5738 2.61449 9.93456C2.33342 9.33179 2.33342 8.55454 2.33342 7.00004V5.25004C2.33342 3.63921 3.63925 2.33337 5.25008 2.33337V2.33337"
                            stroke="#7D8FB3"
                            strokeLinecap="round"
                          />
                        </svg>
                      </a>
                                                   
                                                </p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="table-cell">
                                        <div className="table-cell-content">
                                            <p>{top.totalReactionsGiven?top.totalReactionsGiven:"N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="table-cell">
                                        <div className="table-cell-content">
                                            <p>{top.poll_count?top.poll_count:"N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="table-cell">
                                        <div className="table-cell-content">
                                            <p>{top.uniqueViews}</p>
                                        </div>
                                    </div>
                                    <div className="table-cell">
                                        <div className="table-cell-content">
                                           
                                         <a className="anchorName"  href={"https://www.facebook.com/messages/t/"+ top.id}  target="_blank"> <img className="Micon" src="../images/messengerIcon.png"></img></a>
                                       
                                            {/* <p>{top.interaction_count}</p> */}
                                        </div>
                                    </div>
                                </div>
                                )
                               }):""}
                            </div>
                        </div>

                        {topTwenty.length == 0 && !isTopTwentyInsightLoading?<div className="no-content-listing">
                            <p>No data to display</p>
                        </div>:""}
                       {isTopTwentyInsightLoading? <div className="loader-inline">Loading ...</div>:""}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopTwenty;