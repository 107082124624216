import { useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import Config from '../../Config/env'
import ReplyImage from "../../assets/images/message-group-reply.png";
import UserImage from "../../assets/images/user.png";
import { fetchAllMessageSegments, createSegments, fetchAllMessageGroups, createMessageGroups, deleteMS, deleteMG } from '../../Services/Messages/Messages'

const MessageSegmentGroup = () => {
  document.title = `${Config.name} - Message Segments/Groups`
  const newMessageInput = useRef(null);

  /**Message Segment props and fucntions coded from here */
  const [addMessageItem, setAddMessageItem] = useState(null)
  const [messageSegments, setMessageSegments] = useState([])
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [messageActive, setMessageActive] = useState("segment");
  const [modal, setModal] = useState({
    segment: false,
    group: false,
    editable: false
  });
  const [mSegmentInputs, setInputMessageBlock] = useState({
    title: "",
    messageBlock: "",
    _id: null
  })
  const [messageSegmentBlocks, setMessageSegmentsBlock] = useState([])
  const [mSOptions, setMSOptions] = useState([
    "{First Name}",
    "{Last Name}",
    "{Option A}",
    "{Option B}"
  ])


  const onSegmentChange = (e, type) => {
    setInputMessageBlock({ ...mSegmentInputs, [type]: e.target.value })
  }

  const addMSOption = (e, option, type = "segment") => {
    e.preventDefault()
    if (type == "group") {
      setMGroupInputs({ ...mGroupInputs, customText: mGroupInputs.customText + " " + option })
    } else {
      setInputMessageBlock({ ...mSegmentInputs, messageBlock: mSegmentInputs.messageBlock + " " + option })
    }
  }

  const onMSAdd = (e) => {
    e.preventDefault()
    if (!mSegmentInputs.messageBlock.length) {
      return alert("Sorry, empty message block can't be added")
    }
    setMessageSegmentsBlock([...messageSegmentBlocks, mSegmentInputs.messageBlock])
    setInputMessageBlock({ ...mSegmentInputs, messageBlock: "" })
  }

  const loadMessageSegments = async () => {
    console.log("Message Segments loaded")
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))

    if (userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId) {
      let payload = {
        lsd_token: userInfo.loggedInUserInfo.lsd_token

      }

      fetchAllMessageSegments(payload).then(res => {
        // setLoading(false)

        console.log("response in msegent ", res.data.data)
        // setSyncing(false)
        if (res.data.data.status) {
          let data = res.data.data.payload.data
          // setMessageGroupListingInfo([...messageGroupInfo, ...data ])
          setMessageSegments(data)
        }
      })
    }
  }

  const createMessageSegment = async (e) => {
    e.preventDefault()
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))

    if (userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId) {
      let payload = {
        lsd_token: userInfo.loggedInUserInfo.lsd_token,
        title: mSegmentInputs.title,
        message_blocks: messageSegmentBlocks,
        segment_id: mSegmentInputs._id != null ? mSegmentInputs._id : undefined
      }

      if (!mSegmentInputs.title || !messageSegmentBlocks.length)
        return alert("Title or Message blocks can't be empty")

      createSegments(payload).then(res => {
        if (res.data.data.status) {
          let data = res.data.data.payload.data
          setModal({ ...modal, segment: false, group: false, editable: false })
          loadMessageSegments()
        }
      })
    }
  }

  const deleteMessageSegment = async (e) => {
    e.preventDefault()
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))

    if (userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId) {
      let payload = {
        lsd_token: userInfo.loggedInUserInfo.lsd_token,
        segment_id: mSegmentInputs._id != null ? mSegmentInputs._id : undefined
      }

      if (!mSegmentInputs.title || !messageSegmentBlocks.length)
        return alert("There is an error while deleting the message segment")

      deleteMS(payload).then(res => {
        if (res.data.data.status) {
          // alert("deleted message segment successfully")
          setInputMessageBlock({  title: "",
          messageBlock: "",
          _id: null})
          if(searchKeyword){
            setSearchKeyword("")
            setShowSearchedData(false)
            setSearchFilteredData([])
          }
          
          setMessageSegmentsBlock([])
          loadMessageSegments()
        }
      })
    }
  }

  const deleteMessageGroup = async (e) => {
    e.preventDefault()
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))

    if (userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId) {
      let payload = {
        lsd_token: userInfo.loggedInUserInfo.lsd_token,
        group_id: mGroupInputs._id != null ? mGroupInputs._id : undefined
      }
      console.log("mgroupInput",mGroupInputs)
      if (!mGroupInputs.title)
        return alert("There is an error while deleting the message group")

      deleteMG(payload).then(res => {
        if (res.data.data.status) {
          setMGroupInputs({
            title: "",
            customText: "",
            _id: null
          })
          setAssociateBlocks([])

          if(searchKeyword){
            setSearchKeyword("")
            setShowSearchedData(false)
            setSearchFilteredData([])
          }
          loadMessageGroups()
        }
      })
    }
  }
  const onRemoveMsBlock = (e, block) => {
    e.preventDefault()
    console.log("on remove", block)

    const index = messageSegmentBlocks.indexOf(block);
    if (index > -1) { // only splice array when item is found
      let arrayOfBlock = messageSegmentBlocks
      arrayOfBlock.splice(index, 1)
      setMessageSegmentsBlock([...arrayOfBlock])  // 2nd parameter means remove one item only
    }

  }

  const onSelectMS = (e, segment) => {
    console.log("segmentsss", segment)
    setMessageSegmentsBlock(segment.message_blocks)
    setInputMessageBlock({ ...mSegmentInputs, title: segment.title, _id: segment._id })

  }

  const openModal = (segment = false, group = false, editable = false) => {
    setModal({ ...modal, segment: segment, group, editable })
    if (!editable && segment) {
      setMessageSegmentsBlock([])
      setInputMessageBlock({ ...mSegmentInputs, title: "", _id: null, messageBlock: "" })
    }
    if (!editable && group) {
      setAssociateBlocks([])
      setMGroupInputs({ ...mGroupInputs, title: "", _id: null, customText: "" })
    }
  }

  /**Message Segment props and fucntions ends here */

  const [searchKeyword, setSearchKeyword] = useState("")
  const [showSearchedData, setShowSearchedData] = useState(false)
  const [searchFilterdData, setSearchFilteredData] = useState([])

  const onSearch = (target, module) => {
    console.log("input", target.value, module)
    setSearchKeyword(target.value)
    if (target.value == "") {
      console.log("show default data")
      setShowSearchedData(false)
    }
    if (module == "segment" && target.value.length > 1) {
      setInputMessageBlock({  title: "",
          messageBlock: "",
          _id: null})
          setMessageSegmentsBlock([])
      setShowSearchedData(true)
      console.log("start searching")
      const result = messageSegments.filter(segment => segment.title.toLowerCase().includes(target.value.toLowerCase()));
      if (result) {
        setSearchFilteredData(result)
      } else {
        setSearchFilteredData([])
      }
    }

    if (module == "group" && target.value.length > 1) {
      setShowSearchedData(true)
      console.log("start searching")
      setMGroupInputs({
        title: "",
        customText: "",
        _id: null
      })
      setAssociateBlocks([])
      const result = messageGroupListing.filter(group => group.title.toLowerCase().includes(target.value.toLowerCase()));
      if (result) {
        console.log(result)
        setSearchFilteredData(result)
      } else {
        setSearchFilteredData([])
      }
    }
  }

  /**Message Group props and functions starts from here  */

  const [messageGroupListing, setMessageGroupListingInfo] = useState([])

  const [associateBlocks, setAssociateBlocks] = useState([])
  const [associateBlockInput, setAssociateBlockInput] = useState([])
  const [mGroupInputs, setMGroupInputs] = useState({
    title: "",
    customText: "",
    _id: null

  })
  const onSelectMG = (e, group) => {
    console.log("groupsss", group.associate_blocks)
    setAssociateBlocks(group.associate_blocks)
    setMGroupInputs({ ...mGroupInputs, title: group.title, _id: group._id })

  }

  const loadMessageGroups = async () => {
    console.log("Message groups details load")
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))

    if (userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId) {
      let payload = {
        lsd_token: userInfo.loggedInUserInfo.lsd_token
      }
      fetchAllMessageGroups(payload).then(res => {
        if (res.data.data.status) {
          let data = res.data.data.payload.data
          setMessageGroupListingInfo([...data])
        }
      })
    }
  }

  const addSegmentOrTextToBlock = async (e, segment = false) => {
    e.preventDefault()
    var payload = {
      value: "",
      type: ""
    }
    if (segment) {
      payload.value = segment._id
      payload.type = "id"
    } else {
      payload.value = mGroupInputs.customText
      payload.type = "text"
    }
    setAssociateBlockInput([...associateBlockInput, payload])
    setMGroupInputs({ ...mGroupInputs, customText: "" })
    console.log("associate block input", associateBlockInput)
  }

  const onMGroupInputChange = async (e) => {
    e.preventDefault()
    setMGroupInputs({ ...mGroupInputs, customText: e.target.value })
  }

  const onAddingGroupBlock = async (e) => {
    e.preventDefault()
    if (associateBlockInput.length) {
      setAssociateBlocks([...associateBlocks, associateBlockInput])
      setAssociateBlockInput([])
      setMGroupInputs({ ...mGroupInputs, customText: "" })



    } else {
      alert("Can't add an empty block")
    }
  }

  const removeMessageGroupSet = async (e, index, type = "input") => {
    e.preventDefault()
    console.log("index", index)
    if (type == "input") {
      associateBlockInput.splice(index, 1)
      setAssociateBlockInput([...associateBlockInput])
    } else {
      associateBlocks.splice(index, 1)
      setAssociateBlocks([...associateBlocks])
    }
  }
  const buildMessageSet = (inputBlock, index) => {
    console.log("Input blokc", inputBlock)
    let full_text = ""
    return (
      <div className="group-block">
        {/* {msgSegmentTitle("")} */}
        {
          inputBlock?.map((block, index) => {
            if (block.type == "id") {
              let segmentInfo = messageSegments?.find(segment => segment._id == block.value)
              full_text += `[${segmentInfo?.title}]`
            } else {
              full_text += `${block?.value}`
            }

          })
        }

        {full_text}

        <button onClick={(e) => { removeMessageGroupSet(e, index, "block") }} className="btn delete-message-block">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 6M11 11L6 6M6 6L11 1L1 11" stroke="#CFCFCF" strokeWidth="2"></path>
          </svg>
        </button>
      </div>
    )
  }

  const messageBlockInputRender = (inputBlock, index, edit = false) => {
    // console.log("fuckkkkk",inputBlock)

    let segmentBlock = ""
    if (inputBlock.type == "id") {
      let segmentInfo = messageSegments?.find(segment => segment._id == inputBlock.value)
      segmentBlock = segmentInfo?.title
    } else {
      segmentBlock = inputBlock?.value
    }
    return (

      <>

        {edit ? segmentBlock : <div className="group-block">



          {segmentBlock}
          <button onClick={(e) => { removeMessageGroupSet(e, index) }} className="btn delete-message-block">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L6 6M11 11L6 6M6 6L11 1L1 11" stroke="#CFCFCF" strokeWidth="2"></path>
            </svg>
          </button>



        </div>}
      </>
    )
  }

  const onCreateMessageGroup = async (e) => {
    e.preventDefault()
    if (!mGroupInputs.title || !associateBlocks.length) {
      return alert("Group title or Message blocks can't be empty")
    }
    console.log("payload", { title: mGroupInputs?.title, associate_blocks: associateBlocks })
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))

    if (userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId) {
      if (mGroupInputs?._id) {
        var payload = {
          lsd_token: userInfo.loggedInUserInfo.lsd_token,
          title: mGroupInputs?.title,
          associate_blocks: associateBlocks,
          group_id: mGroupInputs?._id
        }
      } else {
        var payload = {
          lsd_token: userInfo.loggedInUserInfo.lsd_token,
          title: mGroupInputs?.title,
          associate_blocks: associateBlocks
        }
      }

      createMessageGroups(payload).then(res => {
        // setLoading(false)

        console.log("response in group ", res.data.data)
        // setSyncing(false)
        if (res.data.data.status) {
          // alert("Message group created successfully")
          setModal('', '', '')
          let data = res.data.data.payload.data
          // setMessageGroupListingInfo([...messageGroupInfo, ...data ])
          setMessageSegments(data)
        }
        loadMessageSegments()
        loadMessageGroups()

      })
    }
  }

  const onChangeMessageGroup = (e) => {
    e.preventDefault()
    setMGroupInputs({ ...mGroupInputs, title: e.target.value })
  }


  useEffect(() => {
    loadMessageSegments()
  }, []);

  return (
    <>
      {!isLoggedIn ? <Navigate to="/login" replace={true} /> : ""}

      <div className="content-container-message d-flex f-1">
        <div className="content-space f-1 d-flex f-column">
          <header className="messages-header d-flex boxed boxed-padding f-justify-between f-align-center">
            <div className="messages-tabs-trigger">
              <button
                className={`message-tab-trigger segments-tab-trigger btn ${messageActive == "segment" ? "active" : ""}`}
                onClick={e => {
                  e.preventDefault();
                  loadMessageSegments()
                  setMessageActive('segment')
                  setSearchFilteredData([])
                  setShowSearchedData(false)
                  setSearchKeyword("")
                }}
              >
                Segments
              </button>
              <button
                className={`message-tab-trigger group-tab-trigger btn ${messageActive == "group" ? "active" : ""}`}
                onClick={e => {
                  e.preventDefault();
                  loadMessageGroups()
                  setMessageActive('group')
                  setSearchFilteredData([])
                  setShowSearchedData(false)
                  setSearchKeyword("")

                }}
              >
                Groups
              </button>
            </div>

            <div className="message-header-options d-flex f-align-center">
              <div className="search-container">
                <input type="search" value={searchKeyword} onChange={(e) => { onSearch(e.target, messageActive) }} placeholder="Search" />
              </div>
              {messageActive == "segment" &&
                <button className="btn btn-theme btn-blue btn-create" onClick={() => openModal(true, '', '')}>
                  Create New
                </button>}

              {messageActive == "group" &&
                <button className="btn btn-theme btn-blue btn-create" onClick={() => openModal('', true, "")}>
                  Create New
                </button>}
            </div>
          </header>
          <div className="messages-body f-1 d-flex">
            <div className="message-triggers message-segment-triggers d-flex f-column h-100">
              {messageActive == "segment" &&
                <header className="message-trigger-header d-flex f-align-center f-justify-between">
                  <h4>Message Segments <span className="message-count">{showSearchedData ? searchFilterdData.length : messageSegments?.length}</span></h4>
                </header>
              }
              {messageActive == "group" &&
                <header className="message-trigger-header d-flex f-align-center f-justify-between">
                  <h4>Message Groups <span className="message-count">{showSearchedData ? searchFilterdData.length : messageGroupListing?.length}</span></h4>
                </header>
              }
              {messageActive == "segment" && (<div className="message-triggersList f-1 scroll-ui-thick">
                {showSearchedData == false && messageSegments?.length ? messageSegments.map(segment => {
                  return (
                    <div onClick={(e) => onSelectMS(e, segment)} className={segment?._id == mSegmentInputs?._id ? "message-trigger active" : "message-trigger"}>
                      <h6>{segment?.title}</h6>
                      {/* <span className="timestamp-trigger">2 days ago</span> */}
                    </div>
                  )
                }) : ''}

                {showSearchedData == true && searchFilterdData?.length ? searchFilterdData.map(segment => {
                  return (
                    <div onClick={(e) => onSelectMS(e, segment)} className={segment?._id == mSegmentInputs?._id ? "message-trigger active" : "message-trigger"}>
                      <h6>{segment?.title}</h6>
                      {/* <span className="timestamp-trigger">2 days ago</span> */}
                    </div>
                  )
                }) : ''}


              </div>)}




              {/* Message Group listing  */}
              {messageActive == "group" && (<div className="message-triggersList f-1 scroll-ui-thick">

                {showSearchedData == false && messageGroupListing?.length ? messageGroupListing.map(group => {
                  return (
                    <div onClick={(e) => onSelectMG(e, group)} className={group?._id == mGroupInputs?._id ? "message-trigger active" : "message-trigger"}>
                      <h6>{group?.title}</h6>
                      {/* <span className="timestamp-trigger">2 days ago</span> */}
                    </div>
                  )
                }) : ''}

                {showSearchedData == true && searchFilterdData.length ? searchFilterdData.map(group => {
                  return (
                    <div onClick={(e) => onSelectMG(e, group)} className={group?._id == mGroupInputs?._id ? "message-trigger active" : "message-trigger"}>
                      <h6>{group?.title}</h6>
                      {/* <span className="timestamp-trigger">2 days ago</span> */}
                    </div>
                  )
                }) : ''}


              </div>)}

            </div>
            <div className="mesage-previews message-segment-previews d-flex f-column h-100 f-1">


              {/* Message Segment edit and delete button */}
              {messageActive == "segment" && (<header className="message-preview-header d-flex f-align-center f-justify-between">
                <h5>{mSegmentInputs?.title != "" ? mSegmentInputs.title : 'No Message Segment Selected'}</h5>
                {mSegmentInputs._id != null ? <div className="message-options message-segment-options">
                  <button className="btn btn-theme" onClick={() => openModal(true, '', true)}>
                    <span>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.2038 7.79595L15.9998 5.99994C16.545 5.45469 16.8176 5.18207 16.9634 4.88797C17.2407 4.32842 17.2407 3.67146 16.9634 3.11191C16.8176 2.81782 16.545 2.54519 15.9998 1.99994C15.4545 1.45469 15.1819 1.18207 14.8878 1.03633C14.3282 0.759048 13.6713 0.759048 13.1117 1.03633C12.8176 1.18207 12.545 1.45469 11.9998 1.99994L10.1811 3.8186C11.145 5.4692 12.5311 6.84476 14.2038 7.79595ZM8.72666 5.27305L1.85615 12.1436C1.43109 12.5686 1.21856 12.7812 1.07883 13.0422C0.939091 13.3033 0.880146 13.5981 0.762256 14.1875L0.14686 17.2645C0.0803376 17.5971 0.0470765 17.7634 0.141684 17.858C0.236293 17.9526 0.402598 17.9194 0.735208 17.8529L3.81219 17.2375C4.40164 17.1196 4.69637 17.0606 4.95746 16.9209C5.21856 16.7812 5.43109 16.5686 5.85615 16.1436L12.7456 9.25416C11.1239 8.23851 9.7522 6.87622 8.72666 5.27305Z" fill="#4D5E80" />
                      </svg>
                    </span>
                    Edit
                  </button>
                  <button className="btn btn-theme" onClick={async (e) => {
                    if (window.confirm("Are you sure you want to delete this segment?")) {
                      deleteMessageSegment(e)
                    }
                  }}>
                    <span>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 15L10 12" stroke="#4D5E80" strokeWidth="2" strokeLinecap="round" />
                        <path d="M14 15L14 12" stroke="#4D5E80" strokeWidth="2" strokeLinecap="round" />
                        <path d="M3 7H21V7C20.0681 7 19.6022 7 19.2346 7.15224C18.7446 7.35523 18.3552 7.74458 18.1522 8.23463C18 8.60218 18 9.06812 18 10V16C18 17.8856 18 18.8284 17.4142 19.4142C16.8284 20 15.8856 20 14 20H10C8.11438 20 7.17157 20 6.58579 19.4142C6 18.8284 6 17.8856 6 16V10C6 9.06812 6 8.60218 5.84776 8.23463C5.64477 7.74458 5.25542 7.35523 4.76537 7.15224C4.39782 7 3.93188 7 3 7V7Z" stroke="#4D5E80" strokeWidth="2" strokeLinecap="round" />
                        <path d="M10.0681 3.37059C10.1821 3.26427 10.4332 3.17033 10.7825 3.10332C11.1318 3.03632 11.5597 3 12 3C12.4403 3 12.8682 3.03632 13.2175 3.10332C13.5668 3.17033 13.8179 3.26427 13.9319 3.37059" stroke="#4D5E80" strokeWidth="2" strokeLinecap="round" />
                      </svg>
                    </span>
                    Delete
                  </button>
                </div> : ''}
              </header>)}


              {/* Message Group Edit and Delete button */}
              {messageActive == "group" && (<header className="message-preview-header d-flex f-align-center f-justify-between">
                <h5>{mGroupInputs?.title != "" ? mGroupInputs.title : 'No Message Group Selected'}</h5>
                {mGroupInputs._id != null ? <div className="message-options message-segment-options">
                  <button className="btn btn-theme" onClick={() => openModal('', true, true)}>
                    <span>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.2038 7.79595L15.9998 5.99994C16.545 5.45469 16.8176 5.18207 16.9634 4.88797C17.2407 4.32842 17.2407 3.67146 16.9634 3.11191C16.8176 2.81782 16.545 2.54519 15.9998 1.99994C15.4545 1.45469 15.1819 1.18207 14.8878 1.03633C14.3282 0.759048 13.6713 0.759048 13.1117 1.03633C12.8176 1.18207 12.545 1.45469 11.9998 1.99994L10.1811 3.8186C11.145 5.4692 12.5311 6.84476 14.2038 7.79595ZM8.72666 5.27305L1.85615 12.1436C1.43109 12.5686 1.21856 12.7812 1.07883 13.0422C0.939091 13.3033 0.880146 13.5981 0.762256 14.1875L0.14686 17.2645C0.0803376 17.5971 0.0470765 17.7634 0.141684 17.858C0.236293 17.9526 0.402598 17.9194 0.735208 17.8529L3.81219 17.2375C4.40164 17.1196 4.69637 17.0606 4.95746 16.9209C5.21856 16.7812 5.43109 16.5686 5.85615 16.1436L12.7456 9.25416C11.1239 8.23851 9.7522 6.87622 8.72666 5.27305Z" fill="#4D5E80" />
                      </svg>
                    </span>
                    Edit
                  </button>
                  <button className="btn btn-theme"onClick={async (e) => {
                    if (window.confirm("Are you sure you want to delete this group?")) {
                      deleteMessageGroup(e)
                    }
                  }}>
                    <span>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 15L10 12" stroke="#4D5E80" strokeWidth="2" strokeLinecap="round" />
                        <path d="M14 15L14 12" stroke="#4D5E80" strokeWidth="2" strokeLinecap="round" />
                        <path d="M3 7H21V7C20.0681 7 19.6022 7 19.2346 7.15224C18.7446 7.35523 18.3552 7.74458 18.1522 8.23463C18 8.60218 18 9.06812 18 10V16C18 17.8856 18 18.8284 17.4142 19.4142C16.8284 20 15.8856 20 14 20H10C8.11438 20 7.17157 20 6.58579 19.4142C6 18.8284 6 17.8856 6 16V10C6 9.06812 6 8.60218 5.84776 8.23463C5.64477 7.74458 5.25542 7.35523 4.76537 7.15224C4.39782 7 3.93188 7 3 7V7Z" stroke="#4D5E80" strokeWidth="2" strokeLinecap="round" />
                        <path d="M10.0681 3.37059C10.1821 3.26427 10.4332 3.17033 10.7825 3.10332C11.1318 3.03632 11.5597 3 12 3C12.4403 3 12.8682 3.03632 13.2175 3.10332C13.5668 3.17033 13.8179 3.26427 13.9319 3.37059" stroke="#4D5E80" strokeWidth="2" strokeLinecap="round" />
                      </svg>
                    </span>
                    Delete
                  </button>
                </div> : ''}
              </header>)}

              {/* Message Segment -> Display message segment content */}
              {messageActive == "segment" && (
                <div className="message-preview-body f-1 scroll-ui-thick">
                  {messageSegmentBlocks?.length ? messageSegmentBlocks.map(block => {
                    return (<div className="message-segment-item">
                      {block}
                    </div>)
                  }) : ""}

                </div>
              )}
              {/* Message Group -> Display message group content */}
              {messageActive == "group" && (
                <div className="message-preview-body f-1 scroll-ui-thick">
                  {/* <div className="message-empty-area">
                                  Please select a message Group to proceed
                              </div> */}
                  <div className="message-preview-group d-flex">
                    {associateBlocks?.map(block => {
                      return (
                        <div className="new-message-group d-flex">
                          <div className="message-segment-item">
                            {block?.map((data, i) => {
                              return (messageBlockInputRender(data, i, true))
                            })}
                          </div>
                          <figure
                            className="user-image"
                            style={{
                              backgroundImage: `url(${UserImage})`,
                            }}
                          ></figure>
                        </div>
                      )
                    })}
                    <div className="message-group-reply">
                      <img src={ReplyImage} alt="" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {modal.segment && (
        <div className="message-modal modal-general">
          <div className="modal-overlay"></div>
          <div className="modal-outer message-modal-outer">
            <div className="modal-header d-flex f-justify-between f-align-center">
              <h4>{modal.editable ? 'Update Message Segments' : 'Create Message Segments'}</h4>
              <button
                className="btn btn-close-modal"
                onClick={(e) => {
                  e.preventDefault();

                  setModal({
                    segment: false,
                    group: false,
                  });
                }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-container">
                <form>
                  <fieldset className="form-group">
                    <legend className="form-label">
                      Enter your message segment title
                    </legend>
                    <div className="form-element">
                      <input
                        className="form-control"
                        type="text"
                        value={mSegmentInputs.title}
                        onChange={(e) => { onSegmentChange(e, "title") }}
                        placeholder="Segment title"
                      />
                    </div>
                  </fieldset>
                  <fieldset className="form-group form-message-block">
                    <legend className="form-label">
                      Create message block (s)
                    </legend>
                    <div className="form-message-blocks">
                      {messageSegmentBlocks.length ? messageSegmentBlocks.map(msgBlock => {
                        return (<div className="message-block">
                          {msgBlock}
                          <button onClick={(e) => { onRemoveMsBlock(e, msgBlock) }} className="btn delete-message-block">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 1L6 6M11 11L6 6M6 6L11 1L1 11" stroke="#CFCFCF" strokeWidth="2" />
                            </svg>
                          </button>

                        </div>)
                      }) : ""}
                    </div>
                    <div className="form-element">
                      <input
                        className="form-control"
                        type="text"
                        value={mSegmentInputs.messageBlock}
                        onChange={(e) => { onSegmentChange(e, "messageBlock") }}
                        placeholder="write your message here"
                      />
                      <button onClick={(e) => { onMSAdd(e) }} className="btn btn-theme btn-blue" disabled={false}>+ Add</button>

                      <div className="fast-tags">
                        {mSOptions.length ? mSOptions.map(option => {
                          return (
                            <span onClick={(e) => { addMSOption(e, option) }} className="message-tags">{option}</span>
                          )
                        }) : ""}
                      </div>
                    </div>
                  </fieldset>
                  <div className="form-group form-footer text-center">
                    <button onClick={(e) => { createMessageSegment(e) }} className="btn btn-theme btn-gradient">
                      {modal.editable == true ? 'Update' : 'Create'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {modal.group && (
        <div className="message-modal modal-general">
          <div className="modal-overlay"></div>
          <div className="modal-outer message-modal-outer">
            <div className="modal-header d-flex f-justify-between f-align-center">
              <h4>Create Message Group</h4>
              <button
                className="btn btn-close-modal"
                onClick={(e) => {
                  e.preventDefault();
                  setModal({
                    segment: false,
                    group: false,
                  });
                }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-container">
                <form>
                  <fieldset className="form-group">
                    <legend className="form-label">
                      Enter your message group title
                    </legend>
                    <div className="form-element">
                      <input
                        className="form-control"
                        type="text"
                        value={mGroupInputs.title}
                        onChange={(e) => { onChangeMessageGroup(e) }}
                        placeholder="Group title"
                      />
                    </div>
                  </fieldset>

                  {/* FOR ADDED GROUPS */}
                  <fieldset className="form-group form-message-created">
                    <legend className="form-label">
                      Created message blocks
                    </legend>
                    <div className="show-message-blocks">
                      {associateBlocks.length ? associateBlocks?.map((inputBlock, index) => {






                        return buildMessageSet(inputBlock, index)






                      }) : ""}
                    </div>
                  </fieldset>
                  {/* FOR ADDED GROUPS */}

                  <fieldset className="form-group form-message-block">
                    <legend className="form-label">Build message set</legend>
                    <div className="form-message-blocks">
                      {associateBlockInput?.map((inputBlock, i) => {




                        return messageBlockInputRender(inputBlock, i)
                        // console.log("inputBlock",inputBlock)






                      })}
                      <div className="form-mesage-opt">

                        <button
                          className="btn btn-theme btn-gradient btn-select-type"
                          onClick={(e) => {
                            e.preventDefault();

                            setAddMessageItem(addMessageItem == null ? 'options' : null)
                          }}
                        >
                          {addMessageItem == null ? '+ Add' : 'Close'}
                        </button>
                        {addMessageItem == 'options' ?
                          <div className="select-message-type">
                            <ul>
                              <li
                                className="btn"
                                onClick={() => setAddMessageItem('segments')}
                              >
                                Segments
                              </li>
                              <li
                                className="btn"
                                onClick={() => setAddMessageItem('text')}
                              >
                                Text and Keywords
                              </li>
                            </ul>
                          </div> : null
                        }

                        {addMessageItem == 'segments' ?
                          <div className="select-message-type">
                            <input
                              type="search"
                              name=""
                              id=""
                              placeholder="Search"
                            />
                            <ul>
                              {messageSegments.map(segment => <li onClick={(e) => { addSegmentOrTextToBlock(e, segment); setAddMessageItem(null) }} className="btn">{segment.title} </li>)}
                            </ul>
                          </div> : null
                        }
                      </div>
                    </div>

                    {addMessageItem == 'text' ?
                      <div className="form-element">
                        <input
                          className="form-control"
                          type="text"
                          value={mGroupInputs.customText}
                          onChange={(e) => onMGroupInputChange(e)}
                          placeholder="Insert your custom message/keywords here"
                        />
                        <button onClick={(e) => { addSegmentOrTextToBlock(e); setAddMessageItem(null) }} className="btn btn-theme btn-blue" disabled={false}>
                          + Add Custom Text
                        </button>

                        <div className="fast-tags">
                          {mSOptions.length ? mSOptions.map(option => {
                            return (
                              <span onClick={(e) => { addMSOption(e, option, "group") }} className="message-tags">{option}</span>
                            )
                          }) : ""}
                        </div>
                      </div> : null
                    }
                    <br />
                    <button onClick={(e) => onAddingGroupBlock(e)} className="btn btn-theme btn-gradient btn-add-groupItem" disabled={false}>
                      + Add
                    </button>
                  </fieldset>
                  <div className="form-group form-footer text-center">
                    <button onClick={(e) => { onCreateMessageGroup(e) }} className="btn btn-theme btn-gradient">
                      {modal.editable == true ? 'Update' : 'Create'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageSegmentGroup;
