import React from "react";
import { Route, Navigate,useLocation } from "react-router-dom";
import Login from "./Components/Login/Login";
import { checkLogin } from "./Services/Auth/Authenticate";
import Header from "./Components/Header/Header";
import Sidebar from "./Components/Sidebar/Sidebar";
export const ProtectedRoute = ({children}) => {
  let location = useLocation();
  if(!checkLogin()){
    
    return  <Navigate to="/login"  replace />;
  }else {
    return(
      <div className="main-container vh-100 d-flex f-column">
      <Header
          // onLogout={onLogout}
      ></Header>
            <div className="content-container d-flex f-1 o-h">
    
                <Sidebar></Sidebar>
      {children}
      </div>
      </div>
      )
  }
}

export const UnProtectedRoute = ({children}) => {
  let location = useLocation();
  if(checkLogin()){
    return  <Navigate to="/home"  replace />;
  }else {
    return children
  }
}
