import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import SidePanel from "../Sidepanel/SidePanel";
import Story from "../Story/Story";
import { FetchFriendStorys,FetchEngagerSettings } from "../../Services/Storys/Storys";
import { talkToExtension } from "../../Services/ChromeStore/ChromeStore";
import Loader from "../Loader/Loader";
import Config from "../../Config/env"
import SettingsPanel from "./SettingsPanel";
import Toast from "../Toast/Toast";
import Alertbox from "../Toast/Toast";
const Home = () => {
    document.title = `${Config.name} - Dashboard`;
    const [isLoggedIn,setIsLoggedIn] = useState(true)
    const [isLoading,setLoading] = useState(true)
    const [settingsPanel, setSettingsPanel] = useState(false)
    const [topEngagersModal, setTopEngagersModal] = useState(false)
    const [engage, setEngage] = useState(false)
    const [stories,setStories] = useState([])
    const [totalNoOfStories,setTotalStoriesCount] = useState(0)
    const [reactions, setReactions] = useState({
        like: 0,
        heart: 0,
        haha: 0,
        wow: 0,
        sad: 0,
        care: 0,
        angry: 0
    })
    const [vitamins, setVitamins] = useState({
        "appName": "Let Story Drive",
        "logo": "./images/logo.png",
        "extensionId": "",
        "changePasswordUrl": "",
        "backendUrl": "https://letstorydrive.io",
        "mailTo": "",
        "description": {
            "short_description": "",
            "long_description": ""
        },
        preLoader: '',
        secondaryLogo : "./images/logo-in.png"
    })
    const [engagementLimit,setEngaggementLimit] = useState(false)
    const [isSyncing,setSyncing] = useState(false)

    const getVitamins = () => {
        if(Object.keys(JSON.parse(localStorage.getItem('vitaminsForPortal'))).length){
            let vitamins = JSON.parse(localStorage.getItem('vitaminsForPortal'))
            document.title = `${vitamins.appName} - Dashboard`;
            setVitamins(vitamins)
        }
    }


    // const sync = () => {
    //     setSyncing(true)
    //     talkToExtension('sync',extension=>{
    //         if(extension?.response?.response?.type == "error"){
    //             alert(extension?.response?.response?.data?.error)
    //         }else{
    //             alert(extension?.response?.response?.data?.success)
    //             setTimeout(()=>{
    //                 loadAllStorys()
    //             },30000)

    //         }
    //         console.log("respnse from sync",extension?.response?.response?.data)
    //     })
    //     // setLoading(true)
    // }

    const engagement = () => {
        setEngage(!engage)
    }

    const settingsPanelToggle = () => {
        setSettingsPanel(!settingsPanel)
    }

    const loadAllFriendsStorys = async () => {
        let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
        if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
            let payload = {
                lsd_token : userInfo.loggedInUserInfo.lsd_token,
                fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId
            }
            console.log("payload fetch all friends storys",payload)
            FetchFriendStorys(payload).then(res =>{
                setLoading(false)

                console.log("response in HOME ",res)
                setSyncing(false)   
                if(res.data.data.status)
                setStories(res.data.data.data)
                setTotalStoriesCount(res.data.data.data.length)
            })
        }else{
            setLoading(false)
            setSyncing(false)
        }
    }

    // useEffect(() =>{
    //     Alertbox(
    //         `Settings updated successfully.`,
    //         "success",
    //         5000,
    //         "bottom-right"
    //     );
    // }, [reactions])

    async function fetchSettings(){
        console.log("api call")
        let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
        if(userInfo.loggedInUserInfo.lsd_token!=undefined && userInfo.loggedInUserInfo.lsd_token){
          FetchEngagerSettings({ lsd_token : userInfo.loggedInUserInfo.lsd_token}).then(res=>{
            console.log("response settings",res)
            if(res.data.data.status){
                setReactions(res.data.data.data.reactions)
                setEngaggementLimit(res.data.data.data.perPersonEngagementLimit)
            }
          })
        }
      }
    useEffect(()=>{
        getVitamins()
        loadAllFriendsStorys()
        fetchSettings()

    },[])

    return(
        <>
      
        {!isLoggedIn?   <Navigate to="/login" replace={true} />: ''}
        {/* <button onClick={onLogout}>SignOUT</button> */}
        {/* <div className="main-container vh-100 d-flex f-column">
            <Header
                onLogout={onLogout}
            ></Header> */}
      
                {isLoading?<Loader loaderImg={vitamins.preLoader}/>:''}
                <div className="content-container-main d-flex f-1 container-fixed-header">
                    <div className="content-space f-1 d-flex f-column">
                        <div className="header-content-container d-flex">
                            <div className="header-content">
                                <h3>Friends Stories</h3>
                                <span className="num-header">{totalNoOfStories}</span>
                            </div>
                            <div className="header-content-options d-flex f-align-center">
                                {/* <div className="date-range-select">

                                </div> */}
                                {/* <button onClick={engagement} className={engage?"btn btn-gradient btn-header-main btn-start-engagement working":"btn btn-gradient btn-header-main btn-start-engagement"}>  */}
                                {/**Class - syncing FOR LOADING PHASE*/}
                                    {/* <span>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.98995 9.9601H9.30745V15.3601C9.30745 16.1551 10.2974 16.5301 10.8224 15.9301L16.4999 9.4801C16.9949 8.9176 16.5974 8.0401 15.8474 8.0401H13.53V2.6401C13.53 1.8451 12.5399 1.4701 12.0149 2.0701L6.33745 8.5201C5.84995 9.0826 6.24745 9.9601 6.98995 9.9601Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M6.375 3H1.125" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5.625 15H1.125" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.375 9H1.125" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                    {engage ? "Stop Auto engagement" : "Start Auto engagement"}</button>
                                <button onClick={sync} className={isSyncing?"btn btn-gradient btn-header-main btn-sync-title syncing":"btn btn-gradient btn-header-main btn-sync-title"}>  */}
                                {/**Class - syncing FOR LOADING PHASE*/}
                                    {/* <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 10L5.70711 9.29289L5 8.58579L4.29289 9.29289L5 10ZM9.70711 13.2929L5.70711 9.29289L4.29289 10.7071L8.29289 14.7071L9.70711 13.2929ZM4.29289 9.29289L0.292893 13.2929L1.70711 14.7071L5.70711 10.7071L4.29289 9.29289Z" fill="white"/>
                                        <path d="M15.5 18.0622C14.4359 18.6766 13.2288 19 12 19C10.7712 19 9.56413 18.6766 8.5 18.0622C7.43587 17.4478 6.5522 16.5641 5.93782 15.5C5.32344 14.4359 5 13.2288 5 12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        <path d="M19 14L18.2929 14.7071L19 15.4142L19.7071 14.7071L19 14ZM14.2929 10.7071L18.2929 14.7071L19.7071 13.2929L15.7071 9.29289L14.2929 10.7071ZM19.7071 14.7071L23.7071 10.7071L22.2929 9.29289L18.2929 13.2929L19.7071 14.7071Z" fill="white"/>
                                        <path d="M8.5 5.93782C9.56413 5.32344 10.7712 5 12 5C13.2288 5 14.4359 5.32344 15.5 5.93782C16.5641 6.5522 17.4478 7.43587 18.0622 8.5C18.6766 9.56414 19 10.7712 19 12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                    </span>
                                    Sync</button> */}

                                <div className="custom-select select-range-insights">
                                {/* <select>
                                    <option value="all">All</option>
                                    <option value="pending">Pending</option>
                                    <option value="engaged">Engaged</option>
                                </select> */}
                                </div>
                                <button
                                    className="btn btn-gradient btn-header-main btn-header-settings"
                                    onClick={settingsPanelToggle}
                                >
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.25 6.83203V11.1595C2.25 12.7495 2.25 12.7495 3.75 13.762L7.875 16.147C8.4975 16.507 9.51 16.507 10.125 16.147L14.25 13.762C15.75 12.7495 15.75 12.7495 15.75 11.167V6.83203C15.75 5.24953 15.75 5.24953 14.25 4.23703L10.125 1.85203C9.51 1.49203 8.4975 1.49203 7.875 1.85203L3.75 4.23703C2.25 5.24953 2.25 5.24953 2.25 6.83203Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="dash-main-content dashboard-content f-1 scroll-ui-thick">
                        <div className="stories-container stories-engagement">
                         {stories.length>0?stories.map((x,i)=>{
                            return (
                                <>
                                <Story 
                                    key={i} 
                                    info={x}
                                    mode='engagement'
                                />
                                </>
                            )
                         }):'No Stories to show'}
                         </div>
                        </div>
                    </div>
                    
                    {
                        settingsPanel && 
                        <SettingsPanel 
                            reactions={reactions}
                            setReactions={setReactions} 
                            setSettingsPanel={setSettingsPanel}
                            engagementLimit={engagementLimit}
                            setEngaggementLimit={setEngaggementLimit}
                        />
                    }
                    <SidePanel
                        setTopEngagersModal={setTopEngagersModal}
                        mode='engagement'
                    />
                </div>
                
               
        {/* </div> */}
        </>
    )
}



export default Home