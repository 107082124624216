import { useEffect, useState, useRef } from "react";
import Config from  '../../Config/env'
import wow from "../../assets/images/wow.png"
import love from "../../assets/images/love.png"
import like from "../../assets/images/like.png"
import moment from 'moment'

import { NavLink, useHistory, useParams,Navigate } from "react-router-dom";
import {fetchUserDetails,fetchUserName} from '../../Services/Users/Users'
import {automationInsight, dashAutomationCount,dashMessageSent,dashStoriesCount,dashEngagementCount,dashActiveAutomationCount} from '../../Services/Storys/Storys'


const UserDetails = (props) => {
  const { userId } = useParams();
  const [userData,setUserData] = useState([])
  const [syncedStories,setSyncedStories] = useState(0)
  const [totalMessageSent,setMessageSentCount] = useState(0)
  const [totalReactionGiven,setReactionGivenCount] = useState(0)

  document.title = `${Config.name} -${userId}`;

  const timeAgoStoryUpload = (creation_time) => {
                        
    let timeAgo = moment.unix(creation_time).fromNow();
    timeAgo = timeAgo.substring(0, timeAgo.lastIndexOf(' '));

    if (timeAgo.includes('hours'))
       return timeAgo = timeAgo.replace('hours', 'h');
    if (timeAgo.includes('hour'))
       return timeAgo = timeAgo.replace('an hour', '1 h');
    if (timeAgo.includes('minutes'))
       return timeAgo = timeAgo.replace('minutes', 'm');
    if (timeAgo.includes('minute'))
       return timeAgo = timeAgo.replace('a minute', '1 m');
    if (timeAgo.includes('seconds'))
       return timeAgo = timeAgo.replace('seconds', 's');

    return timeAgo
}

const [name, setName ] = useState("facebook user")
  const loadUserInfoDetails = async () => {
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
    if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
        let payload = {
            lsd_token : userInfo.loggedInUserInfo.lsd_token,
            fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
            userId
        }
  
        fetchUserDetails(payload).then(res =>{
            // setLoading(false)

            console.log("response :: ",res.data.data)
            // setSyncing(false)
            if(res.data.data.status){
              setUserData(res.data.data.data.userDetails)
              setMessageSentCount(res.data.data.data.messageCount)
              setReactionGivenCount(res.data.data.data.reactionCount)
              // setViewerlist(res.data.data.payload.data)
            }
        })
    }else{
        // setLoading(false)
        // setSyncing(false)
    }
}

const fetchUserNameInfo = async () => {
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          userId
      }

      fetchUserName(payload).then(res =>{
          // setLoading(false)

          console.log("response :: ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setName(res.data.data.payload.name)
            // setViewerlist(res.data.data.payload.data)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}
const loadDashTotalStories = async () => {
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
        
      }
      dashStoriesCount(payload).then(res =>{
        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setSyncedStories(res.data.data.data.syncedStories)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}

  useEffect(() => {
    console.log("user id",userId)
    loadUserInfoDetails()
    fetchUserNameInfo()
    loadDashTotalStories()
    

  }, []);

  return (
    <div className="content-container-main content-setup d-flex f-1">
      <div className="content-space f-1">
        <div className="header-content-container d-flex f-column">
            <NavLink to={"/home/"} className="go-back">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41016 10.59L2.83016 6L7.41016 1.41L6.00016 -6.16331e-08L0.00015614 6L6.00016 12L7.41016 10.59Z"
                  fill="#323232"
                />
              </svg>
            </NavLink>
          <div className="header-stories-engaged d-flex">
          {/* <div className="custom-select select-range-insights">
            <select>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div> */}
          </div>
          <div className="header-content flex">
            <div className="story-shots engager-shots d-flex f-1">
              <div className="engager-info-short f-1 d-flex">
                <figure
                  style={{
                    backgroundImage: `url('./images/profImage.jpg')`,
                  }}
                ></figure>
                <div className="engager-info">
                   {/* <span className="engager-badge top-engager-badge">
                    Top 13 Engager
                  </span>  */}
                  <h4>{name}</h4>
                  <a target="_blank" href={`https://facebook.com/${userId}`}>
                    facebook.com/{userId}
                        <svg
                          className="openLink"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.25 1.75V1.25H12.75V1.75H12.25ZM6.77022 7.93689C6.57496 8.13215 6.25837 8.13215 6.06311 7.93689C5.86785 7.74163 5.86785 7.42504 6.06311 7.22978L6.77022 7.93689ZM11.75 6.41667V1.75H12.75V6.41667H11.75ZM12.25 2.25H7.58333V1.25H12.25V2.25ZM12.6036 2.10355L6.77022 7.93689L6.06311 7.22978L11.8964 1.39645L12.6036 2.10355Z"
                            fill="#ffffff"
                          />
                          <path
                            d="M11.6667 8.75004V8.75004C11.6667 10.3609 10.3609 11.6667 8.75008 11.6667H7.00008C5.44558 11.6667 4.66833 11.6667 4.06556 11.3856C3.42634 11.0876 2.91257 10.5738 2.61449 9.93456C2.33342 9.33179 2.33342 8.55454 2.33342 7.00004V5.25004C2.33342 3.63921 3.63925 2.33337 5.25008 2.33337V2.33337"
                            stroke="#ffffff"
                            strokeLinecap="round"
                          />
                        </svg>
                  </a>
                </div>

                <div className="facebook-bg">
                  <svg
                    width="163"
                    height="139"
                    viewBox="0 0 163 139"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.1" clipPath="url(#clip0_535_3325)">
                      <path
                        d="M160.847 120.52C162.036 124.955 159.4 129.519 154.965 130.707L42.4806 160.847C38.0455 162.036 33.4815 159.401 32.2931 154.966L2.15287 42.4809C0.964487 38.0458 3.59951 33.4818 8.03463 32.2934L120.519 2.15321C124.955 0.964822 129.519 3.59985 130.707 8.03496L160.847 120.52Z"
                        fill="white"
                      />
                      <path
                        d="M122.827 139.319L109.91 91.1108L125.979 86.8051L124.614 65.6421L104.527 71.0243L102.374 62.9896C100.222 54.955 103.171 49.8591 111.197 47.7084L119.232 45.5555L113.85 25.469C109.832 26.5454 104.851 27.8802 97.7803 29.7747C83.0167 33.7306 76.7776 47.8072 81.2115 64.3545L84.4408 76.4065L68.3715 80.7122L73.7537 100.799L89.823 96.493L102.74 144.701L122.827 139.319Z"
                        fill="#1877F2"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_535_3325">
                        <rect
                          width="133.089"
                          height="133.089"
                          fill="white"
                          transform="translate(0 34.4463) rotate(-15)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="story-infos d-flex f-1">
                <div className="story-items">
                  <span>Total Stories Synced</span>
                  <h4>{syncedStories}</h4>

                  <span>Viewed</span>
                  <h4>{userData?.length}</h4>

                  <figure className="items-image">
                    <svg
                      width="102"
                      height="104"
                      viewBox="0 0 102 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_535_3272"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="102"
                        height="104"
                      >
                        <rect width="102" height="104" fill="white" />
                      </mask>
                      <g mask="url(#mask0_535_3272)">
                        <path
                          d="M34.8359 22.9097C35.7787 19.4539 39.3858 17.4005 42.8925 18.3234L89.2442 30.5221C92.751 31.445 94.8295 34.9947 93.8867 38.4505L75.4505 106.03C74.5078 109.486 70.9007 111.539 67.3939 110.616L21.0422 98.4175C17.5355 97.4946 15.457 93.9449 16.3997 90.4891L34.8359 22.9097Z"
                          fill="#F2F9FF"
                        />
                        <path
                          d="M26.1634 79.6454C26.6348 77.9175 28.4383 76.8908 30.1917 77.3523L44.1607 81.0286C45.9141 81.4901 46.9533 83.2649 46.482 84.9928C46.0106 86.7207 44.207 87.7474 42.4537 87.286L28.4846 83.6096C26.7313 83.1482 25.692 81.3733 26.1634 79.6454Z"
                          fill="#DEEFFF"
                        />
                        <path
                          d="M39.6491 30.2124C40.4976 27.1021 43.744 25.2541 46.9001 26.0847C50.0561 26.9153 51.9268 30.11 51.0783 33.2203C50.2298 36.3305 46.9834 38.1785 43.8274 37.3479C40.6713 36.5173 38.8006 33.3226 39.6491 30.2124Z"
                          fill="#DEEFFF"
                        />
                        <path
                          d="M23.6028 89.0315C24.0742 87.3035 25.8777 86.2769 27.6311 86.7383L53.0293 93.4225C54.7827 93.884 55.8219 95.6588 55.3506 97.3867C54.8792 99.1147 53.0756 100.141 51.3223 99.6799L25.9241 92.9957C24.1707 92.5342 23.1314 90.7594 23.6028 89.0315Z"
                          fill="#DEEFFF"
                        />
                      </g>
                    </svg>
                  </figure>
                </div>
                <div className="story-items">
                <span>Total Reaction Given</span>
                  <h4>{totalReactionGiven}</h4>
                  {/* <span>Total Story Run by LSD</span>
                  <h4>456</h4>

                  <span>Total Viewed Story</span>
                  <h4>34</h4> */}

                  <figure className="items-image">
                    <svg
                      width="104"
                      height="103"
                      viewBox="0 0 104 103"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <mask
                          id="mask0_535_3298"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="104"
                          height="94"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="103"
                            height="93"
                            rx="18.5"
                            fill="white"
                            stroke="#BBC4D7"
                          />
                        </mask>
                        <g mask="url(#mask0_535_3298)">
                          <path
                            d="M66.0792 101.004C90.919 94.3482 105.66 68.8159 99.0042 43.9762C92.3484 19.1364 66.8162 4.39535 41.9764 11.0511C17.1366 17.7069 2.39559 43.2392 9.05139 68.0789C15.7072 92.9187 41.2394 107.66 66.0792 101.004Z"
                            fill="#E4F2FF"
                          />
                          <path
                            d="M56.4084 68.5577C67.8167 65.5009 78.3814 64.5449 85.275 65.5771C85.1604 66.038 85.0307 66.4949 84.8907 66.9506C86.4706 62.3491 86.6488 57.3821 85.4026 52.6791C85.1355 51.6822 24.9338 67.8913 25.1814 68.8154C26.3626 73.1895 28.735 77.1498 32.0343 80.2551C31.7611 79.9821 31.4869 79.7054 31.2322 79.4195C36.8462 75.4001 45.8662 71.3825 56.4084 68.5577Z"
                            fill="white"
                          />
                          <path
                            d="M62.347 87.0772C73.481 84.0938 81.7875 76.2036 84.8906 66.9503C85.0306 66.4946 85.1602 66.0377 85.2749 65.5768C78.3813 64.5446 67.8166 65.5006 56.4082 68.5575C45.8661 71.3822 36.8461 75.3998 31.2321 79.4192C31.4924 79.7036 31.761 79.9818 32.0342 80.2548C32.2528 80.463 32.474 80.6665 32.698 80.8653C40.0145 87.3666 51.1813 90.069 62.347 87.0772Z"
                            fill="#BDDFFF"
                          />
                          <path
                            d="M19.1742 46.9258L32.4397 49.8962C33.7041 50.1797 35.0383 49.506 35.3902 48.2132C35.7206 47.0042 34.9773 45.5446 33.7072 45.2626L20.4412 42.2903C19.1749 42.0074 17.8431 42.6824 17.4907 43.9733C17.1603 45.1823 17.9036 46.6419 19.1755 46.9234L19.1742 46.9258Z"
                            fill="#BDDFFF"
                          />
                          <path
                            d="M24.8559 58.538L34.8584 49.3309C35.8117 48.4532 35.7307 46.8141 34.8406 45.9341C33.8815 44.9906 32.4074 45.0674 31.4439 45.9519L21.4366 55.1562C20.4837 56.0358 20.5648 57.6749 21.4544 58.553C22.4135 59.4965 23.8871 59.4178 24.8511 58.5352L24.8559 58.538ZM76.2386 27.3382L66.2371 36.549C65.2819 37.4273 65.3629 39.0663 66.2548 39.9458C67.214 40.8893 68.6881 40.8125 69.6516 39.928L79.6598 30.7194C80.6145 29.8394 80.5339 28.2022 79.6439 27.3222C78.6847 26.3786 77.2018 26.4599 76.2471 27.3399L76.2386 27.3382Z"
                            fill="#BDDFFF"
                          />
                          <path
                            d="M81.8142 38.9775L68.5487 36.0071C67.2824 35.7241 65.9487 36.3996 65.5981 37.6901C65.2678 38.8991 66.0111 40.3587 67.2811 40.6407L80.5472 43.613C81.8228 43.8934 83.1472 43.2204 83.4977 41.93C83.8281 40.7209 83.0848 39.2614 81.8147 38.9794L81.8142 38.9775Z"
                            fill="#BDDFFF"
                          />
                        </g>
                      </g>
                    </svg>
                  </figure>
                </div>
                <div className="story-items">
                  <span>Total Messages sent</span>
                  <h4>{totalMessageSent}</h4>

                  {/* <span>Total Reaction Given</span>
                  <h4>{totalReactionGiven}</h4> */}

                  <figure className="items-image">
                    <svg
                      width="105"
                      height="95"
                      viewBox="0 0 105 95"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M38.3145 13.6842C38.3145 6.1266 44.4411 0 51.9986 0C59.5562 0 65.6828 6.12661 65.6828 13.6842V94.9999H38.3145V13.6842Z"
                        fill="#F2F9FF"
                      />
                      <path
                        d="M76.6328 30.8638C76.6328 23.3063 82.7594 17.1797 90.317 17.1797C97.8745 17.1797 104.001 23.3063 104.001 30.8639V95.0002H76.6328V30.8638Z"
                        fill="#F2F9FF"
                      />
                      <path
                        d="M0 67.1129C0 59.5553 6.12661 53.4287 13.6842 53.4287C21.2417 53.4287 27.3683 59.5553 27.3683 67.1129V95H0V67.1129Z"
                        fill="#F2F9FF"
                      />
                    </svg>
                  </figure>
                </div>

          










              </div>
            </div>
          </div>
        </div>

        <div className="engager-engaged-stories">
          <header className="engager-stories-header d-flex f-justify-between">
            <h5>
              All Viewed Stories
              <span>{userData?.length}</span>
            </h5>

            {/* <span className="date-span-engaged">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2.25" y="4.5" width="13.5" height="11.25" rx="2" stroke="url(#paint0_linear_535_3253)" strokeWidth="2"/>
              <path d="M3 8.25H15" stroke="url(#paint1_linear_535_3253)" strokeWidth="2" strokeLinecap="round"/>
              <path d="M6.75 12H11.25" stroke="url(#paint2_linear_535_3253)" strokeWidth="2" strokeLinecap="round"/>
              <path d="M6 2.25L6 5.25" stroke="url(#paint3_linear_535_3253)" strokeWidth="2" strokeLinecap="round"/>
              <path d="M12 2.25L12 5.25" stroke="url(#paint4_linear_535_3253)" strokeWidth="2" strokeLinecap="round"/>
              <defs>
                <linearGradient id="paint0_linear_535_3253" x1="4.14653" y1="37.7143" x2="17.5991" y2="36.3765" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1A68FF"/>
                <stop offset="1" stopColor="#9C1FFF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_535_3253" x1="4.6858" y1="11.2024" x2="10.7574" y2="5.16443" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1A68FF"/>
                <stop offset="1" stopColor="#9C1FFF"/>
                </linearGradient>
                <linearGradient id="paint2_linear_535_3253" x1="7.38218" y1="14.9524" x2="11.3578" y2="13.4698" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1A68FF"/>
                <stop offset="1" stopColor="#9C1FFF"/>
                </linearGradient>
                <linearGradient id="paint3_linear_535_3253" x1="3.04762" y1="2.67145" x2="3.7545" y2="5.51473" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1A68FF"/>
                <stop offset="1" stopColor="#9C1FFF"/>
                </linearGradient>
                <linearGradient id="paint4_linear_535_3253" x1="9.04762" y1="2.67145" x2="9.7545" y2="5.51473" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1A68FF"/>
                <stop offset="1" stopColor="#9C1FFF"/>
                </linearGradient>
              </defs>
            </svg>
              28th Feb - 28th Mar, 2022
            </span> */}
          </header>
          <div className="viewed-stories">
            {userData?.map(info=>{
              return(
                <div className="viewed-info">
              <div
                className="stories"
              >
                <header>
                  <span className="timestamp">
                   {timeAgoStoryUpload(info?.stories?.creation_time)}
                  </span>
                  <span className="views">
                    <figure>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 3.375C5.25 3.375 2.0475 5.7075 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C12.75 14.625 15.9525 12.2925 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375ZM9 12.75C6.93 12.75 5.25 11.07 5.25 9C5.25 6.93 6.93 5.25 9 5.25C11.07 5.25 12.75 6.93 12.75 9C12.75 11.07 11.07 12.75 9 12.75ZM9 6.75C7.755 6.75 6.75 7.755 6.75 9C6.75 10.245 7.755 11.25 9 11.25C10.245 11.25 11.25 10.245 11.25 9C11.25 7.755 10.245 6.75 9 6.75Z"
                          fill="white"
                        />
                      </svg>
                    </figure>
                  {info?.stories?.viewer_count}
                  </span>
                </header>
                <div className="body-story">
                  <figure
                    style={{
                     "backgroundImage": `url(${info?.stories?.story_attachement_uri})`
                    }}
                  >
                    <NavLink
                      to={'/'}
                    ></NavLink>
                  </figure>
                </div>
              </div>

              <aside>
                  <label>
                    Selected Options
                  </label>
                  <h4>{info?.selectedOption == "N/A"?"N/A":info?.selectedOption?.text}</h4>

                  <label>
                    Reaction
                  </label>
                  <figure>
                    {info?.reactionGiven == "N/A"?"N/A":info?.reactionGiven?.node?.messaging_actions?.edges.map(reaction=>{
                      return(
                        reaction.node.reaction
                      )
                    })}
                    {/* <img src={wow} alt="" /> */}
                  </figure>

                  <label>
                    Response sent via automation
                  </label>
                  <p>
                    {info.messageSent == "N/A"?"N/A": info?.messageSent?.message}
                  </p>
              </aside>
            </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
