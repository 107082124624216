import axios from "axios";
import history from "../../history";
import {
  createOrUpdateAutomation
  } from "../../Config/Url";
import errorMessage from "../../Messages/error";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "",
};

export const createorupdateautomation = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        createOrUpdateAutomation,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("API",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};



