import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {fetchAllMessageGroups} from '../../Services/Messages/Messages'

import {createorupdateautomation} from '../../Services/Automations/Automation'
const CreateAutomation = ({automationInfo,polls,storyId}) => {

  const [isRunning,setIsRunning] = useState(false)
  const [canCreate, setCreateMode] = useState(false)
  const [canEdit,setEditMode] = useState(false)
  const [toggleSelect, setToggleSelect] = useState({
    option1: false,
    option2: false,
    reaction: false,
    none: false
  })
  const [optionList, setOptionList] = useState(false);
  const [automationToggle,setAutomationToggle] = useState({
    is_active : false,
    statusChange : false
  })
  const [messageGroupInfo, setMessageGroupInfo] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({
    'option1' : {text :'select',id:null},
    'option2' : {text :'select',id:null},
    'reaction' : {text :'select',id:null},
    'none' : {text :'select',id:null}
  })
  const [removeCancelButton, setRemoveCancelButton] = useState(false)
  //slider checkbox
  const toggleAutomation = async () => {
    // console.log("automation toggle triggered")
    setAutomationToggle({...automationToggle, is_active :!!!automationToggle.is_active, statusChange : true})
   
      let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
      if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
        "lsd_token": userInfo.loggedInUserInfo.lsd_token,
        "automation_id": automationInfo._id,
        "data" : {...automationToggle, is_active :!!!automationToggle.is_active, statusChange : true},
        "story_id" : storyId
    }
    createorupdateautomation(payload).then(res=>{
      console.log("Response",res)
    })
  }
}

  const titleByGroupId = (groupId) => {
   
     let temp =  messageGroupInfo.filter(x=>{
     if(x._id == groupId) return x
    })

    return temp[0]?.title
  }
  const loadMessageGroups = async () => {
    console.log("Message groups details load")
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))

    if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token
         
      }

      fetchAllMessageGroups(payload).then(res =>{
          // setLoading(false)

          // console.log("response in SetupStory ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            let data = res.data.data.payload.data
            setMessageGroupInfo([...messageGroupInfo, ...data ])
           
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }

    

  }

  const msgGroupSelected = (ref,data) =>{
    setSelectedGroup({...selectedGroup, 
      [ref] : {
      text : data.group.title, 
      id : data.group._id
    }})
    // alert(ref)
    // switch(ref){
    //   case 'option1' :
    //   console.log("seelected option1",data.group.title, data.group._id)
      
    //   break

    //   case 'option2' :

    //   break
      
    //   case 'reaction' :

    //   break
      
    //   case 'none' :

    //   break
    // }
  }
  const onEdit = () => {
    setToggleSelect({ 
      option1: false,
      option2: false,
      reaction: false,
      none: false
    })
    setEditMode(true)
    setOptionList(!optionList)
  }
  const onSave = async ()=> {
    
    setEditMode(false)
    if(canCreate){
      setRemoveCancelButton(true)
      // alert("remoce Cancel button and show edit button")
      // setCreateMode(false)
    }

    if(selectedGroup.option1.id!=null || selectedGroup.option2.id!=null || selectedGroup.reaction.id!=null || selectedGroup.none.id!=null   ){
      let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))

      if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
        "lsd_token": userInfo.loggedInUserInfo.lsd_token,
        "automation_id": automationInfo?._id,
        "data": {
            "automation_type": {
                "on_poll": {
                    "poll_a": {
                        "name": polls?.poll_options[0].text,
                        "msg_group": selectedGroup.option1.id
                    },
                    "poll_b": {
                        "name": polls?.poll_options[1].text,
                        "msg_group": selectedGroup.option2.id
                    }
                },
                "on_reactions": {
                    "msg_group": selectedGroup.reaction.id
                },
                "on_nothing": {
                    "msg_group": selectedGroup.none.id
                }
            },
            "is_active": automationToggle.is_active
        },
        "story_id": storyId
    
      }
      createorupdateautomation(payload).then(res=>{
        
        console.log("ON SAGVEEEEEEEEEE",res)
      })
    }
    }else{
      return alert("Please select atleast one message group")
    }

 
  }

 const onCreate = () => {
    setCreateMode(!canCreate)
    setEditMode(!canEdit)
 }
  const openToggleAutomation = (e, ref) => {
    e.stopPropagation();
    
  
    if(canEdit){

      setToggleSelect({
        ...toggleSelect,
        [ref]: !toggleSelect[ref]
      });
    }
  }

  useEffect(() => {
    // console.log("automation info in create automation",automationInfo);
    setAutomationToggle({...automationToggle, is_active : automationInfo?.is_active})
    
    let onLoadMessageSelection = {
      'option1' : {
        text : automationInfo?.automation_type?.on_poll?.poll_a?.msg_group?.length >0?titleByGroupId(automationInfo?.automation_type?.on_poll?.poll_a?.msg_group): 'select',
        id : automationInfo?.automation_type?.on_poll?.poll_a?.msg_group?.length >0?automationInfo?.automation_type?.on_poll?.poll_a?.msg_group : null,
      },
      'option2' : {
        text : automationInfo?.automation_type?.on_poll?.poll_b?.msg_group?.length >0?titleByGroupId(automationInfo?.automation_type?.on_poll?.poll_b?.msg_group): 'select',
        id : automationInfo?.automation_type?.on_poll?.poll_b?.msg_group?.length >0?automationInfo?.automation_type?.on_poll?.poll_b?.msg_group : null,
      },
      'reaction' : {
        text : automationInfo?.automation_type?.on_reactions?.msg_group?.length >0?titleByGroupId(automationInfo?.automation_type?.on_reactions?.msg_group): 'select',
        id : automationInfo?.automation_type?.on_reactions?.msg_group?.length >0?automationInfo?.automation_type?.on_reactions?.msg_group : null,
      },
      'none' : {
        text : automationInfo?.automation_type?.on_nothing?.msg_group?.length >0?titleByGroupId(automationInfo?.automation_type?.on_nothing?.msg_group): 'select',
        id : automationInfo?.automation_type?.on_nothing?.msg_group?.length >0?automationInfo?.automation_type?.on_nothing?.msg_group : null,
      }
    }

    setSelectedGroup({...selectedGroup,...onLoadMessageSelection})

  }, [automationInfo]);

  useEffect(()=>{
    loadMessageGroups()
  },[])
  return <>
     <div className="accordionOuter boxed boxed-padding">
              <header className="accordion-header d-flex f-align-center f-justify-between">

                <h2>Automation <span className={automationToggle.is_active?"label-automation-active btn-gradient":'label-automation-active'}>{automationToggle.is_active?'Running':''}</span></h2>{/**Create Automation */}

                {/* IndividualStoryInsights.automation_id != null  */}
                {(automationInfo!=null || removeCancelButton) && !canEdit &&
                  <div className="automation-options d-flex f-align-center">
                    <label className="custom-check d-flex">
                      <input onChange={toggleAutomation} checked={automationToggle.is_active} type="checkbox" name="" id="" />
                      <span className="check-style"></span>
                      {/* <span className="check-text no-text"></span> */}
                    </label>

                    <div className="options-created-automation">
                      <button className="option-automation btn" onClick={() => setOptionList(!optionList)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_348_2142)">
                            <path d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" fill="#7D8FB3"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_348_2142">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </button>

                      {optionList && 
                      <div className="options-list-automation">
                        <ul>
                          <li onClick={onEdit}>
                            Edit
                          </li>
                          <li>
                            Delete
                          </li>
                        </ul>
                      </div>}
                    </div>
                  </div>
                }

                {/* IndividualStoryInsights.automation_id == null */}
                {(automationInfo == null && !removeCancelButton ) &&
                  <>
                    <button onClick={onCreate} className="btn btn-theme btn-blue">
                      {canCreate?'Cancel':'Create'}
                    </button>
                 
                  </>
                }
                   { canEdit?<button onClick={onSave} className="btn btn-theme btn-gradient">Save</button>:''}
              </header>
              <div className="accordion-body"></div>
       {canCreate || automationInfo!=null ?  (
       <>
       <p>Select Message Group</p><br/>

        <div className="d-flex automation-message-select"> {/**active */}
          <div className={polls?.poll_options.length>0 && selectedGroup.option1.id!=null?"automation-option active":"automation-option"} onClick={(e) => openToggleAutomation(e, 'option1')}>  {/**active */}
            <h6>{polls?.poll_options.length>0?polls?.poll_options[0].text:'N/A'}</h6>

            <p>{ polls?.poll_options.length>0?selectedGroup.option1.text:'poll not available'}</p>
           

            {toggleSelect.option1 && polls?.poll_options.length>0 && !!canEdit &&  
              <div className="options-automation">
             
                            
                <ul>

                  {messageGroupInfo?.map((group,i)=>{
                      return(
                        <li onClick={()=>{msgGroupSelected('option1',{group})}} key={i} value={group._id}>
                          {group.title}
                        </li>
                      )
                  })}
                
                </ul>
              </div>
            }
          </div>
          <div className={polls?.poll_options.length>0 && selectedGroup.option2.id!=null?"automation-option active":"automation-option"} onClick={(e) => openToggleAutomation(e, 'option2')}>
            <h6>{polls?.poll_options.length>0?polls?.poll_options[1].text:'N/A'}</h6>

            <p>{ polls?.poll_options.length>0?selectedGroup.option2.text:'poll not available'}</p>

            {toggleSelect.option2 && polls?.poll_options.length>0 && !!canEdit &&  
              <div className="options-automation">
                <ul>
                {messageGroupInfo?.map((group,i)=>{
                      return(
                        <li  onClick={()=>{msgGroupSelected('option2',{group})}} key={i} value={group._id}>
                          {group.title}
                        </li>
                      )
                  })}
                </ul>
              </div>
            }
          </div>
          <div className={selectedGroup.reaction.id!=null?"automation-option active":"automation-option"} onClick={(e) => openToggleAutomation(e, 'reaction')}>
            <h6>Reaction</h6>

            <p>{selectedGroup.reaction.text}</p>

            {toggleSelect.reaction &&  !!canEdit &&  
              <div className="options-automation">
                <ul>
                {messageGroupInfo?.map((group,i)=>{
                      return(
                        <li  onClick={()=>{msgGroupSelected('reaction',{group})}} key={i} value={group._id}>
                          {group.title}
                        </li>
                      )
                  })}
                </ul>
              </div>
            }
          </div>
          <div className={ selectedGroup.none.id!=null?"automation-option active":"automation-option"} onClick={(e) => openToggleAutomation(e, 'none')}>
            <h6>None of the Above</h6>

            <p>{selectedGroup.none.text}</p>

            {toggleSelect.none && !!canEdit &&  
              <div className="options-automation">
                <ul>
                {messageGroupInfo?.map((group,i)=>{
                      return(
                        <li  onClick={()=>{msgGroupSelected('none',{group})}} key={i} value={group._id}>
                          {group.title}
                        </li>
                      )
                  })}
                </ul>
              </div>
            }
          </div>
        </div>
        </>
        ):""}
       </div> 
      </>;
};

export default CreateAutomation;
