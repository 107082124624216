import envConfig from '../../Config/env'

export const talkToExtension = (payload,callback = null) => {
try {
  var editorExtensionId = envConfig.extensionID
    // eslint-disable-next-line no-undef
  if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
    // eslint-disable-next-line no-undef
    chrome.runtime.sendMessage(editorExtensionId,payload, (response) => {
    // eslint-disable-next-line no-undef
      var lastError = chrome.runtime.lastError;
      if(lastError){
        callback({status : false})
      }
      console.log("Last error",response);
      if(response){
        // alert("got resp")
        if(callback){
          callback({status : true, response})
        }
      }
    });
  }else{
    if(callback){
      callback({ status : false})
    }
  }
  
} catch (error) {
  alert(error.message)
}
}
