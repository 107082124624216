import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {useLocation} from 'react-router-dom'
import { getActiveAutomationCoun, } from "../../Config/Url";
import moment from 'moment'
import { NavLink } from "react-router-dom";
import TopTwenty from "./TopTwentyModal/TopTwenty";

import {automationInsight,topTwentyUsers,top3Stories, dashAutomationCount,dashMessageSent,dashStoriesCount,dashEngagementCount,dashActiveAutomationCount,fetchUniqueEngagerStories,
  fetchEngagerStoriesSynced,
  fetchUniqueEngagerUsers,
  fetchEngagerStoriesReacted} from '../../Services/Storys/Storys'
import Interacted from "./InteractedUsers/Interacted";
const SidePanel = (props) => {
  const pathname = useLocation().pathname
  const [automationInfo, setAutomationInsight] = useState({
    "nota": {
        "users": [
        ],
        "totalUsers": 0
    },
    "onlyReaction": {
        "users": [],
        "totalUsers": 0
    },
    "option1": {
        "users": [
           
        ],
        "totalUsers": 0
    },
    "option2": {
        "users": [
            
        ],
        "totalUsers": 0
    },
    'messageDeliverd' : 0
})
  // Dashboard props
  const [totalAutomations, setAutomationsCreated] = useState(0)
  const [messages_sent,setMessagesSent] = useState(0)
  const [syncedStories,setSyncedStories] = useState(0)
  const [totalEngagement, setEngagementCount] = useState(0)
  const [activeAutomationCount,setActiveAutomationCount] = useState(0)
  const [topEngagersModal, setTopEngagersModal] = useState(false)
  const [userInteractionModal,setUserInteractionModal] = useState(false)
  const [ topThreeStories,setTopThreeStories] =useState([])
  const [ top20Users,setTopTwentyUsers] =useState([])
  const[isLoading,setIsLoading] = useState(false)
  const [isTotalStoryInsightLoading,setIsTotalStoryInsightLoading] = useState(false)
  const [isAutomationCreatedInsightLoading,setIsAutomationCreatedInsightLoading] = useState(false)
  const [isMessageSentInsightLoading,setIsMessageSentInsightLoading] = useState(false)
  const [isActiveAutomationInsightLoading,setIsActiveAutomationInsightLoading] = useState(false)
  const [isTopTwentyInsightLoading,setIsTopTwentyInsightLoading] = useState(false)
  const [isUsersInteractedInsightLoading,setIsUsersInteractedInsightLoading] = useState(false)


  const [engagersStorySynced, setEngagersStorySynced] = useState(0)
  const [engagersStorysReacted, setEngagersStorysReacted] = useState(0)
  const [uniqueStoriesEngaged, setuniqueStoriesEngaged] = useState(0)
  const [uniqueUsersEngaged, setUniqueUsersEngaged] = useState(0)
  const [engagerStorysReplied, setEngagerStorysReplied] = useState(0)
  
const loadDashAutomationCount = async () => {
  setIsAutomationCreatedInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
        
      }
      dashAutomationCount(payload).then(res =>{
      setIsAutomationCreatedInsightLoading(false)

        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setAutomationsCreated(res.data.data.data.totalAutomations)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}
const loadTop3Stories = async () => {
  setIsLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
      }

      top3Stories(payload).then(res =>{
        setIsLoading(false)
        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setTopThreeStories(res.data.data.data)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}

const loadTop20Users = async () => {
  setIsTopTwentyInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
      }

      topTwentyUsers(payload).then(res =>{
        setIsTopTwentyInsightLoading(false)
        console.log("response ______",res)
        // alert("hy")
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setTopTwentyUsers(res.data.data.data)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}

const loadEngagementCount = async () => {
  setIsUsersInteractedInsightLoading(true)

  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
        
      }
      dashEngagementCount(payload).then(res =>{
      setIsUsersInteractedInsightLoading(false)

        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setEngagementCount(res.data.data.payload[0]?.tota_engagement)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}

const loadDashMessagesSent = async () => {
  setIsMessageSentInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
        
      }
      dashMessageSent(payload).then(res =>{
        setIsMessageSentInsightLoading(false)
        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setMessagesSent(res?.data?.data?.data?.messages_sent)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}

const loadDashActiveAutomationCount = async () => {
  setIsActiveAutomationInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
    let payload = {
      lsd_token : userInfo.loggedInUserInfo.lsd_token,
      fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
      
    }
    
    dashActiveAutomationCount(payload).then(res =>{
      setIsActiveAutomationInsightLoading(false)
        console.log("response Fuckira",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setActiveAutomationCount(res.data.data.payload[0]?.ActiveAutomations)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}


const loadDashTotalStories = async () => {
  setIsTotalStoryInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
        
      }
      dashStoriesCount(payload).then(res =>{
        setIsTotalStoryInsightLoading(false)
        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setSyncedStories(res.data.data.data.syncedStories)
          }
      })
  }else{
      // setLoading(false)
      // setSyncing(false)
  }
}

const loadEngagerStoriesSynced = async () => {

  // setIsTotalStoryInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
        
      }
      fetchEngagerStoriesSynced(payload).then(res =>{
        // setIsTotalStoryInsightLoading(false)
        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setEngagersStorySynced(res.data.data.count)
          }
      })
  }
}

const loadEngagerStoriesReacted = async () => {

  // setIsTotalStoryInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
        
      }
      fetchEngagerStoriesReacted(payload).then(res =>{
        // setIsTotalStoryInsightLoading(false)
        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setEngagersStorysReacted(res.data.data.count)
          }
      })
  }
}

const loadEngagerUniqueStoriesIEngaged = async () => {

  // setIsTotalStoryInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
        
      }
      fetchUniqueEngagerStories(payload).then(res =>{
        // setIsTotalStoryInsightLoading(false)
        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setuniqueStoriesEngaged(res.data.data.count)
          }
      })
  }
}
const loadEngagerUniqueUsersEngaged = async () => {

  // setIsTotalStoryInsightLoading(true)
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
      let payload = {
          lsd_token : userInfo.loggedInUserInfo.lsd_token,
          fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
        
      }
      fetchUniqueEngagerUsers(payload).then(res =>{
        // setIsTotalStoryInsightLoading(false)
        console.log("response ______",res)
          // setLoading(false)

          // console.log("response sidepanel automation insights ",res.data.data)
          // setSyncing(false)
          if(res.data.data.status){
            setUniqueUsersEngaged(res.data.data.count)
          }
      })
  }
}



    const timeAgoStoryUpload = (creation_time) => {
                            
      let timeAgo = moment.unix(creation_time).fromNow();
      timeAgo = timeAgo.substring(0, timeAgo.lastIndexOf(' '));

      if (timeAgo.includes('hours'))
        return timeAgo = timeAgo.replace('hours', 'h');
      if (timeAgo.includes('hour'))
        return timeAgo = timeAgo.replace('an hour', '1 h');
      if (timeAgo.includes('minutes'))
        return timeAgo = timeAgo.replace('minutes', 'm');
      if (timeAgo.includes('minute'))
        return timeAgo = timeAgo.replace('a minute', '1 m');
      if (timeAgo.includes('seconds'))
        return timeAgo = timeAgo.replace('seconds', 's');

      return timeAgo
    }
  const loadStoryInsights = async () => {
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
    if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
        let payload = {
            lsd_token : userInfo.loggedInUserInfo.lsd_token,
            storyId : props.storyId,
            fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
          
        }
        automationInsight(payload).then(res =>{
            // setLoading(false)

            // console.log("response sidepanel automation insights ",res.data.data)
            // setSyncing(false)
            if(res.data.status){
              setAutomationInsight(res.data.data.data)
              // setViewerlist(res.data.data.payload.data)
            }
        })
    }else{
        // setLoading(false)
        // setSyncing(false)
    }
}


  useEffect(()=>{
  if(props.storyId){

    loadStoryInsights()
  }else{
    // loadTop20Users()
    loadTop3Stories()
    loadDashAutomationCount()
    loadDashMessagesSent()
    loadDashTotalStories()
    loadEngagementCount()
    loadDashActiveAutomationCount()
    loadEngagerStoriesSynced()
    loadEngagerStoriesReacted()
    loadEngagerUniqueStoriesIEngaged()
    loadEngagerUniqueUsersEngaged()
  }
  },[])
  return (
      <>
       {topEngagersModal && 
                    <TopTwenty
                        modal={setTopEngagersModal}
                        // topTwenty={top20Users}
                        addClass="top-engagers-modal"
                    />
        }
        {userInteractionModal && 
                    <Interacted
                        modal={setUserInteractionModal}
                        // topTwenty={top20Users}
                        addClass="top-engagers-modal"
                    />}
          {!pathname.match('setupstory')?  <aside className="content-addition">
    <div className="additional-info-component">
    <div className="subHeader-content-container d-flex f-justify-between f-align-center">
        <div className="header-content">
          <h3>
            <figure>
              <img src="../images/ico-chart.png" alt="" />
            </figure>
            Insights
          </h3>
        </div>
        {/* <div className="custom-select select-range-insights">
          <select>
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
        </div> */}
      </div> 
 
      <div className="insights-aside d-flex f-wrap">
        {props?.mode !== 'engagement' ? <>
          <div className={isTotalStoryInsightLoading?"insights-element sk-loader":"insights-element"}>
            <header>
              <h4>{syncedStories}</h4>
            </header>
            <p>Total no of Stories</p>
          </div>
        
          <div className={isAutomationCreatedInsightLoading?"insights-element sk-loader":"insights-element"}>
            <header>
              <h4>{totalAutomations}</h4>
            </header>
            <p>Automation Created</p>
          </div>
          <div className={isMessageSentInsightLoading?"insights-element sk-loader":"insights-element"}>
            <header>
              <h4>{messages_sent}</h4>
            </header>
            <p>Message Sent</p>
          </div>
          <div className={isActiveAutomationInsightLoading?"insights-element sk-loader":"insights-element"}>
            <header>
              <h4>{activeAutomationCount}</h4>
            </header>
            <p>Total Active Automations</p>
          </div>
        
          <div  onClick={()=>setTopEngagersModal(true)} className={isTopTwentyInsightLoading?"clickable-insights insights-element sk-loader":"clickable-insights insights-element"} >
            <header>
              <ul>
                <li
                  style={{ backgroundImage: `url('../images/user-image.png')` }}
                ></li>
                <li
                  style={{ backgroundImage: `url('../images/user-image.png')` }}
                ></li>
                <li
                  style={{ backgroundImage: `url('../images/user-image.png')` }}
                ></li>
                <li
                  style={{ backgroundImage: `url('../images/user-image.png')` }}
                ></li>
              </ul>
              <span className="more-engagers">{top20Users.length}</span>
            </header>
            <p>Top 20 engagers</p>
            

            {console.log("topTwenty",top20Users)}
          </div>
          </> : <>
            <div className="new-insights insights-element">
              <figure>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 3.11 16.44 3.11 16.44M3.11 16.44H7.63M3.11 16.44V21.44M2 12C2 6.48 6.44 2 12 2C18.67 2 22 7.56 22 7.56M22 7.56V2.56M22 7.56H17.56" stroke="#009087" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </figure>
              <h6>Stories synced</h6>
              <h3>{engagersStorySynced?engagersStorySynced:"N/A"}</h3>
            </div>
            <div className="new-insights insights-element">
              <figure>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.5485 19.6357L22.0035 23.3223C22.1201 24.2907 21.0818 24.9673 20.2535 24.4657L16.2168 22.0623C15.9368 21.899 15.8668 21.549 16.0185 21.269C16.6018 20.1957 16.9168 18.9823 16.9168 17.769C16.9168 13.499 13.2535 10.0223 8.75012 10.0223C7.82845 10.0223 6.93012 10.1623 6.09012 10.4423C5.65845 10.5823 5.23845 10.1857 5.34345 9.74232C6.40512 5.49565 10.4885 2.33398 15.3651 2.33398C21.0585 2.33398 25.6668 6.63898 25.6668 11.9473C25.6668 15.0973 24.0451 17.8857 21.5485 19.6357Z" fill="#00A053"/>
                  <path d="M15.1673 17.7682C15.1673 19.1565 14.654 20.4398 13.7907 21.4548C12.6357 22.8548 10.804 23.7532 8.75065 23.7532L5.70565 25.5615C5.19232 25.8765 4.53898 25.4448 4.60898 24.8498L4.90065 22.5515C3.33732 21.4665 2.33398 19.7281 2.33398 17.7682C2.33398 15.7148 3.43065 13.9065 5.11065 12.8332C6.14898 12.1565 7.39732 11.7715 8.75065 11.7715C12.2973 11.7715 15.1673 14.4548 15.1673 17.7682Z" fill="#00A053"/>
                </svg>
              </figure>
              <h6>Stories replied via engager</h6>
              <h3>{engagerStorysReplied?engagerStorysReplied:'N/A'}</h3>
            </div>
            <div className="new-insights insights-element">
              <figure>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.78809 21.5718V9.71851C9.78809 9.25184 9.92809 8.79684 10.1848 8.41184L13.3698 3.67517C13.8714 2.91684 15.1198 2.38017 16.1814 2.77684C17.3248 3.16184 18.0831 4.44517 17.8381 5.58851L17.2314 9.40351C17.1848 9.75351 17.2781 10.0685 17.4764 10.3135C17.6748 10.5352 17.9664 10.6752 18.2814 10.6752H23.0764C23.9981 10.6752 24.7914 11.0485 25.2581 11.7018C25.7014 12.3318 25.7831 13.1485 25.4914 13.9768L22.6214 22.7152C22.2598 24.1618 20.6848 25.3402 19.1214 25.3402H14.5714C13.7898 25.3402 12.6931 25.0718 12.1914 24.5702L10.6981 23.4152C10.1264 22.9835 9.78809 22.2952 9.78809 21.5718Z" fill="#049FC1"/>
                  <path d="M6.07801 7.44336H4.87634C3.06801 7.44336 2.33301 8.14336 2.33301 9.87003V21.6067C2.33301 23.3334 3.06801 24.0334 4.87634 24.0334H6.07801C7.88634 24.0334 8.62134 23.3334 8.62134 21.6067V9.87003C8.62134 8.14336 7.88634 7.44336 6.07801 7.44336Z" fill="#049FC1"/>
                </svg>
              </figure>
              <h6>Stories reacted via engager</h6>
              <h3>{engagersStorysReacted?engagersStorysReacted:"N/A"}</h3>
            </div>
            <div className="new-insights insights-element">
              <figure>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.4997 2.33301C7.44301 2.33301 4.95801 4.81801 4.95801 7.87467C4.95801 10.873 7.30301 13.2997 10.3597 13.4047C10.453 13.393 10.5463 13.393 10.6163 13.4047C10.6397 13.4047 10.6513 13.4047 10.6747 13.4047C10.6863 13.4047 10.6863 13.4047 10.698 13.4047C13.6847 13.2997 16.0297 10.873 16.0413 7.87467C16.0413 4.81801 13.5563 2.33301 10.4997 2.33301Z" fill="#049FC0"/>
                  <path d="M16.4271 16.5084C13.1721 14.3384 7.86378 14.3384 4.58544 16.5084C3.10378 17.5 2.28711 18.8417 2.28711 20.2767C2.28711 21.7117 3.10378 23.0417 4.57378 24.0217C6.20711 25.1184 8.35378 25.6667 10.5004 25.6667C12.6471 25.6667 14.7938 25.1184 16.4271 24.0217C17.8971 23.03 18.7138 21.7 18.7138 20.2534C18.7021 18.8184 17.8971 17.4884 16.4271 16.5084Z" fill="#049FC0"/>
                  <path d="M23.3219 8.56312C23.5086 10.8265 21.8986 12.8098 19.6702 13.0781C19.6586 13.0781 19.6586 13.0781 19.6469 13.0781H19.6119C19.5419 13.0781 19.4719 13.0781 19.4136 13.1015C18.2819 13.1598 17.2436 12.7981 16.4619 12.1331C17.6636 11.0598 18.3519 9.44979 18.2119 7.69979C18.1302 6.75479 17.8036 5.89146 17.3136 5.15646C17.7569 4.93479 18.2702 4.79479 18.7952 4.74812C21.0819 4.54979 23.1236 6.25312 23.3219 8.56312Z" fill="#049FC0"/>
                  <path d="M25.6546 19.3551C25.5613 20.4868 24.838 21.4668 23.6246 22.1318C22.458 22.7735 20.988 23.0768 19.5296 23.0418C20.3696 22.2835 20.8596 21.3385 20.953 20.3351C21.0696 18.8885 20.3813 17.5001 19.0046 16.3918C18.223 15.7735 17.313 15.2835 16.3213 14.9218C18.8996 14.1751 22.143 14.6768 24.138 16.2868C25.2113 17.1501 25.7596 18.2351 25.6546 19.3551Z" fill="#049FC0"/>
                </svg>
              </figure>
              <h6>Users I engaged</h6>
              <h3>{uniqueUsersEngaged?uniqueUsersEngaged:"N/A"}</h3>
            </div>
            <div className="new-insights insights-element">
              <figure>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.9997 18.6777C21.6413 18.6777 22.1663 19.2027 22.1663 19.8444V20.9994C22.1663 23.5661 20.4163 25.6661 17.4997 25.6661H10.4997C7.58301 25.6661 5.83301 23.5661 5.83301 20.9994V19.8444C5.83301 19.2027 6.35801 18.6777 6.99967 18.6777H20.9997Z" fill="#00A053"/>
                  <path d="M22.1663 6.99967V15.7497C22.1663 16.3913 21.6413 16.9163 20.9997 16.9163H6.99967C6.35801 16.9163 5.83301 16.3913 5.83301 15.7497V6.99967C5.83301 4.43301 7.58301 2.33301 10.4997 2.33301H17.4997C20.4163 2.33301 22.1663 4.43301 22.1663 6.99967Z" fill="#00A053"/>
                  <path d="M3.5 24.2087C3.02167 24.2087 2.625 23.812 2.625 23.3337V4.66699C2.625 4.18866 3.02167 3.79199 3.5 3.79199C3.97833 3.79199 4.375 4.18866 4.375 4.66699V23.3337C4.375 23.812 3.97833 24.2087 3.5 24.2087Z" fill="#00A053"/>
                  <path d="M24.5 24.2087C24.0217 24.2087 23.625 23.812 23.625 23.3337V4.66699C23.625 4.18866 24.0217 3.79199 24.5 3.79199C24.9783 3.79199 25.375 4.18866 25.375 4.66699V23.3337C25.375 23.812 24.9783 24.2087 24.5 24.2087Z" fill="#00A053"/>
                </svg>
              </figure>
              <h6>Unique stories I engaged</h6>
              <h3>{uniqueStoriesEngaged?uniqueStoriesEngaged:"N/A"}</h3>
            </div>
          </>
        }
          {/* <p> (COMING SOON)</p> */}
          {props?.mode !== 'engagement' ?
        <div onClick={()=>{setUserInteractionModal(true)}} className={isUsersInteractedInsightLoading?"clickable-insights insights-element sk-loader":"clickable-insights insights-element"}>
          <header>
            <h4>{totalEngagement}</h4>
          </header>
          <p>Users interacted with stories</p>
        </div>:""}
      </div>
    </div>
    {props?.mode !== 'engagement' ?
     
    <div className="additional-info-component boxed boxed-padding top-stories-dashboard">
      <div className="subHeader-content-container d-flex f-justify-between f-align-center">
        <div className="header-content ">
          <h3>Top 3 Stories</h3>&nbsp;
          {/* <p>(COMING SOON..)</p> */}
        </div>
        {/* topThreeStories */}
        {/* <div className="custom-select select-range-insights"> */}
          {/* <select className="no-shadow">
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select> */}
        {/* </div> */}
      </div>
      <div className="boxed-content aside-top-stories d-flex ">
      {isLoading?<>
        <div className="stories set sk-loader"></div>
        <div className="stories set sk-loader"></div>
        <div className="stories set sk-loader"></div>
        </>
        : ""}
        {!isLoading && topThreeStories.length==0?<>
                        <div className="no-content-listing">
                            <p>No data found</p>
                        </div></>
                        :
                        ""}
      {topThreeStories!=undefined && topThreeStories.length>0 && topThreeStories.map(story => {
        return(
          <div className="stories set">
          <header>
            <span className="timestamp">{timeAgoStoryUpload(story.creation_time)}</span>3
            <span className="views">
              <figure>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 3.375C5.25 3.375 2.0475 5.7075 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C12.75 14.625 15.9525 12.2925 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375ZM9 12.75C6.93 12.75 5.25 11.07 5.25 9C5.25 6.93 6.93 5.25 9 5.25C11.07 5.25 12.75 6.93 12.75 9C12.75 11.07 11.07 12.75 9 12.75ZM9 6.75C7.755 6.75 6.75 7.755 6.75 9C6.75 10.245 7.755 11.25 9 11.25C10.245 11.25 11.25 10.245 11.25 9C11.25 7.755 10.245 6.75 9 6.75Z"
                    fill="white"
                  />
                </svg>
              </figure>
              {story.story_total_viewers}
            </span>
          </header>
          <div className="body-story">
            {/* <!-- background if Image --> */}
            <figure
              style={{ backgroundImage: `url(${story.url})` }}
            >
                 <NavLink to = {'/setupstory/'+`${story.storyId}`} ></NavLink>
            </figure>
          </div>
          <footer className="footer-story">
            <label className="custom-check d-flex">
              {/* <input type="checkbox" name="" id="" defaultChecked disabled /> */}
              <span className="check-style"></span>
              <span className="check-text"></span>
            </label>
          </footer>
        </div>
        )
      })}
      </div>
    </div>
:""}
    {/* <div className="additional-info-component boxed boxed-padding engagement-insights-dashboard">
      <div className="subHeader-content-container d-flex f-justify-between f-align-center">
        <div className="header-content">
          <h3>
            <figure className="ico-chart-aside">
              <img src="./images/ico-chart-aside.png" alt="" />
            </figure>
            What type of story you have most uploaded in your profile ?
          </h3>
        </div>
      </div>
      <div className="boxed-content aside-insights-listing">
        <div className="aside-insights">
          <label className="d-flex w-100 f-justify-between">
            Poll stories
            <span className="progress-insight-label">15%</span>
          </label>
          <div className="insights-bar">
            <div className="progress-insight" style={{ width: "15%" }}></div>
          </div>
        </div>
        <div className="aside-insights">
          <label className="d-flex w-100 f-justify-between">
            Normal stories
            <span className="progress-insight-label">90%</span>
          </label>
          <div className="insights-bar">
            <div className="progress-insight" style={{ width: "90%" }}></div>
          </div>
        </div>
        <div className="aside-insights">
          <label className="d-flex w-100 f-justify-between">
            Normal stories
            <span className="progress-insight-label">15%</span>
          </label>
          <div className="insights-bar">
            <div className="progress-insight" style={{ width: "15%" }}></div>
          </div>
        </div>
      </div>
    </div> */}
  </aside>
  
  :
    /**  Autoamtion insights below */
  <aside className="content-addition">
    <div className="additional-info-component">
      <div className="subHeader-content-container d-flex f-justify-between f-align-center">
        <div className="header-content">
          <h3>
            <figure>
              <img src="./images/ico-chart.png" alt="" />
            </figure>
            Automation Insights
          </h3>
        </div>
      
      </div>
 
      <div className="insights-aside d-flex f-wrap">
        <div className="insights-element">
          <header>
            <h4>{ props.polls!=null && props.polls?.poll_options.length? automationInfo.option1.totalUsers:'N/A'}</h4>
          </header>
          <p> { props.polls!=null && props.polls?.poll_options.length? props.polls?.poll_options[0].text:'Option 1'}</p>
        </div>
        <div className="insights-element">
          <header>
            <h4>{ props.polls!=null && props.polls?.poll_options.length? automationInfo.option2.totalUsers:'N/A'}</h4>
          </header>
          <p> { props.polls!=null && props.polls?.poll_options.length? props.polls?.poll_options[1].text:'Option 2'}</p>
        </div>
        <div className="insights-element">
          <header>
            <h4>{automationInfo.onlyReaction.totalUsers}</h4>
          </header>
          <p>Only reaction(s)</p>
        </div>
        <div className="insights-element">
          <header>
            <h4>{automationInfo.nota.totalUsers}</h4>
          </header>
          <p>None of the above</p>
        </div>
        <div className="insights-element">
          <header>
            <h4>{automationInfo.messageDeliverd}</h4>
          </header>
          <p>Messages sent via automation</p>
        </div>
        </div>
        </div>
        </aside>}
      </>
  )
};

export default SidePanel;
