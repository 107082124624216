import { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import Config from '../../Config/env'
import moment from "moment";
import Sidebar from "../Sidebar/Sidebar";
import CreateAutomation from "./CreateAutomation";
import StoryViewers from "./StoryViewers";
import SidePanel from "../Sidepanel/SidePanel";
import { IndividualStory } from "../../Services/Storys/Storys";

const SetupStory = (props) => {
  document.title = `${Config.name} - Story Info`;
  const { storyId } = useParams();
  
  const [IndividualStoryInsights, setIndividualStoryInsights] = useState(
    {
        "viewer_count": "0",
        "total_reaction_count": "0",
        "creation_time": "0",
        "story_type": null,
        "story_attachement_uri": null,
        "automation_id": null,
        "_id": null,
        "polls" : null,
        "story_card_reactions" : null,
        "viewer_list" : null,
        
    }
)
  const loadIndividualStory = async () => {
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
    if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
        let payload = {
            lsd_token : userInfo.loggedInUserInfo.lsd_token,
            fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
            story_id : storyId
        }
  
        IndividualStory(payload).then(res =>{
            // setLoading(false)

            // console.log("response in SetupStory ",res.data.data)
            // setSyncing(false)
            if(res.data.data.status){
              let data = res.data.data.payload.data
              setIndividualStoryInsights({...IndividualStoryInsights, ...data })
            }
        })
    }else{
        // setLoading(false)
        // setSyncing(false)
    }
}

const convertEPOCtoHumanRedableDate = (epochdate) => {
  var myDate = new Date( epochdate*1000);
  return myDate.toDateString()
}
const convertEPOCtoHumanRedableTime = (epochdate) => {
  var myDate = new Date( epochdate*1000);
  return myDate.toTimeString()
}
  useEffect(() => {
    // console.log("macthc id", storyId);
    // console.log("props", props);
    loadIndividualStory()
    
  }, IndividualStoryInsights);

  return (
    <>
      <div className="content-container-main content-setup d-flex f-1">
        <div className="content-space f-1">
          <div className="header-content-container d-flex">
            <NavLink to = {'/home/'} className="go-back">
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.41016 10.59L2.83016 6L7.41016 1.41L6.00016 -6.16331e-08L0.00015614 6L6.00016 12L7.41016 10.59Z" fill="#323232"/>
              </svg>
            </NavLink>
            <div className="header-content flex">
              <div className="story-shots d-flex f-1">
                <div className={IndividualStoryInsights?"stories":"stories sk-loader"}>
                  <div className="body-story">
                    <figure
                      style={{
         
                        backgroundImage: `url(${IndividualStoryInsights?.story_attachement_uri})`,
                      }}
                    ></figure>
                  </div>
                </div>

                <div className="story-infos d-flex f-1">
                  <div className="story-items">
                    <span>Total Viewer’s</span>
                    <h3>{IndividualStoryInsights.viewer_count}</h3>

                    {/* <button className="btn">
                      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 -6.16331e-08L7.99984 6L1.99984 12L0.589844 10.59Z" fill="#CCE7FF"/>
                      </svg>
                    </button> */}

                    <figure className="items-image">
                      <svg width="137" height="96" viewBox="0 0 137 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_330_4586" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="137" height="87">
                      <path d="M0 0H137V87H20C8.95431 87 0 78.0457 0 67V0Z" fill="#1877F2"/>
                      </mask>
                      <g mask="url(#mask0_330_4586)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M98.1855 58.2148C105.721 63.3298 111.001 70.2598 111.001 79.4998V95.9998H133.001V79.4998C133.001 67.5098 113.366 60.4148 98.1855 58.2148Z" fill="rgb(50, 50, 50)" fillOpacity="0.2"/>
                      <path d="M56 52C68.1503 52 78 42.1503 78 30C78 17.8497 68.1503 8 56 8C43.8497 8 34 17.8497 34 30C34 42.1503 43.8497 52 56 52Z" fill="rgb(50, 50, 50)" fillOpacity="0.2"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M89.0005 52C101.156 52 111.001 42.155 111.001 30C111.001 17.845 101.156 8 89.0005 8C86.4155 8 83.9955 8.55 81.6855 9.32C86.2505 14.985 89.0005 22.19 89.0005 30C89.0005 37.81 86.2505 45.015 81.6855 50.68C83.9955 51.45 86.4155 52 89.0005 52Z" fill="rgb(50, 50, 50)" fillOpacity="0.2"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M56 57.5C41.315 57.5 12 64.87 12 79.5V96H100V79.5C100 64.87 70.685 57.5 56 57.5Z" fill="rgb(50, 50, 50)" fillOpacity="0.2"/>
                      </g>
                    </svg>
                    </figure>
                  </div>
                  
                  <div className="story-items">
                    <span>Total Reaction’s</span>
                    <h3>{IndividualStoryInsights.total_reaction_count}</h3>

                    {/* <button className="btn">
                      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 -6.16331e-08L7.99984 6L1.99984 12L0.589844 10.59Z" fill="#CCE7FF"/>
                      </svg>
                    </button> */}

                    <figure className="items-image">
                    <svg width="104" height="103" viewBox="0 0 104 103" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                      <mask id="mask0_330_4603" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="104" height="94">
                      <rect x="0.5" y="0.5" width="103" height="93" rx="18.5" fill="white" stroke="#BBC4D7"/>
                      </mask>
                      <g mask="url(#mask0_330_4603)">
                      <path d="M66.0792 101.004C90.919 94.3487 105.66 68.8164 99.0042 43.9766C92.3484 19.1369 66.8162 4.39584 41.9764 11.0516C17.1366 17.7074 2.39559 43.2396 9.05139 68.0794C15.7072 92.9192 41.2394 107.66 66.0792 101.004Z" fill="#E4F2FF"/>
                      <path d="M56.4084 68.5577C67.8167 65.5009 78.3814 64.5449 85.275 65.5771C85.1604 66.038 85.0307 66.4949 84.8907 66.9506C86.4706 62.3491 86.6488 57.3821 85.4026 52.6791C85.1355 51.6822 24.9338 67.8913 25.1814 68.8154C26.3626 73.1895 28.735 77.1498 32.0343 80.2551C31.7611 79.9821 31.4869 79.7054 31.2322 79.4195C36.8462 75.4001 45.8662 71.3825 56.4084 68.5577Z" fill="white"/>
                      <path d="M62.347 87.0777C73.481 84.0943 81.7875 76.2041 84.8906 66.9508C85.0306 66.4951 85.1602 66.0382 85.2749 65.5773C78.3813 64.5451 67.8166 65.5011 56.4082 68.5579C45.8661 71.3827 36.8461 75.4003 31.2321 79.4197C31.4924 79.7041 31.761 79.9823 32.0342 80.2553C32.2528 80.4635 32.474 80.667 32.698 80.8657C40.0145 87.3671 51.1813 90.0695 62.347 87.0777Z" fill="#BDDFFF"/>
                      <path d="M19.1742 46.9267L32.4397 49.8972C33.7041 50.1806 35.0383 49.507 35.3902 48.2142C35.7206 47.0052 34.9773 45.5456 33.7072 45.2636L20.4412 42.2913C19.1749 42.0083 17.8431 42.6833 17.4907 43.9743C17.1603 45.1833 17.9036 46.6429 19.1755 46.9244L19.1742 46.9267Z" fill="#BDDFFF"/>
                      <path d="M24.8559 58.538L34.8584 49.3309C35.8117 48.4532 35.7307 46.8141 34.8406 45.9341C33.8815 44.9906 32.4074 45.0674 31.4439 45.9519L21.4366 55.1562C20.4837 56.0358 20.5648 57.6749 21.4544 58.553C22.4135 59.4965 23.8871 59.4178 24.8511 58.5353L24.8559 58.538ZM76.2386 27.3382L66.2371 36.549C65.2819 37.4273 65.3629 39.0663 66.2548 39.9458C67.214 40.8893 68.6881 40.8125 69.6516 39.928L79.6598 30.7194C80.6145 29.8394 80.5339 28.2022 79.6439 27.3222C78.6847 26.3786 77.2018 26.4599 76.2471 27.3399L76.2386 27.3382Z" fill="#BDDFFF"/>
                      <path d="M81.8142 38.978L68.5487 36.0076C67.2824 35.7246 65.9487 36.4001 65.5981 37.6906C65.2678 38.8996 66.0111 40.3592 67.2811 40.6412L80.5472 43.6135C81.8228 43.8939 83.1472 43.2209 83.4977 41.9305C83.8281 40.7214 83.0848 39.2619 81.8147 38.9799L81.8142 38.978Z" fill="#BDDFFF"/>
                      </g>
                      </g>
                    </svg>
                    </figure>
                  </div>
                  
                  <div className="story-items">
                    <span>Time of Publish</span>
                    <p>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_330_4621)">
                      <path d="M12.75 7.5H5.25V9H12.75V7.5ZM14.25 2.25H13.5V0.75H12V2.25H6V0.75H4.5V2.25H3.75C2.9175 2.25 2.2575 2.925 2.2575 3.75L2.25 14.25C2.25 15.075 2.9175 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM14.25 14.25H3.75V6H14.25V14.25ZM10.5 10.5H5.25V12H10.5V10.5Z" fill="#BBC4D7"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_330_4621">
                      <rect width="18" height="18" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>

                      <span>{convertEPOCtoHumanRedableDate(IndividualStoryInsights.creation_time)}</span>
                    </p>
                    <p>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_330_4624)">
                    <path d="M8.9925 1.5C4.8525 1.5 1.5 4.86 1.5 9C1.5 13.14 4.8525 16.5 8.9925 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 8.9925 1.5ZM9 15C5.685 15 3 12.315 3 9C3 5.685 5.685 3 9 3C12.315 3 15 5.685 15 9C15 12.315 12.315 15 9 15Z" fill="#BBC4D7"/>
                    <path d="M9.375 5.25H8.25V9.75L12.1875 12.1125L12.75 11.19L9.375 9.1875V5.25Z" fill="#BBC4D7"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_330_4624">
                    <rect width="18" height="18" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>

                      <span>{convertEPOCtoHumanRedableTime(IndividualStoryInsights.creation_time)}</span>
                    </p>

                    {/* <button className="btn">
                      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 -6.16331e-08L7.99984 6L1.99984 12L0.589844 10.59Z" fill="#CCE7FF"/>
                      </svg>
                    </button> */}

                    <figure className="items-image">
                      <svg width="112" height="88" viewBox="0 0 112 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" d="M41.7405 2.3996C11.9389 10.3849 -5.58558 40.7383 2.39972 70.5398C10.385 100.341 40.7384 117.866 70.5399 109.881C100.341 101.895 117.866 71.5419 109.881 41.7404C101.895 11.9388 71.542 -5.5857 41.7405 2.3996ZM91.2294 56.1632C91.2644 58.2483 89.572 59.7491 87.4869 59.7841L58.6882 61.7409C57.7111 62.0027 56.4722 61.2874 55.3642 61.0606C53.1481 60.6072 51.7783 59.4033 51.2547 57.4491L41.7593 18.1033C41.1047 15.6606 42.6662 13.6713 44.6204 13.1477C46.5746 12.6241 49.0524 14.0546 49.5761 16.0088L59.0972 49.5879L86.8581 51.5746C89.4317 51.4086 91.0634 53.5896 91.2294 56.1632Z" fill="#E4F2FF"/>
                      </svg>
                    </figure>
                  </div>
                  
                  <div className="story-items">
                    <span>Story Type</span>
                    <h3>{IndividualStoryInsights.story_type == null? 'Normal Story':IndividualStoryInsights.story_type.split('StoryOverlay')}</h3>

                    {/* <button className="btn">
                      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 -6.16331e-08L7.99984 6L1.99984 12L0.589844 10.59Z" fill="#CCE7FF"/>
                      </svg>
                    </button> */}

                    <figure className="items-image">
                      <svg width="126" height="86" viewBox="0 0 126 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M109.798 3.57082C108.8 3.27059 107.755 3.26399 106.776 3.52628C105.958 3.74557 105.19 4.15208 104.536 4.72861L92.7099 15.2185L92.1624 13.1752C89.7918 4.32782 80.6628 -0.942816 71.8154 1.42782L12.9759 17.1938C4.12853 19.5645 -1.1421 28.6935 1.22853 37.5408L12.6947 80.3332C15.0654 89.1806 24.1944 94.4512 33.0417 92.0806L91.8813 76.3146C100.729 73.9439 105.999 64.8149 103.629 55.9676L103.081 53.9242L118.566 57.0905C119.422 57.2682 120.291 57.2361 121.109 57.0168C122.088 56.7545 122.99 56.2262 123.704 55.4673C125.019 54.0656 125.522 52.0906 125.025 50.2345L113.559 7.44206C113.061 5.58594 111.638 4.12688 109.798 3.57082ZM35.8568 59.7942C31.4224 60.9824 26.8715 58.3549 25.6833 53.9206C24.4951 49.4862 27.1226 44.9353 31.557 43.7471C35.9913 42.5589 40.5423 45.1864 41.7305 49.6207C42.9186 54.0551 40.2912 58.606 35.8568 59.7942Z" fill="#E4F2FF"/>
                      </svg>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dash-main-content dashboard-content">


         
          <CreateAutomation storyId={storyId} automationInfo={IndividualStoryInsights?.automation_id} polls={IndividualStoryInsights?.polls} />
          



           {IndividualStoryInsights.viewer_list!=null? <StoryViewers viewer_list={IndividualStoryInsights.viewer_list} />: 'N/A'}
          </div>
        </div>

        <SidePanel storyId={storyId} polls={IndividualStoryInsights.polls} storyReactions={IndividualStoryInsights.story_card_reactions} />
      </div>
    </>
  );
};

export default SetupStory;
