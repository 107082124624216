import { useEffect, useState } from "react";
import "../../assets/style/style.css";
import { Navigate, NavLink } from "react-router-dom";
import { talkToExtension } from "../../Services/ChromeStore/ChromeStore";
import { Storys,FetchEngagerSettings,UpdateEngagerSettings } from "../../Services/Storys/Storys";
import Alertbox from "../Toast/Toast";
import Story from "../Story/Story";

const logo = "./images/logo.png";
const authFacebook = "./images/auth-facebook.png";

const Header = (props) => {

  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [toggleUserMenu, setToggleUserMenu] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [fbProfileImg,setFbProfileImg] = useState('./images/profile.gif')
  const [engage, setEngage] = useState(false)
  const [isSyncing,setSyncing] = useState(false)
  const [isLoading,setLoading] = useState(true)
  const [stories,setStories] = useState([])
  const [totalNoOfStories,setTotalStoriesCount] = useState(0)
  const [vitamins,setVitamins] = useState({
    "appName": "Let Story Drive",
    "secondaryLogo": "./images/logo-in.png",
    "extensionId": "",
    "changePasswordUrl": "",
    "backendUrl": "https://letstorydrive.io",
    "mailTo": "",
    "description": {
        "short_description": "",
        "long_description": ""
    }
})

  const [autoEngager, setAutoEngager] = useState(false) 
  const fetchProfileImage = ()=> {
    talkToExtension("profileInfo",callback =>{
      if(callback?.response?.fbUserInfo?.data?.data?.user!=undefined && callback?.response?.fbUserInfo?.data?.data?.user!=null ){
        localStorage.setItem('portalProfileImg',callback.response.fbUserInfo.data.data.user.profile_composer_info.viewer.actor
        .profile_picture.uri)
        setFbProfileImg(callback.response.fbUserInfo.data.data.user.profile_composer_info.viewer.actor
          .profile_picture.uri)
      }
    })
  }

  const onLogout = () => {
    localStorage.clear('_lsd_token')
    setRedirectToLogin(true)
}

const engagement = async () => {
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  talkToExtension('saveSettings',extension=>{
  
    console.log("respnse from EXTENSION for portal engaer settings",extension)
})
  if(userInfo.loggedInUserInfo.lsd_token!=undefined && userInfo.loggedInUserInfo.lsd_token){
    UpdateEngagerSettings({ lsd_token : userInfo.loggedInUserInfo.lsd_token,toggleEngager : !autoEngager }).then(res=>{
      console.log("response settings",res)
      Alertbox(
        `${res.data.data.message}`,
        res.data.data.status?"success":"error",
        5000,
        "bottom-right"
    );
      if(res.data.data.status){
          setAutoEngager(!autoEngager)
       }
     })
   }
}

const loadAllStorys = async () => {
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
    if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
        let payload = {
            lsd_token : userInfo.loggedInUserInfo.lsd_token,
            fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId
        }
        console.log("payload fetch all storys",payload)
        Storys(payload).then(res =>{
        setLoading(false)

            console.log("response in HOME ",res.data.data)
            setSyncing(false)
            if(res.data.data.status)
            setStories(res.data.data.payload.stories)
            setTotalStoriesCount(res.data.data.payload.totalNoOfStories)
        })
    }else{
        setLoading(false)
        setSyncing(false)
    }
}

const sync = () => {
    setSyncing(true)
    talkToExtension('sync',extension=>{
        if(extension?.response?.response?.type == "error"){
          Alertbox(
            `${extension?.response?.response?.data?.error}`,
            "success",
            5000,
            "bottom-right"
        );
        }else{
          Alertbox(
            `${extension?.response?.response?.data?.success}`,
            "success",
            5000,
            "bottom-right"
        );
            setTimeout(()=>{
                loadAllStorys()
            },30000)

        }
        console.log("respnse from sync",extension?.response?.response?.data)
    })
    // setLoading(true)
}
async function fetchSettings(){
  console.log("api call")
  let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
  if(userInfo.loggedInUserInfo.lsd_token!=undefined && userInfo.loggedInUserInfo.lsd_token){
    FetchEngagerSettings({ lsd_token : userInfo.loggedInUserInfo.lsd_token}).then(res=>{
      console.log("response settings",res)
      if(res.data.data.status){
        if(res.data.data.data.autoEngager == true){
          setAutoEngager(true)
        }
      }
    })
  }
}
  //One time calls
  useEffect(()=>{
    fetchProfileImage()
    if(Object.keys(JSON.parse(localStorage.getItem('vitaminsForPortal'))).length){
      let vitamins = JSON.parse(localStorage.getItem('vitaminsForPortal'))
      document.title = `${vitamins.appName} - Login`;
         setVitamins(vitamins)
  }
  fetchSettings()
  },[])


  return (
    <>
      
      {redirectToLogin?   <Navigate to="/login" replace={true} />: ''}

      
      <header className="header logged-header d-flex f-justify-between f-align-center">
        <div className="logo-header-container">
          <NavLink to="/home">
            <img 
              src={vitamins.secondaryLogo} 
              // height={40}
              height={59}
              alt=""
            />
          </NavLink>
        </div>

        <div className="user-options-header d-flex">
          <button onClick={engagement} className={autoEngager?"btn btn-gradient btn-header-main btn-start-engagement working":"btn btn-gradient btn-header-main btn-start-engagement"}> 
          {/**Class - syncing FOR LOADING PHASE*/}
              <span>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.98995 9.9601H9.30745V15.3601C9.30745 16.1551 10.2974 16.5301 10.8224 15.9301L16.4999 9.4801C16.9949 8.9176 16.5974 8.0401 15.8474 8.0401H13.53V2.6401C13.53 1.8451 12.5399 1.4701 12.0149 2.0701L6.33745 8.5201C5.84995 9.0826 6.24745 9.9601 6.98995 9.9601Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M6.375 3H1.125" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M5.625 15H1.125" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M3.375 9H1.125" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
              {autoEngager ? "Stop Auto engagement" : "Start Auto engagement"}</button>
          <button onClick={sync} className={isSyncing?"btn btn-gradient btn-header-main btn-sync-title syncing":"btn btn-gradient btn-header-main btn-sync-title"}> 
          {/**Class - syncing FOR LOADING PHASE*/}
              <span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 10L5.70711 9.29289L5 8.58579L4.29289 9.29289L5 10ZM9.70711 13.2929L5.70711 9.29289L4.29289 10.7071L8.29289 14.7071L9.70711 13.2929ZM4.29289 9.29289L0.292893 13.2929L1.70711 14.7071L5.70711 10.7071L4.29289 9.29289Z" fill="white"/>
                  <path d="M15.5 18.0622C14.4359 18.6766 13.2288 19 12 19C10.7712 19 9.56413 18.6766 8.5 18.0622C7.43587 17.4478 6.5522 16.5641 5.93782 15.5C5.32344 14.4359 5 13.2288 5 12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                  <path d="M19 14L18.2929 14.7071L19 15.4142L19.7071 14.7071L19 14ZM14.2929 10.7071L18.2929 14.7071L19.7071 13.2929L15.7071 9.29289L14.2929 10.7071ZM19.7071 14.7071L23.7071 10.7071L22.2929 9.29289L18.2929 13.2929L19.7071 14.7071Z" fill="white"/>
                  <path d="M8.5 5.93782C9.56413 5.32344 10.7712 5 12 5C13.2288 5 14.4359 5.32344 15.5 5.93782C16.5641 6.5522 17.4478 7.43587 18.0622 8.5C18.6766 9.56414 19 10.7712 19 12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
              </svg>
              </span>
              Sync</button>
          <button
            // onClick={() => setToggleUserMenu(!toggleUserMenu)}
            className="btn d-flex user-options-trigger"
            style={{ backgroundImage:  `url(${fbProfileImg})` }}
          ></button>

          {toggleUserMenu && (
            <div className="user-options-menu">
              <ul>
                <li>
                  <button
                    className="btn w-100"
                    onClick={(e) => {
                      setChangePasswordModal(true);
                      setToggleUserMenu(false);
                    }}
                  >
                    Change Password
                  </button>
                </li>
                <li>
                  <button className="btn w-100" onClick={onLogout}>
                    Log-out
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </header>

      {changePasswordModal && (
        <div className="changePassword-modal modal-general">
          <div className="modal-overlay"></div>
          <div className="modal-outer modal-outer-changePassword">
            <div className="modal-header d-flex f-justify-between f-align-center">
              <h4>Change Password</h4>
              <button
                className="btn btn-close-modal"
                onClick={(e) => setChangePasswordModal(false)}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-container">
                <form>
                  <div className="form-group">
                    <label className="form-label">New password</label>
                    <div className="form-element">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="gautamkumar@tier5.in"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Confim new password</label>
                    <div className="form-element">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="gautamkumar@tier5.in"
                      />
                    </div>
                  </div>
                  <div className="form-group form-footer">
                    <button className="btn btn-theme btn-blue w-100">
                      Change my password
                    </button>
                  </div>
                </form>
              </div>

              {/* SUCCESS UI FOR CHANGE PASSWORD */}
              {/* <div class="success-space password-change-success text-center">
                    <figure class="success-icon">
                        <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <g clip-path="url(#clip0_834_1270)">
                            <path
                            d="M50 0C40.111 0 30.444 2.93245 22.2215 8.42652C13.9991 13.9206 7.59043 21.7295 3.80605 30.8658C0.0216641 40.0021 -0.968503 50.0555 0.960758 59.7545C2.89002 69.4536 7.65206 78.3627 14.6447 85.3553C21.6373 92.348 30.5465 97.11 40.2455 99.0393C49.9446 100.969 59.9979 99.9784 69.1342 96.194C78.2705 92.4096 86.0794 86.001 91.5735 77.7785C97.0676 69.5561 100 59.8891 100 50C99.9855 36.7436 94.7131 24.0343 85.3394 14.6606C75.9657 5.28697 63.2564 0.0144754 50 0V0ZM50 95.3125C41.0381 95.3125 32.2773 92.655 24.8257 87.676C17.3741 82.697 11.5663 75.6201 8.13673 67.3403C4.70714 59.0606 3.8098 49.9497 5.55819 41.16C7.30658 32.3702 11.6222 24.2963 17.9592 17.9592C24.2963 11.6222 32.3702 7.30656 41.16 5.55817C49.9498 3.80977 59.0606 4.70711 67.3404 8.13671C75.6201 11.5663 82.697 17.3741 87.676 24.8257C92.655 32.2773 95.3125 41.038 95.3125 50C95.298 62.0132 90.5194 73.5302 82.0248 82.0248C73.5302 90.5194 62.0132 95.298 50 95.3125Z"
                            fill="#00C41F"
                            />
                            <path
                            d="M68.6718 33.0937L39.3906 61.8905L31.3828 53.4609C30.951 53.0273 30.3668 52.7798 29.7549 52.7713C29.1431 52.7627 28.5522 52.9938 28.1084 53.4151C27.6647 53.8364 27.4033 54.4145 27.3801 55.026C27.3569 55.6374 27.5738 56.2337 27.9843 56.6874L37.6328 66.8437C37.847 67.0684 38.1037 67.2484 38.388 67.3731C38.6723 67.4979 38.9786 67.5649 39.289 67.5702H39.3281C39.9418 67.5697 40.5309 67.3285 40.9687 66.8984L71.9453 36.4296C72.1648 36.2136 72.3397 35.9566 72.4599 35.673C72.5801 35.3895 72.6433 35.085 72.6458 34.7771C72.6483 34.4691 72.5902 34.1637 72.4747 33.8782C72.3592 33.5927 72.1886 33.3328 71.9726 33.1132C71.7567 32.8936 71.4996 32.7188 71.216 32.5986C70.9325 32.4784 70.628 32.4152 70.3201 32.4127C70.0121 32.4101 69.7067 32.4683 69.4212 32.5838C69.1357 32.6993 68.8758 32.8699 68.6562 33.0859L68.6718 33.0937Z"
                            fill="#00C41F"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_834_1270">
                            <rect width="100" height="100" fill="white" />
                            </clipPath>
                        </defs>
                        </svg>
                    </figure>

                    <p>
                        Your password updated sucessfully !
                    </p>

                    <button className="btn btn-theme btn-blue w-100" onClick={() => setChangePasswordModal(false)}>
                        Close
                    </button>
                </div> */}
              {/* SUCCESS UI FOR CHANGE PASSWORD */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
