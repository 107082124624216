import axios from "axios";
import jwt_decode from "jwt-decode";
import history from "../../history";

import errorMessage from "../../Messages/error";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "",
};

// export const Login = (loginDetails) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(
//         loginUrl,
//         {
//           email: loginDetails.email,
//           password: loginDetails.password,
//         },
//         { headers: headers }
//       )
//       .then((result) => {
//         localStorage.setItem("_kyubi_token", result.data.token);
//         resolve(true);
//       })
//       .catch((error) => {
//         if (error != null && error.response != null) {
//           reject(error.response.data);
//         } else {
//           reject(errorMessage.connectionError);
//         }
//       });
//   });
// };

export const checkLogin = () => {
  const token = localStorage.getItem("_lsd_token");
  if (!token) {
    console.log("false")
    return false;
  }else{
    return true
  }
};

// export const logout = () => {
//   localStorage.removeItem("_kyubi_token");
//   localStorage.removeItem("kyubi_balance_data");
//   localStorage.removeItem("kyubi_ads_data");
//   localStorage.removeItem("balance_api_running");
//   localStorage.removeItem("check_for_balance_change");
//   localStorage.removeItem("_kyubi_su_stats");
//   history.push("/login");
//   // window.location = "/login";
// };

// export const generateToken = (data) => {
//   return new Promise((resolve, reject) => {
//     let url = "";
//     if (data.extId) url = generateTokenEndUserUrl;
//     else url = generateTokenUrl;
//     axios
//       .post(url, data, { headers: headers })
//       .then((result) => {
//         resolve(true);
//       })
//       .catch((error) => {
//         if (error != null && error.response != null) {
//           reject(error.response.data);
//         } else {
//           reject(errorMessage.connectionError);
//         }
//       });
//   });
// };

// export const resetPassword = (data) => {
//   return new Promise((resolve, reject) => {
//     let url = "";
//     if (data.type == "user") url = resetPasswordUrl + data.token;
//     else url = resetPasswordEndUserUrl + data.token;
//     axios
//       .post(url, data, { headers: headers })
//       .then((result) => {
//         resolve(true);
//       })
//       .catch((error) => {
//         if (error != null && error.response != null) {
//           reject(error.response.data);
//         } else {
//           reject(errorMessage.connectionError);
//         }
//       });
//   });
// };
