import axios from "axios";
import history from "../../history";
import {
    getAllStories,
    getIndividualStory,
    getIndividualStoryViewerList,
    getStoryInsight,
    getDashAutomationCount,
    getDashStoriesCount,
    getDashMessgesSent,
    getDashEngagement,
    getActiveAutomationCount,
    getTop3Stories,
    getTop20Users,
    usersInteracted,
    getAllFriendsStories,
    getEngagerSettings,
    updateEngagerSettings,
    getEngagerStorysSynced,
    getEngagerUniqueStoriesEngaged,
    getEngagerStorysReacted,
    getEngagerUniqueUsersEngaged
  } from "../../Config/Url";
import errorMessage from "../../Messages/error";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "",
};


export const FetchEngagerSettings = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getEngagerSettings,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched engaer setttings",{status : true, data : result})
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const fetchUniqueEngagerUsers = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getEngagerUniqueUsersEngaged,
        payload,
        { headers: headers }
      )
      .then((result) => {
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const fetchUniqueEngagerStories = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getEngagerUniqueStoriesEngaged,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched engaer setttings",{status : true, data : result})
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const fetchEngagerStoriesReacted = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getEngagerStorysReacted,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched engaer setttings",{status : true, data : result})
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const fetchEngagerStoriesSynced = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getEngagerStorysSynced,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched engaer setttings",{status : true, data : result})
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const UpdateEngagerSettings = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        updateEngagerSettings,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("update engaer setttings",{status : true, data : result})
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};
export const dashAutomationCount = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getDashAutomationCount,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const top3Stories = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getTop3Stories,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const topTwentyUsers = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getTop20Users,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const usersInteraction = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        usersInteracted,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};
export const dashActiveAutomationCount = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getActiveAutomationCount,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const dashEngagementCount = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getDashEngagement,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};


export const dashStoriesCount = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getDashStoriesCount,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const dashMessageSent = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getDashMessgesSent,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};


export const FetchFriendStorys = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getAllFriendsStories,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched all Friends fb storys",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const Storys = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getAllStories,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched all the fb storys",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const IndividualStory = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getIndividualStory,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};


export const automationInsight = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getStoryInsight,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const ViewerList = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getIndividualStoryViewerList,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("fetched the API response",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};