module.exports = {
  appEnv:"local", //['local', 'development', 'production']
  backendUrl: "https://letstorydrive.io/api/",
  baseUrl: "https://letstorydrive.io",
  extensionID : "gcbopejmaooomanljccdndjkljgippha",
  name : "Let Story Drive",
  shortName : "LSD",


  // backendUrl: "http://localhost:8000/api/",
  // baseUrl: "http://localhost:3000",
  // extensionID  : "jcabnpjennifbocjpodbhijmoaaoffdm"


};
