import { useEffect, useState } from "react";
import { Angry, Care, Haha, Like, Love, Sad, Wow } from "../../assets/images/Icons";
import { UpdateEngagerSettings, FetchEngagerSettings } from "../../Services/Storys/Storys";
import Alertbox from "../Toast/Toast";
import { talkToExtension } from "../../Services/ChromeStore/ChromeStore";

const SettingsPanel = ({ reactions, setReactions, setSettingsPanel,engagementLimit,setEngaggementLimit }) => {
    const [react, setReact] = useState(reactions);
    // const [perPersonEngagementLimit, setPerPersonEngagementLimit] = useState(engagementLimit);

    const [storyLimit, setStoryLimit] = useState(engagementLimit!=undefined && engagementLimit?engagementLimit:2);
    const [error, setError] = useState('');

    // Function to handle onChange event for reaction inputs
    const handleReactionChange = (e, reactionType) => {
        let value = Number(e.target.value);

        // Ensure the value is a valid number and between 1 and 5
        if (isNaN(value) || value < 0 || value > 5 || value % 1 !== 0) {
            Alertbox(
                `Please enter a valid number between 1 and 5 for ${reactionType} reaction`,
                "error",
                5000,
                "bottom-right"
            );

        } else {
            setError('');
            setReact({ ...react, [reactionType]: value });
        }
    };

    // Function to handle onChange event for story limit
    const handleStoryLimitChange = (e) => {
        let value = Number(e.target.value);

        // Ensure the value is a valid number and between 1 and 10
        if (isNaN(value) || value < 0 || value > 10 || value % 1 !== 0) {
            Alertbox(
                `Please enter a valid number between 1 and 10`,
                "error",
                5000,
                "bottom-right"
            );
            setError('Please enter a valid number between 1 and 10');
            let prevStoryLimit = storyLimit
            // setStoryLimit(value);
            clearError();
            // setInterval(()=>{
            //     setStoryLimit(prevStoryLimit?prevStoryLimit:1);
            // },3000)
        } else {
            setError('');
            setStoryLimit(value);
        }
    };

    const clearError = () => {
        setTimeout(() => {
            setError('');
        }, 2000);
    };

    const saveSettings = async () => {
        console.log("reactions", react,storyLimit);
        // API call here
        let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'));
        if (userInfo.loggedInUserInfo.lsd_token !== undefined && userInfo.loggedInUserInfo.lsd_token) {
            if(storyLimit<1){
                Alertbox(
                    `Please enter a valid number between 1 and 10 for per person storylimit`,
                    "error",
                    5000,
                    "bottom-right"
                );
                return
            }
            let payload = {
                lsd_token: userInfo.loggedInUserInfo.lsd_token,
                "portalEngagerSettings": {
                    "reactions": react,
                    "perPersonEngagementLimit": storyLimit
                }
            };
      
            UpdateEngagerSettings(payload).then(res => {
                talkToExtension('saveSettings', extension => {
                    console.log("response from EXTENSION for portal engager settings", extension);
                });
                console.log("response settings", res);
                if (res.data.data.status) {
                    setReactions(react);
                    setEngaggementLimit(storyLimit)
                    setSettingsPanel(false);
                    Alertbox(
                        `Settings updated successfully`,
                        "success",
                        5000,
                        "bottom-right"
                    );
                }
            });
        }
    };

    useEffect(() => {
        setReact(reactions);
        setStoryLimit(engagementLimit)
    }, [reactions,engagementLimit]);

    return (
        <aside className="engagement-settings">
            <header className="engagement-settings-header d-flex f-justify-between">
                Engagement settings
                <button
                    className="btn"
                    onClick={() => setSettingsPanel(false)}
                >
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.75674 12.5996L13.7567 3.59961" stroke="#31353B" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.7567 12.5996L4.75674 3.59961" stroke="#31353B" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </header>
            <section className="set-reactions f-1 d-flex f-column">
                <p className="header-note muted-text d-flex">
                    <figure>
                        <svg width="1" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99935 12.8337C10.2077 12.8337 12.8327 10.2087 12.8327 7.00033C12.8327 3.79199 10.2077 1.16699 6.99935 1.16699C3.79102 1.16699 1.16602 3.79199 1.16602 7.00033C1.16602 10.2087 3.79102 12.8337 6.99935 12.8337Z" stroke="#B6BCCA" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7 4.66699V7.58366" stroke="#B6BCCA" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.99609 9.33301H7.00133" stroke="#B6BCCA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </figure>
                    We randomize the reactions based on the selection and value provided, ensuring that repetitive automation is prevented
                </p>
                <ul className="d-flex">
                    {[
                        { label: 'Like', icon: <Like />, type: 'like' },
                        { label: 'Heart', icon: <Love />, type: 'heart' },
                        { label: 'Haha', icon: <Haha />, type: 'haha' },
                        { label: 'Wow', icon: <Wow />, type: 'wow' },
                        { label: 'Sad', icon: <Sad />, type: 'sad' },
                        { label: 'Care', icon: <Care />, type: 'care' },
                        { label: 'Angry', icon: <Angry />, type: 'angry' }
                    ].map(({ label, icon, type }) => (
                        <li key={type}>
                            <figure>{icon}</figure>
                            <label>{label}</label>
                            <input
                                type="number"
                                min="0"
                                max="5"
                                value={react[type]}
                                onChange={(e) => handleReactionChange(e, type)}
                            />
                        </li>
                    ))}
                </ul>

                <section className="settings-sections">
                    <p className="header-note muted-text d-flex">
                        <figure>
                            <svg width="1" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.99935 12.8337C10.2077 12.8337 12.8327 10.2087 12.8327 7.00033C12.8327 3.79199 10.2077 1.16699 6.99935 1.16699C3.79102 1.16699 1.16602 3.79199 1.16602 7.00033C1.16602 10.2087 3.79102 12.8337 6.99935 12.8337Z" stroke="#B6BCCA" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7 4.66699V7.58366" stroke="#B6BCCA" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.99609 9.33301H7.00133" stroke="#B6BCCA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </figure>
                        Specify the maximum number of stories you want to auto engage per person within a 24-hour period
                    </p>
                    <li>
                        <label>
                            Set Story Engagement Limit per Person (24 hours):
                        </label>
                        <input
                            type="number"
                            id="storyLimit"
                            value={storyLimit}
                            onChange={handleStoryLimitChange}
                            min="1"
                            max="10"
                            step="1" // Ensures only integer values can be selected
                        />
                    </li>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </section>

                <footer className="engagement-settings-footer text-right">
                    <button
                        className="btn"
                        onClick={() => setSettingsPanel(false)}
                    >Cancel</button>
                    <button
                        className="btn btn-gradient"
                        onClick={saveSettings}
                    >
                        Save
                    </button>
                </footer>
            </section>
        </aside>
    );
}
export default SettingsPanel;
