import { useEffect, useState } from "react";
import Config from "../../Config/env"
import { ViewerList } from "../../Services/Storys/Storys";
import { baseUrl } from "../../Config/env";
import InlineLoader from "../Loader/InlineLoader";

const StoryViewers = (props) => {

  const [viewerList, setViewerlist] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  const loadViewerList = async () => {
    setIsLoading(true)
    let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
    if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
        let payload = {
            lsd_token : userInfo.loggedInUserInfo.lsd_token,
            fb_user_id : userInfo.facebookInfo.fb_data_lsd.userId,
            viewer_list : props.viewer_list
        }
  
        ViewerList(payload).then(res =>{
          setIsLoading(false)
            // setLoading(false)
            console.log("response in Viwer listing ",res.data.data)
            // setSyncing(false)
            if(res.data.data.status){
              setViewerlist(res.data.data.payload.data)
            }
        })
    }else{
        // setLoading(false)
        // setSyncing(false)
    }
}

  useEffect(()=>{
    console.log("viewer list id",props.viewer_list)
    if(props.viewer_list!=null){
      loadViewerList()
      console.log("There are some viewers")
    }else{
      console.log("There are no viewers")
    }
  },[])

  return (
    <>
      <div className="story-viewers">
        <div className="table table-sortable">
          <div className="table-header">
            <div className="table-row">
              <div className="table-cell table-cell-header">
                Story viewers
              </div>
              <div className="table-cell table-cell-header">
                Reaction Given
              </div>
              <div className="table-cell table-cell-header">
                Viewer Selection/Reaction
              </div>
              <div className="table-cell table-cell-header">
               {" Message Sent from " + Config.shortName}
              </div>
            </div>
          </div>
          <div className="table-body">
            {console.log("vlist",viewerList)}
           {
            viewerList?.edges?.map(x=>{
                return (
                  
              <div className="table-row"> {/**active */}
              <div className="table-cell">
                <div className="table-cell-content d-flex">
                  <figure
                    className="table-user-image"
                    style={{
                      backgroundImage: `url(${x.node.profile_picture.uri})`,
                    }}
                  >
                    <span className="user-status available"></span>
                  </figure>

                  <div className="table-cell-textBody">
                   <a href={baseUrl+"/user-details/"+x.node.id}  target="_blank">
                   <h6>
                     {x.node.name}<span> {/**active-tick */}</span>
                    </h6>
                    </a>
                    <p>
                      <a
                        href={"https://www.facebook.com/"+ x.node.id}
                        target="_blank"
                        rel="noreferrer"
                      >
                        facebook.com/{x.node.id}
                        <svg
                          className="open-link"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.25 1.75V1.25H12.75V1.75H12.25ZM6.77022 7.93689C6.57496 8.13215 6.25837 8.13215 6.06311 7.93689C5.86785 7.74163 5.86785 7.42504 6.06311 7.22978L6.77022 7.93689ZM11.75 6.41667V1.75H12.75V6.41667H11.75ZM12.25 2.25H7.58333V1.25H12.25V2.25ZM12.6036 2.10355L6.77022 7.93689L6.06311 7.22978L11.8964 1.39645L12.6036 2.10355Z"
                            fill="#7D8FB3"
                          />
                          <path
                            d="M11.6667 8.75004V8.75004C11.6667 10.3609 10.3609 11.6667 8.75008 11.6667H7.00008C5.44558 11.6667 4.66833 11.6667 4.06556 11.3856C3.42634 11.0876 2.91257 10.5738 2.61449 9.93456C2.33342 9.33179 2.33342 8.55454 2.33342 7.00004V5.25004C2.33342 3.63921 3.63925 2.33337 5.25008 2.33337V2.33337"
                            stroke="#7D8FB3"
                            strokeLinecap="round"
                          />
                        </svg>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="table-cell">
                <div className="table-cell-content">
                  <p>{x.feedback.lightweight_reactions[0].messaging_actions?.edges?.length?
                  x.feedback.lightweight_reactions[0]?.messaging_actions.edges.map(y=> y.node.reaction):
                  x.feedback.lightweight_reactions[0].last5?.edges?.length?
                  x.feedback.lightweight_reactions[0]?.last5?.edges.map(y=> y.node.reaction):'N/A'}</p>
                </div>
              </div>
              <div className="table-cell">
                <div className="table-cell-content">
                  <p>{x?.option!=undefined?x?.option:'N/A'}</p>
                </div>
              </div>
              <div className="table-cell">
                <div className="table-cell-content">
                  <p>{x?.message_sent!=undefined?x?.message_sent:'N/A'}</p>
                </div>
              </div>
            </div>
                )
            }) 
           }
          
          </div>
        </div>
        {isLoading? <InlineLoader/>:""}
      </div>
    </>
  );
};

export default StoryViewers;
