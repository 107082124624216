import { useState } from 'react'
import Config from '../../Config/env'
import '../../assets/style/style.css'
import { Navigate, NavLink } from "react-router-dom";
import { useEffect } from 'react';
import instExt from "../../assets/images/log-pointer.png";
import pointerExt from "../../assets/images/pointer.png";
const logo =  './images/logo.png'
const authFacebook = './images/auth-facebook.png'
const Login = () => {
    document.title = `${Config.name} - Login`;
    const [isLoggedIn,setIsLoggedIn]= useState(false)
    const [vitamins, setVitamins] = useState({
        "appName": "Let Story Drive",
        "logo": "./images/logo.png",
        "extensionId": "",
        "changePasswordUrl": "",
        "backendUrl": "https://letstorydrive.io",
        "mailTo": "",
        "description": {
            "short_description": "",
            "long_description": ""
        },
        secondaryLogo : "./images/logo-in.png"
    })
    
    const onLogin = () => {
        alert("login")
        localStorage.setItem('_lsd_token','fuck')
        setIsLoggedIn(true)
    }

    const getVitamins = () => {
        if(localStorage.getItem('vitaminsForPortal')!=undefined && Object.keys(JSON.parse(localStorage.getItem('vitaminsForPortal'))).length){
            let vitamins = JSON.parse(localStorage.getItem('vitaminsForPortal'))
            document.title = `${vitamins.appName} - Login`;
            setVitamins(vitamins)
        }
    }
    
    useEffect(()=>{
      getVitamins()
    },[])

    return (
        <> 
        {isLoggedIn?   <Navigate to="/home" replace={true} />: ''}
        
        <div className="main-container auth-container auth-container-login vh-100 d-flex">
            <div className="auth-images-container d-flex f-column">
            <header className="auth-header">
                <NavLink
                        to="/home"
                >
                    <img src={vitamins.logo} alt=""/>

                </NavLink>
            </header>
            <div className="auth-text-container">
                <h1>{vitamins.appName}</h1>
                <p>{vitamins.description.long_description}. This is one and only most powerfull automation
                    tool in the market for converting leads from FB stories.</p>
            </div>
            <div className="auth-assets-container">
                <div className="auth-fb-stories">
                    <img src={authFacebook} alt=""/>
                </div>
                <div className="auth-dots">
                    <img src="./images/auth-ellipse.png" alt=""/>
                </div>
                <div className="auth-waterfall">
                    <img src="./images/icon-auth-waterfall.png" alt=""/>
                </div>
                <div className="auth-dashboard-group">
                    <img className="auth-dashboard" src="./images/auth-image-dash.png" alt=""/>
                    <img className="auth-dashboard-logo" src={vitamins.secondaryLogo} alt="" />
                    <img className="auth-dashboard-icon-1" src="./images/auth-user-1.png" alt=""/>
                    <img className="auth-dashboard-icon-2" src="./images/auth-user-2.png" alt=""/>
                </div>
            </div>
        </div>
        <div className="auth-forms-container d-flex f-column">
            <div className="auth-forms-content login-form-content">
                {/* <h4 className="header-text text-blue">
                    Login
                </h4> */}
                <div className="form-container login-form-container">
                    {/* <form> */}
                        {/* <div className="form-group">
                            <label className="form-label">Email address</label>
                            <div className="form-element">
                                <input className="form-control" type="text" placeholder="gautamkumar@tier5.in"/>
                            </div>
                        </div> */}
                        <div className="form-group"> 
                        {/* error */}
                            {/* <label className="form-label">Password</label>
                            <div className="form-element form-element-padding">
                                <input className="form-control" type="password" placeholder="***********"/>
                                <NavLink
                                    className="form-clickable form-clickable-reset"
                                    to="/reset-password"
                                >
                                Reset Password

                                </NavLink>
                            </div> */}
                            {/* <!-- <p className="error-message">Error Text</p> --> */}
                        </div>
                        {/* <div className="form-group form-remember-password">
                            <label className="custom-checkbox">
                                <input type="checkbox" className="custom-checkbox-input"/>
                                <span className="custom-checkbox-content">
                                    <span className="custom-checkbox-figure"></span>
                                    <span className="custom-checkbox-text">Remember Password</span>
                                </span>
                            </label>
                        </div> */}
                        {/* <div className="form-group form-footer">
                            <button className="btn btn-theme btn-blue w-100" onClick={onLogin}>Login</button>
                            <div className="form-footer-addition">
                                <p>Don’t have am account?   <a href="" className="text-blue">Sign up</a></p>
                            </div>
                        </div> */}
                    {/* </form> */}
                </div>

                <div className='extension-installation text-center'>
                    <span className="pointer-arrow">
                        <img src={pointerExt} alt="" />
                    </span>
                    <figure>
                        <img src={instExt} alt="Pointer Img" />
                    </figure>
                    <p>Please login to the extension to continue using <a href="javascript:void(0)">{vitamins.appName}</a> . If you have already, then please reload .</p>
                </div>
            </div>
            <footer className="auth-footer">
                <p>Powered by <a href={vitamins?.footer?.poweredBy?.url}>{vitamins?.footer?.poweredBy?.label}</a> {'&'} <a href={vitamins?.footer?.partnership?.url}>{vitamins?.footer?.partnership?.label}</a></p>
            </footer>
        </div>
        </div>
       </>
    )
}

export default Login
