let { backendUrl: backendUrl } = require("./env");




// Misc. urls
export const facebook = "//www.facebook.com/tier5development";
// export const facebookJon =  "//www.facebook.com/theceojonvaughn";
export const facebookJon = "//www.jonvaughn.io/fb";
export const messenger = "//www.messenger.com/t/tier5development";
export const tier5Website = "//tier5.us";

// Stories 
export const getAllStories = backendUrl + "facebook/all-stories";
export const getIndividualStory = backendUrl +"facebook/getIndividualStory"
export const getIndividualStoryViewerList = backendUrl +"facebook/getIndividualStoryViewersList"
export const getStoryInsight = backendUrl+"facebook/storyInsight"

//Friends Stories
export const getAllFriendsStories = backendUrl + "facebook/friends-all-stories";
export const getEngagerSettings = backendUrl + "auth/fetchEngagerSettings"
export const updateEngagerSettings = backendUrl + "auth/engagerSettings"

// story engager dash insights
export const getEngagerStorysSynced = backendUrl + "/automation/dashFriendsStoriesSynced"
export const getEngagerStorysReacted = backendUrl + "/automation/dashFriendsStoriesReacted"
export const getEngagerUniqueStoriesEngaged = backendUrl + "/automation/dashUniqueStoriesEngaged"
export const getEngagerUniqueUsersEngaged = backendUrl + "/automation/dashUniqueFriendsEngaged"





//Dashboard Insights
export const getDashStoriesCount = backendUrl + "/automation/dashStories"
export const getDashAutomationCount = backendUrl + "/automation/dashAutomationInsights"
export const getDashMessgesSent = backendUrl + "/automation/dashMessgesDeliverd"
export const getDashEngagement = backendUrl +"/automation/dashEngagement"
export const getActiveAutomationCount = backendUrl  + "/automation/dashActiveAutomations"
export const getTop3Stories = backendUrl + "/facebook/top-three-stories"
export const getTop20Users = backendUrl + "/facebook/top-twenty"
export const usersInteracted = backendUrl + "/facebook/users-interaction"

//Messages
export const getAllMessageGroups = backendUrl + 'message/findAllGroups'
export const getAllMessageSegments = backendUrl + 'message/findAllSegments'
export const createSegment = backendUrl + 'message/createSegment'
export const createMessageGroup = backendUrl + 'message/createGroup'
export const deleteMessageGroup = backendUrl + 'message/deleteGroup'
export const deleteMessageSegment = backendUrl + 'message/deleteSegment'

//Automation
export const createOrUpdateAutomation = backendUrl + 'automation'

//Users
export const userDetails = backendUrl + 'facebook/userDetails'
export const username = backendUrl + 'facebook/username'