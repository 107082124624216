import { useState } from 'react'
// import { NavLink } from "react-router-dom";
import '../../assets/style/style.css'
import { useLocation, NavLink, Navigate } from "react-router-dom";
const logo = './images/logo.png'
const authFacebook = './images/auth-facebook.png'

const Sidebar = () => {
  
    //    const [isLoggedIn,setIsLoggedIn]= useState(false)


    const pathname = useLocation().pathname

    return (
        <>

            {pathname === '/' ? <Navigate to="/home" replace={true} /> : ''}
            <nav className="navigation-menu-left">
                <ul>
                   
                <li >
                      
                    
                        <NavLink to={"/home" || ""}
                            className={({ isActive }) => (isActive == true || pathname.match("setupstory") || pathname.match("user-details")? 'active' : '')}
                        >

                            {/* <img src="../images/ico-dash.png" alt="" />
                            <img src="../images/ico-dash-filled.png" className="filled-ico" alt="" /> */}
                            <img src="../images/dashboard.png" alt="" />
                            <img src="../images/dashboard-active.png" className="filled-ico" alt="" />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink 
                            to={"/engagement" || ""}
                            className={({ isActive }) => (isActive == true ? 'active' : '')}
                        >
                            <img src="../images/engagement.svg" alt="" />
                            <img src="../images/engagement-filled.svg" className="filled-ico" alt="" />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/messagesettings"
                            className={({ isActive }) => (isActive == true ? 'active' : '')}
                        >
                            <img src="../images/ico-chat.png" alt="" />
                            <img src="../images/ico-chat-filled.png" className="filled-ico" alt="" />
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/tags"
                            className={({ isActive }) => (isActive == true ? 'active' : '')}
                        >
                            <img src="../images/ico-labels.png" alt="" />
                            <img src="../images/ico-labels-filled.png" className="filled-ico" alt="" />
                        </NavLink>
                    </li>
                    <li >
                        <NavLink to="/settings"
                            className={({ isActive }) => (isActive == true ? 'active' : '')}
                        >
                            <img src="../images/ico-settings.png" alt="" />
                            <img src="../images/ico-settings-filled.png" className="filled-ico" alt="" />
                        </NavLink>
                    </li> */}
                  
                </ul>
            </nav>
        </>
    )
}

export default Sidebar
