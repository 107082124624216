import { useEffect, useState } from "react"
import moment from 'moment'
import { Link, NavLink } from "react-router-dom";
import {createorupdateautomation} from '../../Services/Automations/Automation'
import Config from '../../Config/env'
const Story = (props) => {
    const [createLabel, setCreateLabel] = useState(false)
    const [addLabel, setAddLabel] = useState(false)
    const timeAgoStoryUpload = (creation_time) => {
                        
                        let timeAgo = moment.unix(creation_time).fromNow();
                        timeAgo = timeAgo.substring(0, timeAgo.lastIndexOf(' '));
    
                        if (timeAgo.includes('hours'))
                           return timeAgo = timeAgo.replace('hours', 'h');
                        if (timeAgo.includes('hour'))
                           return timeAgo = timeAgo.replace('an hour', '1 h');
                        if (timeAgo.includes('minutes'))
                           return timeAgo = timeAgo.replace('minutes', 'm');
                        if (timeAgo.includes('minute'))
                           return timeAgo = timeAgo.replace('a minute', '1 m');
                        if (timeAgo.includes('seconds'))
                           return timeAgo = timeAgo.replace('seconds', 's');

                        return timeAgo
    }
    const timeUntilExpiration = (expiration_time) => {
        // Calculate the duration from the current time to the expiration time
        let timeUntil = moment.duration(moment.unix(expiration_time).diff(moment()));
    
        // Format the duration into a human-readable string
        let timeAgo = '';
        if (timeUntil.hours() > 0) {
            timeAgo += timeUntil.hours() + 'h ';
        }
        if (timeUntil.minutes() > 0) {
            timeAgo += timeUntil.minutes() + 'm ';
        }
        if (timeUntil.seconds() > 0 || timeAgo === '') {
            timeAgo += timeUntil.seconds() + 's';
        }
    
        // Trim any trailing space
        timeAgo = timeAgo.trim();
    
        return timeAgo;
    };
    const [automationToggle,setAutomationToggle] = useState({
        is_active : false,
        statusChange : false
      })
    const toggleAddLabel = (e) => {
        e.preventDefault();
        setCreateLabel(!createLabel)
    }

    const toggleAutomation = async () => {
        setAutomationToggle({...automationToggle, is_active :!!!automationToggle.is_active, statusChange : true})
   
        let userInfo = await JSON.parse(localStorage.getItem('_lsd_token'))
        if(userInfo.loggedInUserInfo.lsd_token && userInfo.facebookInfo.fb_data_lsd.userId){
        let payload = {
          "lsd_token": userInfo.loggedInUserInfo.lsd_token,
          "automation_id": props.info.automation_id._id,
          "data" : {...automationToggle, is_active :!!!automationToggle.is_active, statusChange : true},
          "story_id" : props.info.story_id
      }
      createorupdateautomation(payload).then(res=>{
        console.log("Response",res)
      })
    }
    }

    useEffect(()=> {
        setAutomationToggle({...automationToggle, is_active : props.info.automation_id?.is_active})
    },[])

    return (

        <>
            <div className={props.info.automation_id == null ? "stories" : "stories set"}>
                <header>
                    {
                        props?.mode !== 'engagement' ? 
                        <>
                            <span className="timestamp">{timeAgoStoryUpload(props.info.creation_time)}</span>
                            <span className="views">
                                <figure>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9 3.375C5.25 3.375 2.0475 5.7075 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C12.75 14.625 15.9525 12.2925 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375ZM9 12.75C6.93 12.75 5.25 11.07 5.25 9C5.25 6.93 6.93 5.25 9 5.25C11.07 5.25 12.75 6.93 12.75 9C12.75 11.07 11.07 12.75 9 12.75ZM9 6.75C7.755 6.75 6.75 7.755 6.75 9C6.75 10.245 7.755 11.25 9 11.25C10.245 11.25 11.25 10.245 11.25 9C11.25 7.755 10.245 6.75 9 6.75Z"
                                            fill="white" />
                                    </svg>
                                </figure>
                                {props.info.viewer_count}
                            </span>
                        </> :
                        <>
                            <div className="friend-profile-pic d-flex" title={props.info.documents[0].friend_name}>
                                <figure
                                    className="p-pic p-pic-status"
                                    style={{ "backgroundImage": `url(${props.info.documents[0].profile_pic})` }}
                                ></figure>
                                <article>
                                    <a href={props.info.documents[0].temp_story_link} target="blank">
                                    <h6>{props.info.documents[0].friend_name}</h6>
                                    </a>
                                    <p>will disappear in {timeUntilExpiration(props.info.documents[0].expiration_time)}</p>
                                </article>
                            </div>
                            <aside className="num-stats d-flex">
                                <span>
                                  {props.info.count}
                                </span>
                                <figure>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_b_228_2307)">
                                        <rect width="24" height="24" rx="12" transform="matrix(-1 0 0 1 24 0)" fill="white" fill-opacity="0.2"/>
                                        <path d="M10.5991 8.49332L15.6066 8.49332L15.6066 13.5008" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.59411 15.5065L15.5361 8.56445" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <filter id="filter0_b_228_2307" x="-40" y="-40" width="104" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="20"/>
                                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_228_2307"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_228_2307" result="shape"/>
                                        </filter>
                                    </defs>
                                    </svg>
                                </figure>
                            </aside>
                        </>
                    }
                </header>
                <div className="body-story">
                    <div className="label-story"> {/**label-set */}
                        {/* <button className="btn btn-gradient" onClick={() => setAddLabel(!addLabel)}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.75842 2.49162C9.5315 2.26354 9.16284 2.26237 8.93359 2.48929C8.87292 2.54995 8.83092 2.6217 8.80234 2.69637C8.317 3.70904 7.78267 4.28012 7.10834 4.61729C6.35175 4.99062 5.48317 5.2502 4.08317 5.2502C4.00734 5.2502 3.9315 5.26479 3.86034 5.29454C3.71742 5.35404 3.60425 5.46778 3.54475 5.61012C3.48584 5.75245 3.48584 5.91345 3.54475 6.05578C3.5745 6.12753 3.61709 6.19228 3.67134 6.24595L5.56309 8.1377L2.9165 11.6669L6.44567 9.02029L8.33684 10.9115C8.3905 10.9663 8.45525 11.0083 8.527 11.038C8.59817 11.0678 8.674 11.0835 8.74984 11.0835C8.82567 11.0835 8.9015 11.0678 8.97267 11.038C9.11559 10.9785 9.22934 10.866 9.28825 10.7225C9.318 10.6519 9.33317 10.5755 9.33317 10.5002C9.33317 9.1002 9.59217 8.23162 9.96492 7.4867C10.3015 6.81237 10.8726 6.27804 11.8858 5.7927C11.9611 5.76412 12.0323 5.72212 12.0923 5.66145C12.3193 5.4322 12.3181 5.06354 12.09 4.83662L9.75842 2.49162Z" fill="white"/>
                            </svg>
                            <span>{addLabel ? "Close Add Label" : "Add a label"}</span>
                        </button> */}

                        {addLabel && 
                            <div className="label-container boxed boxed-padding">
                                <div className="header-label-list d-flex f-align-center">
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5168 4.98275C18.063 4.52658 17.3257 4.52425 16.8672 4.97808C16.7458 5.09942 16.6618 5.24292 16.6047 5.39225C15.634 7.41758 14.5653 8.55975 13.2167 9.23408C11.7035 9.98075 9.96634 10.4999 7.16634 10.4999C7.01467 10.4999 6.86301 10.5291 6.72067 10.5886C6.43484 10.7076 6.20851 10.9351 6.08951 11.2197C5.97167 11.5044 5.97167 11.8264 6.08951 12.1111C6.14901 12.2546 6.23417 12.3841 6.34267 12.4914L10.1262 16.2749L4.83301 23.3332L11.8913 18.0401L15.6737 21.8224C15.781 21.9321 15.9105 22.0161 16.054 22.0756C16.1963 22.1351 16.348 22.1666 16.4997 22.1666C16.6513 22.1666 16.803 22.1351 16.9453 22.0756C17.2312 21.9566 17.4587 21.7314 17.5765 21.4444C17.636 21.3032 17.6663 21.1504 17.6663 20.9999C17.6663 18.1999 18.1843 16.4627 18.9298 14.9729C19.603 13.6242 20.7452 12.5556 22.7717 11.5849C22.9222 11.5277 23.0645 11.4437 23.1847 11.3224C23.6385 10.8639 23.6362 10.1266 23.18 9.67275L18.5168 4.98275Z" fill="#4D5E80"/>
                                    </svg>
                                    <h5>Label <span className="label-count">5</span></h5>

                                    {!createLabel && <button className="btn btn-gradient" onClick={e => toggleAddLabel(e)}>+ Add</button>}

                                    {createLabel && <button className="btn btn-gradient" onClick={e => toggleAddLabel(e)}>+ Save</button>}
                                </div>

                                <div className="header-label-body">
                                    <ul>
                                        {createLabel && 
                                            <li className="creator-label">
                                                <input type="text" placeholder="Type here" />
                                                <button className="btn" onClick={e => toggleAddLabel(e)}>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 1L1 10.9999" stroke="#191A25" stroke-width="1.5" strokeLinecap="round"/>
                                                        <path d="M11 11L1 1.00008" stroke="#191A25" stroke-width="1.5" strokeLinecap="round"/>
                                                    </svg>
                                                </button>
                                            </li>
                                        }
                                        <li> {/**active */}
                                            <span>label name</span> 
                                            <button className="btn btn-edit-label">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0866 7.91314L14.25 6.7497L14.2501 6.74966L14.2501 6.74961C14.3297 6.67001 14.3695 6.6302 14.4015 6.59496C15.0949 5.83214 15.0949 4.66726 14.4015 3.90444C14.3695 3.86919 14.3297 3.82937 14.2501 3.74976L14.2501 3.74974L14.25 3.7497C14.1704 3.67004 14.1305 3.63022 14.0953 3.59817C13.3325 2.90478 12.1676 2.90478 11.4048 3.59817C11.3695 3.63022 11.3297 3.67004 11.25 3.7497L10.069 4.93071C10.7823 6.17586 11.8243 7.20981 13.0866 7.91314ZM8.6143 6.38542L3.8564 11.1433C3.43134 11.5684 3.21881 11.7809 3.07907 12.042C2.93934 12.3031 2.88039 12.5978 2.7625 13.1873L2.3971 15.0143C2.33058 15.3469 2.29732 15.5132 2.39193 15.6078C2.48654 15.7024 2.65284 15.6691 2.98545 15.6026H2.98545L4.81243 15.2372L4.81244 15.2372C5.40189 15.1193 5.69661 15.0604 5.95771 14.9206C6.2188 14.7809 6.43133 14.5684 6.85638 14.1433L6.85639 14.1433L6.8564 14.1433L11.6281 9.37163C10.4171 8.60339 9.38969 7.58288 8.6143 6.38542Z" fill="#7D8FB3"/>
                                                </svg>
                                            </button>
                                            <button className="btn btn-delete-label">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.5 11.25L7.5 9" stroke="#7D8FB3" stroke-width="1.5" strokeLinecap="round"/>
                                                    <path d="M10.5 11.25L10.5 9" stroke="#7D8FB3" stroke-width="1.5" strokeLinecap="round"/>
                                                    <path d="M2.25 5.25H15.75V5.25C15.5178 5.25 15.4017 5.25 15.304 5.25963C14.3544 5.35315 13.6032 6.10441 13.5096 7.05397C13.5 7.15175 13.5 7.26783 13.5 7.5V11C13.5 12.8856 13.5 13.8284 12.9142 14.4142C12.3284 15 11.3856 15 9.5 15H8.5C6.61438 15 5.67157 15 5.08579 14.4142C4.5 13.8284 4.5 12.8856 4.5 11V7.5C4.5 7.26783 4.5 7.15175 4.49037 7.05397C4.39685 6.10441 3.64559 5.35315 2.69603 5.25963C2.59825 5.25 2.48217 5.25 2.25 5.25V5.25Z" stroke="#7D8FB3" stroke-width="1.5" strokeLinecap="round"/>
                                                    <path d="M7.55111 2.52794C7.63657 2.44821 7.82489 2.37775 8.08686 2.32749C8.34882 2.27724 8.6698 2.25 9 2.25C9.3302 2.25 9.65118 2.27724 9.91314 2.32749C10.1751 2.37775 10.3634 2.44821 10.4489 2.52794" stroke="#7D8FB3" stroke-width="1.5" strokeLinecap="round"/>
                                                </svg>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                    {/* <!-- background if Image --> */}
                    {props?.mode !== 'engagement'?
                    <figure style={{ "backgroundImage": `url(${props.info.story_attachement_uri})` }}>
                        <NavLink to = {props.info.automation_id != null?'/setupstory/'+`${props.info.story_id}`:''} ></NavLink>
                    </figure>
                    :
                    <figure style={{ "backgroundImage": `url(${props.info.documents[0].story_attachement_uri})` }}>
                        <NavLink to = {props.info.automation_id != null?'/setupstory/'+`${props.info.story_id}`:''} ></NavLink>
                    </figure>}
                    
                </div>
                {props?.mode !== 'engagement' ?
                    <footer className="footer-story">
                        {props.info.automation_id == null ? 
                            <button className="btn btn-setup d-flex">
                                <NavLink to = {props.info.automation_id == null?'/setupstory/'+`${props.info.story_id}`:''} >
                                    {/* {"Setup " + Config.shortName} */}
                                    Setup Automation
                                    {/* <span><img src="./images/right.png" alt="" /></span> */}
                                    <span><img src="./images/right.svg" alt="" /></span>
                                </NavLink>
                            </button> :
                            <label className="custom-check ">
                                <input onChange={toggleAutomation} type="checkbox" checked={automationToggle.is_active} name="" id="" />
                                <span className="check-style"></span>
                                <span className="check-text">
                                </span>
                            </label>
                        }
                    </footer> :
                    <footer className="footer-story">
                        
                        <a 
                            href={"https://www.facebook.com/stories/"+props?.info?.documents[0]?.story_id}
                            target="_blank"
                            className={
                                props.info.documents.filter(item=>item.reaction_sent!=null).length == props.info.documents.length?
                                    "engage-check d-flex f-justify-between f-align-center engage-completed":
                                    "engage-check d-flex f-justify-between f-align-center engage-pending"}
                        > {/* engage-pending / engage-completed */}
                            <p>
                                <strong>
                                    <span>{props.info.documents.filter(item=>item.reaction_sent!=null).length }</span>/{props.info.documents.length}
                                </strong>
                                stories engaged
                            </p>
                            <figure>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_b_228_2307)">
                                        <rect width="24" height="24" rx="12" transform="matrix(-1 0 0 1 24 0)" fill="white" fill-opacity="0.2"/>
                                        <path d="M10.5991 8.49332L15.6066 8.49332L15.6066 13.5008" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.59411 15.5065L15.5361 8.56445" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <filter id="filter0_b_228_2307" x="-40" y="-40" width="104" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="20"/>
                                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_228_2307"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_228_2307" result="shape"/>
                                        </filter>
                                    </defs>
                                </svg>
                            </figure>
                        </a>
                    </footer>
                }
            </div>
        </>


    )
}

export default Story