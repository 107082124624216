import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import UserDetails from './Components/UserDetails/UserDetails';
import Login from './Components/Login/Login';
import ResetPassword from './Components/ResetPassword/ResetPassword/ResetPassword';
import  MessageSegmentGroup from './Components/MessageSegmentGroup/MessageSegmentGroup'
import Engagement from "./Components/Engagement/Engagement"
import NoExtensionMessage from './Components/NoExtensionMessage/NoExtensionMessage.jsx'
import SetupStory from './Components/Story/SetupStory'
import { UnProtectedRoute, ProtectedRoute } from './CustomRouteMiddleware';
import {talkToExtension} from '../src/Services/ChromeStore/ChromeStore'
import { useEffect,useState } from 'react';
import Loader from './Components/Loader/Loader';
import { ToastContainer } from 'react-toastify';
import { alertBrodcater } from "./Components/Toast/AlertBroadcaster"
import "react-toastify/dist/ReactToastify.css";

function App() {

  const [extInstalled, setInstalled] = useState(false)
  const [isLoading,setIsLoading] = useState(true)
  const [vitamins, setVitamins] = useState({})
  const [loginToFacebook,setLoginToFacebook] = useState(false)


  useEffect(  () => {
     talkToExtension("version",response =>{
      console.log("I am getting called all the time Fuck",response)
      if(response.status == false){
        // return alert("Login to facebook to continue...")
      }

      if(response?.response?.facebookInfo?.fb_data_lsd?.status == false){
        setLoginToFacebook(true)
        // return alert("Login to facebook to continue...")
      }
      setIsLoading(false)
      if(response.status){
        let vitaminsForPortal = {
          appName : response?.response?.appName,
          logo : response?.response?.logo,
          extensionId :  response?.response?.extId,
          changePasswordUrl : response?.response?.changePassURL,
          backendUrl : response?.response?.appBaseBackendUrl,
          mailTo : response?.response?.mailTo,
          description : response?.response?.description,
          footer : response?.response?.footer,
          preLoader : response?.response?.preLoader,
          secondaryLogo : response?.response?.secondary_logo
        }
        setVitamins(vitaminsForPortal)
        console.log("Vitamin",vitaminsForPortal)
        localStorage.setItem('vitaminsForPortal',JSON.stringify(vitaminsForPortal))
        setInstalled(true)
        if(response?.response?.loggedInUserInfo && response?.response?.loggedInUserInfo?.is_logged_in){
          localStorage.setItem('_lsd_token',JSON.stringify(response.response))
        }else{
          localStorage.removeItem('_lsd_token')
        }
      }else{
        setInstalled(false)
      }
    })

 

    if(localStorage.getItem('vitaminsForPortal')!=undefined && Object.keys(JSON.parse(localStorage.getItem('vitaminsForPortal'))).length){
      let vitamins = JSON.parse(localStorage.getItem('vitaminsForPortal'))
         setVitamins(vitamins)
  }
  }, []);

  alertBrodcater();
  return (
    <>
    
    {isLoading?<Loader loaderImg={vitamins.preLoader}/>: <>{!extInstalled  || loginToFacebook ? <NoExtensionMessage facebookShit={loginToFacebook}/>  : <Router>
      <ToastContainer />
      <Routes>  
          <Route path="/" element={<ProtectedRoute><Home/></ProtectedRoute>}/>
          <Route path="/home" element={<ProtectedRoute><Home/></ProtectedRoute>}/>
          <Route path="/engagement" element={<ProtectedRoute><Engagement/></ProtectedRoute>}/>
          <Route path="/messagesettings" element={<ProtectedRoute><MessageSegmentGroup/></ProtectedRoute>}/>
          <Route path="/setupstory/:storyId" element={<ProtectedRoute><SetupStory/></ProtectedRoute>}/>
          <Route path="/user-details/:userId" element={<ProtectedRoute><UserDetails/></ProtectedRoute>}/>
          <Route  path="/login" element={<UnProtectedRoute><Login /></UnProtectedRoute>}/>
          <Route path="/reset-password" element={<UnProtectedRoute><ResetPassword/></UnProtectedRoute>}/>
          <Route extInstalled={extInstalled} path="/no-extension-found" element={<UnProtectedRoute><NoExtensionMessage/></UnProtectedRoute>}/>
        </Routes>

        
   </Router> }</>
   
   }
   
    </>
  );
}

export default App;
