import axios from "axios";
import history from "../../history";
import {
  getAllMessageGroups,
  getAllMessageSegments,
  createSegment,
  createMessageGroup,
  deleteMessageGroup,
  deleteMessageSegment
  } from "../../Config/Url";
import errorMessage from "../../Messages/error";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "",
};

export const fetchAllMessageGroups = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getAllMessageGroups,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("API",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};
export const deleteMG = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        deleteMessageGroup,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("API",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const deleteMS = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        deleteMessageSegment,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("API",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const fetchAllMessageSegments = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getAllMessageSegments,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("API",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const createSegments = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        createSegment,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("API",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const createMessageGroups = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        createMessageGroup,
        payload,
        { headers: headers }
      )
      .then((result) => {
        console.log("API",result)
        resolve({status : true, data : result});
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};
