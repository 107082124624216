export const Like = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_72_1131)">
                <path d="M11.9999 4.49998e-10C9.22365 2.40424e-05 6.53329 0.962678 4.38722 2.72394C2.24116 4.4852 0.772177 6.93609 0.230577 9.65901C-0.311023 12.3819 0.108268 15.2084 1.41701 17.6568C2.72575 20.1053 4.84296 22.0242 7.4079 23.0866C9.97284 24.149 12.8268 24.2892 15.4835 23.4833C18.1402 22.6773 20.4353 20.9752 21.9777 18.6668C23.5201 16.3584 24.2144 13.5866 23.9422 10.8237C23.6701 8.06083 22.4484 5.47777 20.4853 3.51467C19.3709 2.40037 18.0481 1.51646 16.5921 0.913412C15.1362 0.310364 13.5758 -1.36463e-05 11.9999 4.49998e-10Z" fill="#577AFF"/>
                <path d="M17.96 11.0484C18.1331 11.1626 18.2706 11.3232 18.3566 11.5118C18.4427 11.7005 18.4739 11.9095 18.4467 12.1151C18.4648 12.3538 18.4091 12.5923 18.2869 12.7982C18.1648 13.004 17.9822 13.1673 17.764 13.2658C17.9108 13.5294 17.9581 13.8369 17.8973 14.1324C17.8411 14.3816 17.7229 14.6125 17.5537 14.8038C17.3844 14.9952 17.1697 15.1408 16.9293 15.2271C17.0265 15.372 17.0801 15.5416 17.0839 15.716C17.0877 15.8903 17.0414 16.0622 16.9507 16.2111C16.684 16.6791 16.452 16.8564 15.4267 16.8564H11.2427C9.81867 16.8564 9.08134 15.9898 9.08134 15.2698V11.5684C9.08134 9.61643 11.1947 7.9591 11.1947 6.60176L11.0427 4.9111C11.0292 4.76333 11.0583 4.6148 11.1267 4.4831C11.3787 4.26239 11.7068 4.14857 12.0413 4.16576C12.3106 4.16021 12.5764 4.22746 12.8107 4.36043C13.1856 4.63997 13.4803 5.01317 13.6655 5.44261C13.8506 5.87204 13.9195 6.34261 13.8653 6.8071C13.8653 7.23776 13.2693 8.52576 13.188 8.9711C14.0769 8.77285 14.984 8.66696 15.8947 8.6551C17.4227 8.64576 18.4147 8.95643 18.4147 9.98843C18.3593 10.3749 18.2024 10.7397 17.96 11.0458V11.0484ZM5.624 10.5124H6.776C6.89162 10.5136 7.00571 10.539 7.11089 10.587C7.21608 10.635 7.31003 10.7045 7.38667 10.7911C7.54996 10.9748 7.64011 11.212 7.64 11.4578V17.4991C7.63999 17.7448 7.54985 17.982 7.38667 18.1658C7.31003 18.2523 7.21608 18.3219 7.11089 18.3699C7.00571 18.4179 6.89162 18.4433 6.776 18.4444H5.624C5.50839 18.4433 5.3943 18.4179 5.28911 18.3699C5.18392 18.3219 5.08998 18.2523 5.01334 18.1658C4.85015 17.982 4.76002 17.7448 4.76 17.4991V11.4644C4.75989 11.2187 4.85004 10.9814 5.01334 10.7978C5.08937 10.71 5.18305 10.6392 5.28827 10.59C5.39349 10.5409 5.50788 10.5144 5.624 10.5124Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_72_1131">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export const Love = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_72_1238)">
<path d="M11.9999 4.49973e-10C9.22365 2.40411e-05 6.53329 0.962625 4.38722 2.72379C2.24116 4.48495 0.772177 6.9357 0.230577 9.65847C-0.311023 12.3812 0.108268 15.2076 1.41701 17.6559C2.72575 20.1042 4.84296 22.023 7.4079 23.0853C9.97284 24.1477 12.8268 24.2878 15.4835 23.482C18.1402 22.6761 20.4353 20.974 21.9777 18.6657C23.5201 16.3575 24.2144 13.5859 23.9422 10.8231C23.6701 8.06038 22.4484 5.47746 20.4852 3.51447C19.3709 2.40023 18.0481 1.51638 16.5921 0.913361C15.1362 0.310347 13.5758 -1.36456e-05 11.9999 4.49973e-10Z" fill="#ED001D"/>
<path d="M15.612 5.68396C14.7643 5.63329 13.9275 5.89639 13.2614 6.4231C12.5952 6.94981 12.1462 7.70327 12 8.5398C11.8544 7.70325 11.4057 6.94963 10.7397 6.42285C10.0737 5.89607 9.23698 5.63303 8.38933 5.68396C5.30133 5.68396 4.40133 9.16243 4.77867 11.017C5.772 15.9327 12 19.3765 12 19.3765C12 19.3765 18.2293 15.9367 19.2227 11.017C19.5987 9.16243 18.6973 5.68396 15.612 5.68396Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_72_1238">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
    )
}
export const Haha = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_72_693)">
<path d="M23.9987 11.9995C23.9986 14.7756 23.036 17.4658 21.2749 19.6117C19.5137 21.7577 17.063 23.2266 14.3402 23.7681C11.6174 24.3097 8.79111 23.8904 6.34281 22.5818C3.89451 21.2731 1.97572 19.156 0.913364 16.5912C-0.148988 14.0264 -0.289165 11.1726 0.516715 8.51605C1.3226 5.85948 3.02467 3.56453 5.33293 2.02223C7.64119 0.479919 10.4128 -0.214317 13.1756 0.0578064C15.9383 0.32993 18.5212 1.55158 20.4842 3.51459C21.5984 4.62885 22.4823 5.95165 23.0853 7.40749C23.6883 8.86332 23.9987 10.4237 23.9987 11.9995Z" fill="#F8C850"/>
<path d="M4.69574 11.4884C4.69574 14.6429 6.16233 20.8692 11.9993 20.8692C17.8363 20.8692 19.3029 14.6442 19.3029 11.4897C19.3016 11.2444 16.9511 9.91113 11.9993 9.91113C7.04761 9.91113 4.69574 11.2444 4.69574 11.4884Z" fill="#50000A"/>
<path d="M6.58497 18.5204C7.23852 19.315 8.072 19.9425 9.01635 20.3508C9.96071 20.7591 10.9888 20.9365 12.0153 20.8683C13.0396 20.9366 14.0654 20.7592 15.0072 20.3508C15.949 19.9424 16.7796 19.3148 17.4297 18.5204C16.7498 17.7619 15.9139 17.1594 14.9794 16.7542C14.0448 16.3489 13.0337 16.1505 12.0153 16.1726C10.9947 16.1513 9.98148 16.35 9.04447 16.7551C8.10745 17.1602 7.26862 17.7623 6.58497 18.5204Z" fill="#DC2842"/>
<path d="M9.38881 6.24521C9.60117 6.36659 9.77193 6.54926 9.87875 6.76931C9.98556 6.98936 10.0234 7.23653 9.98744 7.47847C9.88345 7.87845 9.70346 8.05577 9.37148 8.05977C8.09881 8.12554 6.85318 8.45181 5.71168 9.01839C5.56219 9.09925 5.39619 9.14485 5.22638 9.15171C5.11767 9.14898 5.01269 9.11153 4.92681 9.04483C4.84092 8.97814 4.77864 8.88571 4.74907 8.78107C4.65174 8.53575 4.64241 8.1731 5.14905 7.84779C6.01363 7.36696 6.95816 7.0467 7.93689 6.9025C7.3446 6.43036 6.70156 6.02567 6.01966 5.6959C5.3957 5.38792 5.4637 4.97994 5.54769 4.75596C5.73301 4.25998 6.43697 4.45464 7.12227 4.79729C7.92584 5.20022 8.68542 5.68546 9.38881 6.24521ZM14.6139 6.24521C15.3162 5.68526 16.0749 5.2 16.8777 4.79729C17.5644 4.44931 18.2657 4.26398 18.4523 4.75596C18.535 4.97994 18.6043 5.39192 17.9803 5.68924C17.2985 6.01641 16.6554 6.4189 16.0631 6.88917C17.0411 7.03284 17.9849 7.35314 18.8483 7.83445C19.3576 8.16377 19.3483 8.52375 19.2483 8.76773C19.2189 8.87248 19.1566 8.96503 19.0707 9.03176C18.9848 9.09848 18.8797 9.13586 18.771 9.13838C18.6011 9.13152 18.4351 9.08592 18.2856 9.00505C17.145 8.44248 15.9014 8.11899 14.6312 8.05444C14.2979 8.05444 14.1179 7.87178 14.0152 7.47581C13.9794 7.23388 14.0174 6.98678 14.1242 6.76677C14.231 6.54676 14.4016 6.36406 14.6139 6.24254V6.24521Z" fill="#2A3755"/>
</g>
<defs>
<clipPath id="clip0_72_693">
<rect width="23.9987" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
    )
}
export const Wow = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_72_234)">
<mask id="mask0_72_234" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<path d="M24 0H0V24H24V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_72_234)">
<path d="M24 11.9923C24 14.7686 23.0373 17.4589 21.2761 19.605C19.5148 21.7511 17.0639 23.2201 14.341 23.7617C11.6181 24.3033 8.7916 23.884 6.34316 22.5752C3.89473 21.2665 1.97583 19.1493 0.913415 16.5843C-0.148996 14.0194 -0.289181 11.1654 0.516744 8.50871C1.32267 5.852 3.02484 3.55692 5.33323 2.01453C7.64162 0.472133 10.4134 -0.222142 13.1763 0.0499971C15.9392 0.322136 18.5222 1.54385 20.4853 3.50698C21.5996 4.62129 22.4835 5.94417 23.0866 7.40009C23.6896 8.856 24 10.4164 24 11.9923Z" fill="#FDD75A"/>
<path d="M8.464 16.3242C8.22667 19.0922 9.55333 20.9909 12 20.9909C14.4467 20.9909 15.7707 19.0922 15.536 16.3242C15.3013 13.5562 13.8627 11.9922 12 11.9922C10.1373 11.9922 8.704 13.5549 8.464 16.3242Z" fill="#50000A"/>
<path d="M5.25067 8.24152C5.20567 7.69564 5.37697 7.15393 5.72764 6.73318C6.07832 6.31242 6.58028 6.0463 7.12534 5.99219C7.67063 6.04597 8.17293 6.31195 8.52389 6.73275C8.87484 7.15354 9.04632 7.69543 9.00134 8.24152C9.04704 8.78791 8.87586 9.33033 8.5248 9.7515C8.17374 10.1727 7.67102 10.4387 7.12534 10.4922C6.5799 10.4384 6.07751 10.1722 5.72673 9.75107C5.37595 9.32993 5.20495 8.7877 5.25067 8.24152ZM15.0013 8.24152C14.9567 7.69572 15.1281 7.1542 15.4787 6.73352C15.8293 6.31284 16.3311 6.04663 16.876 5.99219C17.4211 6.0463 17.923 6.31242 18.2737 6.73318C18.6244 7.15393 18.7957 7.69564 18.7507 8.24152C18.7964 8.7877 18.6254 9.32993 18.2746 9.75107C17.9238 10.1722 17.4214 10.4384 16.876 10.4922C16.3307 10.4381 15.8285 10.1718 15.4778 9.75072C15.1271 9.32967 14.956 8.78762 15.0013 8.24152Z" fill="white"/>
<path d="M5.25067 8.24152C5.20567 7.69564 5.37697 7.15393 5.72764 6.73318C6.07832 6.31242 6.58028 6.0463 7.12534 5.99219C7.67063 6.04597 8.17293 6.31195 8.52389 6.73275C8.87484 7.15354 9.04632 7.69543 9.00134 8.24152C9.04704 8.78791 8.87586 9.33033 8.5248 9.7515C8.17374 10.1727 7.67102 10.4387 7.12534 10.4922C6.5799 10.4384 6.07751 10.1722 5.72673 9.75107C5.37595 9.32993 5.20495 8.7877 5.25067 8.24152ZM15.0013 8.24152C14.9567 7.69572 15.1281 7.1542 15.4787 6.73352C15.8293 6.31284 16.3311 6.04663 16.876 5.99219C17.4211 6.0463 17.923 6.31242 18.2737 6.73318C18.6244 7.15393 18.7957 7.69564 18.7507 8.24152C18.7964 8.7877 18.6254 9.32993 18.2746 9.75107C17.9238 10.1722 17.4214 10.4384 16.876 10.4922C16.3307 10.4381 15.8285 10.1718 15.4778 9.75072C15.1271 9.32967 14.956 8.78762 15.0013 8.24152Z" fill="black"/>
<path d="M6.72267 6.84397C6.79248 6.86604 6.85712 6.90197 6.91272 6.94961C6.96831 6.99726 7.01372 7.05563 7.04621 7.12124C7.0787 7.18685 7.09762 7.25835 7.10182 7.33145C7.10603 7.40454 7.09543 7.47773 7.07067 7.54664C7.04234 7.69671 6.95723 7.83008 6.83305 7.91898C6.70888 8.00789 6.5552 8.0455 6.404 8.02397C6.33458 8.00108 6.27047 7.96446 6.21548 7.9163C6.16049 7.86814 6.11575 7.80942 6.08391 7.74362C6.05207 7.67782 6.03378 7.60629 6.03013 7.53328C6.02648 7.46028 6.03755 7.38728 6.06267 7.31864C6.09159 7.17037 6.17596 7.03871 6.2986 6.95051C6.42124 6.86231 6.57291 6.8242 6.72267 6.84397ZM16.7053 6.9373C16.7827 6.96194 16.8544 7.0019 16.9161 7.05481C16.9777 7.10772 17.0281 7.1725 17.0641 7.24528C17.1002 7.31806 17.1213 7.39736 17.1261 7.47846C17.1309 7.55955 17.1193 7.64078 17.092 7.7173C17.0596 7.8823 16.966 8.02894 16.83 8.12785C16.694 8.22675 16.5256 8.27061 16.3587 8.25064C16.2811 8.22602 16.2093 8.18601 16.1475 8.13301C16.0858 8.08 16.0354 8.01509 15.9993 7.94216C15.9632 7.86922 15.9422 7.78975 15.9375 7.70851C15.9328 7.62726 15.9445 7.54591 15.972 7.4693C16.003 7.30365 16.0963 7.15616 16.2327 7.0572C16.3691 6.95824 16.5382 6.91533 16.7053 6.9373Z" fill="#4E506A"/>
<path d="M16.6027 2.53659C16.68 2.52593 16.7587 2.52593 16.8373 2.52593C17.6044 2.5396 18.3384 2.84026 18.8947 3.36859C18.9982 3.46705 19.0595 3.60181 19.0657 3.74454C19.0719 3.88726 19.0226 4.02684 18.928 4.13393C18.8823 4.18642 18.8265 4.22913 18.7639 4.25949C18.7013 4.28985 18.6332 4.30724 18.5637 4.3106C18.4942 4.31396 18.4247 4.30324 18.3595 4.27906C18.2942 4.25489 18.2345 4.21776 18.184 4.16993C17.9872 3.98062 17.7535 3.83381 17.4975 3.73862C17.2415 3.64343 16.9687 3.6019 16.696 3.61659C16.5212 3.62957 16.3513 3.68071 16.1983 3.7664C16.0454 3.85209 15.913 3.97026 15.8107 4.11259C15.7696 4.16831 15.7177 4.21524 15.6582 4.2506C15.5986 4.28596 15.5326 4.30904 15.464 4.31849C15.3954 4.32793 15.3257 4.32355 15.2588 4.30559C15.1919 4.28763 15.1293 4.25646 15.0747 4.21393C14.9643 4.12502 14.8924 3.99707 14.8738 3.85659C14.8551 3.7161 14.8913 3.57384 14.9747 3.45926C15.1632 3.19693 15.4064 2.9786 15.6874 2.81931C15.9685 2.66003 16.2807 2.56357 16.6027 2.53659ZM5.104 3.36993C5.66037 2.8408 6.39498 2.53962 7.16267 2.52593C7.52625 2.51797 7.88629 2.59881 8.21157 2.76145C8.53685 2.92409 8.81755 3.16362 9.02933 3.45926C9.11416 3.57418 9.15126 3.71746 9.13287 3.85911C9.11448 4.00076 9.04203 4.12981 8.93067 4.21926C8.87588 4.26204 8.81308 4.29341 8.74597 4.3115C8.67885 4.3296 8.6088 4.33405 8.53993 4.3246C8.47107 4.31516 8.4048 4.292 8.34504 4.2565C8.28527 4.221 8.23324 4.17388 8.192 4.11793C8.08988 3.97428 7.95738 3.85489 7.8039 3.76824C7.65043 3.68159 7.47975 3.62982 7.304 3.61659C7.03134 3.60237 6.75866 3.64413 6.50275 3.73929C6.24684 3.83446 6.01312 3.98101 5.816 4.16993C5.76538 4.21861 5.70535 4.25644 5.63959 4.28111C5.57383 4.30578 5.50373 4.31677 5.43358 4.3134C5.36342 4.31003 5.2947 4.29237 5.23161 4.26151C5.16852 4.23065 5.11239 4.18724 5.06667 4.13393C4.97301 4.02641 4.92459 3.88683 4.93155 3.74441C4.93851 3.602 5.00031 3.4678 5.104 3.36993Z" fill="black"/>
<path d="M16.6027 2.53659C16.68 2.52593 16.7587 2.52593 16.8373 2.52593C17.6044 2.5396 18.3384 2.84026 18.8947 3.36859C18.9982 3.46705 19.0595 3.60181 19.0657 3.74454C19.0719 3.88726 19.0226 4.02684 18.928 4.13393C18.8823 4.18642 18.8265 4.22913 18.7639 4.25949C18.7013 4.28985 18.6332 4.30724 18.5637 4.3106C18.4942 4.31396 18.4247 4.30324 18.3595 4.27906C18.2942 4.25489 18.2345 4.21776 18.184 4.16993C17.9872 3.98062 17.7535 3.83381 17.4975 3.73862C17.2415 3.64343 16.9687 3.6019 16.696 3.61659C16.5212 3.62957 16.3513 3.68071 16.1983 3.7664C16.0454 3.85209 15.913 3.97026 15.8107 4.11259C15.7696 4.16831 15.7177 4.21524 15.6582 4.2506C15.5986 4.28596 15.5326 4.30904 15.464 4.31849C15.3954 4.32793 15.3257 4.32355 15.2588 4.30559C15.1919 4.28763 15.1293 4.25646 15.0747 4.21393C14.9643 4.12502 14.8924 3.99707 14.8738 3.85659C14.8551 3.7161 14.8913 3.57384 14.9747 3.45926C15.1632 3.19693 15.4064 2.9786 15.6874 2.81931C15.9685 2.66003 16.2807 2.56357 16.6027 2.53659ZM5.104 3.36993C5.66037 2.8408 6.39498 2.53962 7.16267 2.52593C7.52625 2.51797 7.88629 2.59881 8.21157 2.76145C8.53685 2.92409 8.81755 3.16362 9.02933 3.45926C9.11416 3.57418 9.15126 3.71746 9.13287 3.85911C9.11448 4.00076 9.04203 4.12981 8.93067 4.21926C8.87588 4.26204 8.81308 4.29341 8.74597 4.3115C8.67885 4.3296 8.6088 4.33405 8.53993 4.3246C8.47107 4.31516 8.4048 4.292 8.34504 4.2565C8.28527 4.221 8.23324 4.17388 8.192 4.11793C8.08988 3.97428 7.95738 3.85489 7.8039 3.76824C7.65043 3.68159 7.47975 3.62982 7.304 3.61659C7.03134 3.60237 6.75866 3.64413 6.50275 3.73929C6.24684 3.83446 6.01312 3.98101 5.816 4.16993C5.76538 4.21861 5.70535 4.25644 5.63959 4.28111C5.57383 4.30578 5.50373 4.31677 5.43358 4.3134C5.36342 4.31003 5.2947 4.29237 5.23161 4.26151C5.16852 4.23065 5.11239 4.18724 5.06667 4.13393C4.97301 4.02641 4.92459 3.88683 4.93155 3.74441C4.93851 3.602 5.00031 3.4678 5.104 3.36993Z" fill="#E38200"/>
</g>
</g>
<defs>
<clipPath id="clip0_72_234">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
    )
}
export const Sad = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_72_1216)">
<path d="M24 12.0001C24 14.7764 23.0373 17.4668 21.2761 19.6128C19.5148 21.7589 17.0639 23.2279 14.341 23.7695C11.6181 24.3111 8.7916 23.8918 6.34316 22.583C3.89473 21.2743 1.97583 19.1571 0.913415 16.5921C-0.148996 14.0272 -0.289181 11.1732 0.516744 8.51652C1.32267 5.85981 3.02484 3.56473 5.33323 2.02234C7.64162 0.479946 10.4134 -0.214329 13.1763 0.0578096C15.9392 0.329949 18.5222 1.55166 20.4853 3.51479C21.5996 4.6291 22.4835 5.95198 23.0866 7.4079C23.6896 8.86382 24 10.4243 24 12.0001Z" fill="#FDD75A"/>
<path d="M7.82533 19.1596C7.82357 19.2105 7.8327 19.2611 7.85211 19.3082C7.87152 19.3553 7.90076 19.3976 7.93788 19.4325C7.975 19.4673 8.01913 19.4938 8.06733 19.5102C8.11552 19.5267 8.16667 19.5326 8.21733 19.5276C9.39546 18.938 10.6851 18.6044 12.0013 18.5489C13.3177 18.6039 14.6074 18.9375 15.7853 19.5276C15.836 19.5326 15.8871 19.5267 15.9353 19.5102C15.9835 19.4938 16.0277 19.4673 16.0648 19.4325C16.1019 19.3976 16.1311 19.3553 16.1506 19.3082C16.17 19.2611 16.1791 19.2105 16.1773 19.1596C15.8877 18.2999 15.3197 17.5614 14.563 17.061C13.8064 16.5606 12.9044 16.3269 12 16.3969C11.096 16.3275 10.1947 16.5615 9.43858 17.0619C8.68249 17.5622 8.11481 18.3004 7.82533 19.1596Z" fill="#50000A"/>
<path d="M5.63356 13.1999C5.63356 11.9319 6.36956 10.9053 7.27356 10.9053C8.17756 10.9053 8.91356 11.9319 8.91356 13.1999C8.91823 13.6905 8.79899 14.1744 8.56689 14.6066C8.52143 14.6914 8.45943 14.7663 8.38454 14.8268C8.30965 14.8872 8.2234 14.9321 8.13089 14.9586C7.85101 15.0375 7.56168 15.0779 7.27089 15.0786C6.98012 15.0777 6.69081 15.0373 6.41089 14.9586C6.3188 14.9319 6.23301 14.887 6.15858 14.8265C6.08415 14.766 6.02259 14.6913 5.97756 14.6066C5.74578 14.1744 5.62743 13.6904 5.63356 13.1999ZM15.0842 13.1999C15.0842 11.9319 15.8202 10.9053 16.7242 10.9053C17.6282 10.9053 18.3642 11.9319 18.3642 13.1999C18.3697 13.6903 18.2514 14.1741 18.0202 14.6066C17.9735 14.6922 17.9106 14.7678 17.8349 14.8293C17.7615 14.8907 17.6762 14.9361 17.5842 14.9626C17.0218 15.1208 16.4266 15.1208 15.8642 14.9626C15.7723 14.9361 15.6869 14.8907 15.6136 14.8293C15.5379 14.7678 15.4749 14.6922 15.4282 14.6066C15.1967 14.1747 15.0783 13.6913 15.0842 13.2013V13.1999Z" fill="white"/>
<path d="M5.63356 13.1999C5.63356 11.9319 6.36956 10.9053 7.27356 10.9053C8.17756 10.9053 8.91356 11.9319 8.91356 13.1999C8.91823 13.6905 8.79899 14.1744 8.56689 14.6066C8.52143 14.6914 8.45943 14.7663 8.38454 14.8268C8.30965 14.8872 8.2234 14.9321 8.13089 14.9586C7.85101 15.0375 7.56168 15.0779 7.27089 15.0786C6.98012 15.0777 6.69081 15.0373 6.41089 14.9586C6.3188 14.9319 6.23301 14.887 6.15858 14.8265C6.08415 14.766 6.02259 14.6913 5.97756 14.6066C5.74578 14.1744 5.62743 13.6904 5.63356 13.1999ZM15.0842 13.1999C15.0842 11.9319 15.8202 10.9053 16.7242 10.9053C17.6282 10.9053 18.3642 11.9319 18.3642 13.1999C18.3697 13.6903 18.2514 14.1741 18.0202 14.6066C17.9735 14.6922 17.9106 14.7678 17.8349 14.8293C17.7615 14.8907 17.6762 14.9361 17.5842 14.9626C17.0218 15.1208 16.4266 15.1208 15.8642 14.9626C15.7723 14.9361 15.6869 14.8907 15.6136 14.8293C15.5379 14.7678 15.4749 14.6922 15.4282 14.6066C15.1967 14.1747 15.0783 13.6913 15.0842 13.2013V13.1999Z" fill="black"/>
<path d="M6.74533 11.9562C6.84404 12.0771 6.90015 12.2271 6.90497 12.383C6.90979 12.539 6.86305 12.6922 6.772 12.8189C6.7423 12.8815 6.69857 12.9365 6.64421 12.9796C6.58984 13.0227 6.5263 13.0526 6.4585 13.0672C6.39069 13.0818 6.32044 13.0805 6.25319 13.0636C6.18594 13.0467 6.12349 13.0145 6.07067 12.9695C5.97311 12.8489 5.91781 12.6996 5.91323 12.5445C5.90866 12.3894 5.95506 12.2371 6.04533 12.1109C6.0749 12.0483 6.11839 11.9933 6.17249 11.9502C6.22658 11.907 6.28984 11.8768 6.35741 11.8619C6.42498 11.847 6.49507 11.8477 6.56231 11.864C6.62955 11.8804 6.69216 11.9119 6.74533 11.9562ZM16.904 11.9562C17.0027 12.077 17.0589 12.2269 17.064 12.3828C17.069 12.5387 17.0227 12.6919 16.932 12.8189C16.902 12.8816 16.858 12.9366 16.8034 12.9797C16.7488 13.0227 16.685 13.0527 16.617 13.0672C16.549 13.0818 16.4786 13.0805 16.4112 13.0636C16.3437 13.0467 16.2811 13.0145 16.228 12.9695C16.1304 12.8489 16.0751 12.6996 16.0706 12.5445C16.066 12.3894 16.1124 12.2371 16.2027 12.1109C16.2318 12.0479 16.2751 11.9926 16.3292 11.9491C16.3833 11.9056 16.4466 11.8752 16.5144 11.8602C16.5821 11.8453 16.6524 11.8461 16.7198 11.8628C16.7871 11.8794 16.8497 11.9114 16.9027 11.9562H16.904Z" fill="#4E506A"/>
<path d="M6.32667 7.6892C6.69074 7.48901 7.09464 7.37198 7.50934 7.34653C7.67706 7.33576 7.84416 7.37564 7.98894 7.46099C8.13373 7.54635 8.24954 7.67323 8.32134 7.8252C8.58 8.33986 8.46267 8.4532 8.02934 8.54386C6.54057 8.9254 5.17096 9.67346 4.04534 10.7199C3.60534 11.1585 3.176 10.6732 3.40134 10.2905C4.11657 9.16911 5.12936 8.2685 6.32667 7.6892ZM15.6787 7.82253C15.7509 7.67106 15.8668 7.54473 16.0116 7.45988C16.1564 7.37502 16.3232 7.33554 16.4907 7.34653C16.9054 7.37158 17.3094 7.48863 17.6733 7.6892C18.8707 8.26788 19.8832 9.16865 20.5973 10.2905C20.8227 10.6719 20.4 11.1572 19.9547 10.7199C18.8282 9.67446 17.4582 8.92736 15.9693 8.54653C15.536 8.4532 15.4213 8.3412 15.6787 7.8252V7.82253Z" fill="#E38200"/>
<path d="M20.0867 23.9999C19.4286 23.9624 18.8123 23.6658 18.3723 23.1751C17.9323 22.6844 17.7045 22.0394 17.7387 21.3812C17.793 20.196 18.1387 19.0423 18.7453 18.0226C19.6813 16.0479 19.872 15.7812 20.0867 15.7812C20.3013 15.7812 20.4867 16.0479 21.428 18.0226C22.0346 19.0423 22.3804 20.196 22.4347 21.3812C22.4689 22.0394 22.2411 22.6844 21.8011 23.1751C21.3611 23.6658 20.7447 23.9624 20.0867 23.9999Z" fill="#1452FF"/>
<path d="M20.0907 20.2533C19.8301 20.2395 19.5855 20.1231 19.4106 19.9294C19.2357 19.7357 19.1446 19.4807 19.1573 19.22C19.1789 18.7494 19.3163 18.2914 19.5573 17.8867C19.9267 17.1067 20 17 20.0907 17C20.1813 17 20.252 17.1067 20.624 17.8867C20.8649 18.2915 21.0022 18.7494 21.024 19.22C21.0371 19.4809 20.9462 19.7363 20.7712 19.9303C20.5962 20.1242 20.3515 20.2395 20.0907 20.2533Z" fill="#7D9FFF"/>
</g>
<defs>
<clipPath id="clip0_72_1216">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
    )
}
export const Care = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_72_1262)">
<path fillRule="evenodd" clipRule="evenodd" d="M23.843 12C23.8662 15.1471 22.6431 18.1754 20.441 20.4238C18.2389 22.6722 15.2365 23.9578 12.0897 24C8.94279 23.9578 5.94045 22.6722 3.73831 20.4238C1.53617 18.1754 0.313145 15.1471 0.336322 12C0.313145 8.85294 1.53617 5.82455 3.73831 3.57619C5.94045 1.32783 8.94279 0.0421819 12.0897 0C15.2365 0.0421819 18.2389 1.32783 20.441 3.57619C22.6431 5.82455 23.8662 8.85294 23.843 12Z" fill="url(#paint0_linear_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.843 12C23.8662 15.1471 22.6431 18.1754 20.441 20.4238C18.2389 22.6722 15.2365 23.9578 12.0897 24C8.94279 23.9578 5.94045 22.6722 3.73831 20.4238C1.53617 18.1754 0.313145 15.1471 0.336322 12C0.313145 8.85294 1.53617 5.82455 3.73831 3.57619C5.94045 1.32783 8.94279 0.0421819 12.0897 0C15.2365 0.0421819 18.2389 1.32783 20.441 3.57619C22.6431 5.82455 23.8662 8.85294 23.843 12Z" fill="url(#paint1_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.843 12C23.8662 15.1471 22.6431 18.1754 20.441 20.4238C18.2389 22.6722 15.2365 23.9578 12.0897 24C8.94279 23.9578 5.94045 22.6722 3.73831 20.4238C1.53617 18.1754 0.313145 15.1471 0.336322 12C0.313145 8.85294 1.53617 5.82455 3.73831 3.57619C5.94045 1.32783 8.94279 0.0421819 12.0897 0C15.2365 0.0421819 18.2389 1.32783 20.441 3.57619C22.6431 5.82455 23.8662 8.85294 23.843 12Z" fill="url(#paint2_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.843 12C23.8662 15.1471 22.6431 18.1754 20.441 20.4238C18.2389 22.6722 15.2365 23.9578 12.0897 24C8.94279 23.9578 5.94045 22.6722 3.73831 20.4238C1.53617 18.1754 0.313145 15.1471 0.336322 12C0.313145 8.85294 1.53617 5.82455 3.73831 3.57619C5.94045 1.32783 8.94279 0.0421819 12.0897 0C15.2365 0.0421819 18.2389 1.32783 20.441 3.57619C22.6431 5.82455 23.8662 8.85294 23.843 12Z" fill="url(#paint3_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.843 12C23.8662 15.1471 22.6431 18.1754 20.441 20.4238C18.2389 22.6722 15.2365 23.9578 12.0897 24C8.94279 23.9578 5.94045 22.6722 3.73831 20.4238C1.53617 18.1754 0.313145 15.1471 0.336322 12C0.313145 8.85294 1.53617 5.82455 3.73831 3.57619C5.94045 1.32783 8.94279 0.0421819 12.0897 0C15.2365 0.0421819 18.2389 1.32783 20.441 3.57619C22.6431 5.82455 23.8662 8.85294 23.843 12Z" fill="url(#paint4_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.843 12C23.8662 15.1471 22.6431 18.1754 20.441 20.4238C18.2389 22.6722 15.2365 23.9578 12.0897 24C8.94279 23.9578 5.94045 22.6722 3.73831 20.4238C1.53617 18.1754 0.313145 15.1471 0.336322 12C0.313145 8.85294 1.53617 5.82455 3.73831 3.57619C5.94045 1.32783 8.94279 0.0421819 12.0897 0C15.2365 0.0421819 18.2389 1.32783 20.441 3.57619C22.6431 5.82455 23.8662 8.85294 23.843 12Z" fill="url(#paint5_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M14.74 8.84126C14.588 8.37192 9.57867 8.37192 9.42133 8.84126C9.264 9.31059 10.3547 9.93726 12.088 9.93726C13.8213 9.93726 14.9 9.31059 14.74 8.84126Z" fill="url(#paint6_linear_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.284 6.21055C9.284 7.30655 8.65734 7.61989 7.71867 7.77589C6.78 7.93189 5.996 7.46255 5.996 6.21055C5.996 5.27055 6.46534 4.01855 7.71734 4.01855C8.81334 4.01855 9.284 5.27055 9.284 6.21055Z" fill="url(#paint7_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M7.56133 4.64527C7.60508 4.70603 7.63586 4.77514 7.65173 4.8483C7.66761 4.92147 7.66825 4.99712 7.65362 5.07054C7.63898 5.14397 7.60938 5.21359 7.56667 5.27508C7.52395 5.33657 7.46903 5.3886 7.40533 5.42794C7.248 5.58527 6.93467 5.74127 6.77867 5.42794C6.70205 5.3118 6.66122 5.17573 6.66122 5.0366C6.66122 4.89748 6.70205 4.76141 6.77867 4.64527C6.89422 4.56714 7.03051 4.52539 7.17 4.52539C7.30949 4.52539 7.44578 4.56714 7.56133 4.64527Z" fill="#4E506A"/>
<path fillRule="evenodd" clipRule="evenodd" d="M14.876 6.21055C14.876 7.30655 15.5027 7.61855 16.5973 7.77589C17.5307 7.93189 18.3187 7.46255 18.3187 6.21055C18.32 5.27055 17.8507 4.01855 16.5973 4.01855C15.344 4.01855 14.876 5.27055 14.876 6.21055Z" fill="url(#paint8_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.512 4.65102C16.668 4.80835 16.512 5.12035 16.3547 5.43368C16.2679 5.50954 16.1566 5.55136 16.0413 5.55136C15.9261 5.55136 15.8148 5.50954 15.728 5.43368C15.572 5.27768 15.7293 4.96435 15.8853 4.65102C16.1973 4.33768 16.3547 4.33768 16.512 4.65102Z" fill="#4E506A"/>
<path fillRule="evenodd" clipRule="evenodd" d="M5.23467 3.28671C4.92134 3.60004 5.23467 4.06938 5.704 3.91338C7.0784 3.29636 8.58366 3.02754 10.0867 3.13071C10.556 3.13071 10.7133 3.13071 10.556 2.50404C10.556 2.03471 9.92934 1.72138 8.67734 1.87738C7.40829 1.9656 6.20128 2.45971 5.23467 3.28671Z" fill="url(#paint9_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.4864 1.87797C14.2344 1.72063 13.6077 2.03397 13.6077 2.5033C13.4517 3.12997 13.6077 3.12997 14.0824 3.12997C15.587 2.9943 17.1004 3.26458 18.4651 3.91263C19.0917 4.22597 19.2477 3.5993 18.9344 3.28597C17.9662 2.4584 16.7571 1.96469 15.4864 1.87797Z" fill="url(#paint10_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5213 12.2652C12.2333 11.3318 11.1387 13.5172 11.1387 13.5172C11.1387 13.5172 11.4507 11.0065 8.164 9.91049C5.032 8.97182 3.15333 11.9465 2.99733 13.6678C2.684 17.5812 6.128 21.9652 7.37733 23.5305C7.53333 23.9998 8.004 23.9998 8.47333 23.9998C10.352 23.5305 15.6733 21.8078 17.7093 18.3638C18.4947 16.6425 18.652 13.1985 15.5213 12.2652Z" fill="url(#paint11_linear_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5213 12.2652C12.2333 11.3318 11.1387 13.5172 11.1387 13.5172C11.1387 13.5172 11.4507 11.0065 8.164 9.91049C5.032 8.97182 3.15333 11.9465 2.99733 13.6678C2.684 17.5812 6.128 21.9652 7.37733 23.5305C7.53333 23.9998 8.004 23.9998 8.47333 23.9998C10.352 23.5305 15.6733 21.8078 17.7093 18.3638C18.4947 16.6425 18.652 13.1985 15.5213 12.2652Z" fill="url(#paint12_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5213 12.2652C12.2333 11.3318 11.1387 13.5172 11.1387 13.5172C11.1387 13.5172 11.4507 11.0065 8.164 9.91049C5.032 8.97182 3.15333 11.9465 2.99733 13.6678C2.684 17.5812 6.128 21.9652 7.37733 23.5305C7.53333 23.9998 8.004 23.9998 8.47333 23.9998C10.352 23.5305 15.6733 21.8078 17.7093 18.3638C18.4947 16.6425 18.652 13.1985 15.5213 12.2652Z" fill="url(#paint13_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5213 12.2652C12.2333 11.3318 11.1387 13.5172 11.1387 13.5172C11.1387 13.5172 11.4507 11.0065 8.164 9.91049C5.032 8.97182 3.15333 11.9465 2.99733 13.6678C2.684 17.5812 6.128 21.9652 7.37733 23.5305C7.53333 23.9998 8.004 23.9998 8.47333 23.9998C10.352 23.5305 15.6733 21.8078 17.7093 18.3638C18.4947 16.6425 18.652 13.1985 15.5213 12.2652Z" fill="url(#paint14_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5213 12.2652C12.2333 11.3318 11.1387 13.5172 11.1387 13.5172C11.1387 13.5172 11.4507 11.0065 8.164 9.91049C5.032 8.97182 3.15333 11.9465 2.99733 13.6678C2.684 17.5812 6.128 21.9652 7.37733 23.5305C7.53333 23.9998 8.004 23.9998 8.47333 23.9998C10.352 23.5305 15.6733 21.8078 17.7093 18.3638C18.4947 16.6425 18.652 13.1985 15.5213 12.2652Z" fill="url(#paint15_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5213 12.2652C12.2333 11.3318 11.1387 13.5172 11.1387 13.5172C11.1387 13.5172 11.4507 11.0065 8.164 9.91049C5.032 8.97182 3.15333 11.9465 2.99733 13.6678C2.684 17.5812 6.128 21.9652 7.37733 23.5305C7.53333 23.9998 8.004 23.9998 8.47333 23.9998C10.352 23.5305 15.6733 21.8078 17.7093 18.3638C18.4947 16.6425 18.652 13.1985 15.5213 12.2652Z" fill="url(#paint16_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5213 12.2652C12.2333 11.3318 11.1387 13.5172 11.1387 13.5172C11.1387 13.5172 11.4507 11.0065 8.164 9.91049C5.032 8.97182 3.15333 11.9465 2.99733 13.6678C2.684 17.5812 6.128 21.9652 7.37733 23.5305C7.53333 23.9998 8.004 23.9998 8.47333 23.9998C10.352 23.5305 15.6733 21.8078 17.7093 18.3638C18.4947 16.6425 18.652 13.1985 15.5213 12.2652Z" fill="url(#paint17_radial_72_1262)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.372 11.333C1.588 10.5503 0.18 10.081 0.0226664 11.9597C-0.0352563 12.9545 0.131728 13.9497 0.511215 14.8712C0.890702 15.7926 1.47294 16.6168 2.21467 17.2823C6.59867 20.7263 10.512 18.8477 10.6667 16.3423C10.8253 14.4637 8.476 14.621 7.85067 14.621V14.4637C8.00667 14.3077 8.32 14.1503 8.47733 13.9943C9.104 13.525 8.79067 12.7423 8.008 12.8983C6.94259 13.2262 5.81167 13.2798 4.72 13.0543C3.46667 12.5863 3.31067 12.1143 2.372 11.333Z" fill="url(#paint18_radial_72_1262)"/>
<path d="M21.1827 11.1636C21.652 10.2303 22.4347 10.5369 22.904 10.6943C23.2471 10.775 23.5484 10.9792 23.7505 11.2679C23.9527 11.5566 24.0415 11.9096 24 12.2596C24.0919 13.4824 23.9312 14.711 23.5277 15.869C23.1242 17.0269 22.4866 18.0894 21.6547 18.9903C18.0547 23.0609 11.9467 22.4343 11.32 19.9303C10.8547 18.0516 13.0413 17.7383 13.8253 17.7383V17.5823C13.512 17.4263 13.356 17.2689 13.0427 17.1129C12.416 16.6436 12.5733 15.7036 13.356 15.8609C14.3783 16.1198 15.4334 16.2254 16.4867 16.1743C19.4613 15.8609 19.9307 13.5063 21.1827 11.1636Z" fill="url(#paint19_radial_72_1262)"/>
</g>
<defs>
<linearGradient id="paint0_linear_72_1262" x1="-3.07256" y1="29.784" x2="-3.07256" y2="31.104" gradientUnits="userSpaceOnUse">
<stop stopColor="#F28A2D"/>
<stop offset="1" stopColor="#FDE86F"/>
</linearGradient>
<radialGradient id="paint1_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.0897 12) scale(11.7537 12)">
<stop stopColor="#F28A2D" stopOpacity="0"/>
<stop offset="1" stopColor="#F08423" stopOpacity="0.341"/>
</radialGradient>
<radialGradient id="paint2_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.87479 12.48) scale(2.37424 2.424)">
<stop stopColor="#F28A2D" stopOpacity="0.502"/>
<stop offset="1" stopColor="#F28A2D" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint3_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.7784 17.664) scale(6.65257 6.792)">
<stop stopColor="#F28A2D" stopOpacity="0.502"/>
<stop offset="1" stopColor="#F28A2D" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint4_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.81132 3.048) scale(2.51528 2.568)">
<stop stopColor="#D45F00" stopOpacity="0.149"/>
<stop offset="1" stopColor="#F28A2D" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint5_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.3445 3.216) scale(2.49177 2.544)">
<stop stopColor="#D45F00" stopOpacity="0.149"/>
<stop offset="1" stopColor="#D45F00" stopOpacity="0"/>
</radialGradient>
<linearGradient id="paint6_linear_72_1262" x1="-2.96677" y1="28.0966" x2="-2.96677" y2="25.7176" gradientUnits="userSpaceOnUse">
<stop stopColor="#482314"/>
<stop offset="1" stopColor="#9A4111"/>
</linearGradient>
<radialGradient id="paint7_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.70576 6.4796) scale(2.52847 2.91161)">
<stop stopColor="#3B446B"/>
<stop offset="0.688" stopColor="#202340"/>
</radialGradient>
<radialGradient id="paint8_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.4527 6.47581) scale(2.64741 2.91161)">
<stop stopColor="#3B446B"/>
<stop offset="0.688" stopColor="#202340"/>
</radialGradient>
<radialGradient id="paint9_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.74064 2.67776) scale(1.65746 0.634321)">
<stop stopColor="#E38200"/>
<stop offset="1" stopColor="#CD6700"/>
</radialGradient>
<radialGradient id="paint10_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.3258 2.39664) scale(1.71245 0.667785)">
<stop stopColor="#E38200"/>
<stop offset="1" stopColor="#CD6700"/>
</radialGradient>
<linearGradient id="paint11_linear_72_1262" x1="-3.06189" y1="30.8201" x2="-3.38646" y2="29.6955" gradientUnits="userSpaceOnUse">
<stop stopColor="#F34462"/>
<stop offset="1" stopColor="#CC0820"/>
</linearGradient>
<radialGradient id="paint12_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.1733 16.0666) scale(4.39627 4.12356)">
<stop stopColor="#FF7091" stopOpacity="0.702"/>
<stop offset="1" stopColor="#FE6D8E" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint13_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.02367 13.9977) scale(4.39627 4.12356)">
<stop stopColor="#FF7091" stopOpacity="0.702"/>
<stop offset="1" stopColor="#FE6D8E" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint14_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.56731 17.365) scale(3.77258 3.53856)">
<stop stopColor="#9C0600"/>
<stop offset="1" stopColor="#9C0600" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint15_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.2045 17.7217) scale(2.26659 2.12599)">
<stop stopColor="#9C0600" stopOpacity="0.502"/>
<stop offset="1" stopColor="#9C0600" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint16_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.55534 13.2129) scale(1.96235 1.84062)">
<stop stopColor="#9C0600" stopOpacity="0.502"/>
<stop offset="1" stopColor="#9C0600" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint17_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.3254 20.7324) scale(2.64689 2.4827)">
<stop stopColor="#9C0600"/>
<stop offset="1" stopColor="#9C0600" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint18_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.224709 10.971) scale(13.03 10.4043)">
<stop stopColor="#EDA83A"/>
<stop offset="1" stopColor="#FFDC5E"/>
</radialGradient>
<radialGradient id="paint19_radial_72_1262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.3228 11.0652) scale(15.4893 13.8398)">
<stop stopColor="#EDA83A"/>
<stop offset="1" stopColor="#FFDC5E"/>
</radialGradient>
<clipPath id="clip0_72_1262">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
    )
}
export const Angry = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_72_1059)">
<path d="M23.9987 11.9995C23.9986 14.7756 23.036 17.4658 21.2749 19.6117C19.5137 21.7577 17.063 23.2266 14.3402 23.7681C11.6174 24.3097 8.79111 23.8904 6.34281 22.5818C3.89451 21.2731 1.97572 19.156 0.913364 16.5912C-0.148988 14.0264 -0.289165 11.1726 0.516715 8.51605C1.3226 5.85948 3.02467 3.56453 5.33293 2.02223C7.64119 0.479919 10.4128 -0.214317 13.1756 0.0578064C15.9383 0.32993 18.5212 1.55158 20.4842 3.51459C21.5984 4.62885 22.4823 5.95165 23.0853 7.40749C23.6883 8.86332 23.9987 10.4237 23.9987 11.9995Z" fill="url(#paint0_linear_72_1059)"/>
<path d="M7.61691 20.1657C7.61691 20.9924 9.57814 20.8604 11.9993 20.8604C14.4205 20.8604 16.3818 20.9937 16.3818 20.1657C16.3831 19.1698 14.4165 18.5205 11.9993 18.5205C9.58214 18.5205 7.61691 19.1698 7.61691 20.1657Z" fill="black"/>
<path d="M7.61691 20.1657C7.61691 20.9924 9.57814 20.8604 11.9993 20.8604C14.4205 20.8604 16.3818 20.9937 16.3818 20.1657C16.3831 19.1698 14.4165 18.5205 11.9993 18.5205C9.58214 18.5205 7.61691 19.1698 7.61691 20.1657Z" fill="#50000A"/>
<path d="M5.63435 14.734C5.57634 14.2088 5.72469 13.6815 6.04808 13.2636C6.37147 12.8457 6.8446 12.5698 7.36759 12.4941C7.89072 12.5695 8.36406 12.8453 8.68751 13.2633C9.01096 13.6813 9.15918 14.2087 9.10083 14.734C9.10764 15.2143 8.98317 15.6873 8.74085 16.1019C8.638 16.272 8.47304 16.3953 8.28087 16.4459C7.98342 16.5229 7.6775 16.5623 7.37026 16.5632C7.063 16.5624 6.75707 16.523 6.45964 16.4459C6.26722 16.3958 6.10209 16.2724 5.99966 16.1019C5.75554 15.6878 5.62922 15.2148 5.63435 14.734ZM14.8912 14.734C14.8329 14.2083 14.9815 13.6806 15.3055 13.2625C15.6295 12.8444 16.1035 12.5689 16.6271 12.4941C17.1501 12.5698 17.6232 12.8457 17.9466 13.2636C18.27 13.6815 18.4183 14.2088 18.3603 14.734C18.3652 15.2145 18.2394 15.6874 17.9963 16.1019C17.8939 16.2724 17.7288 16.3958 17.5364 16.4459C17.2389 16.523 16.933 16.5624 16.6257 16.5632C16.3185 16.5623 16.0126 16.5229 15.7151 16.4459C15.5226 16.3954 15.3572 16.2721 15.2538 16.1019C15.0112 15.6873 14.8858 15.2144 14.8912 14.734Z" fill="white"/>
<path d="M5.63435 14.734C5.57634 14.2088 5.72469 13.6815 6.04808 13.2636C6.37147 12.8457 6.8446 12.5698 7.36759 12.4941C7.89072 12.5695 8.36406 12.8453 8.68751 13.2633C9.01096 13.6813 9.15918 14.2087 9.10083 14.734C9.10764 15.2143 8.98317 15.6873 8.74085 16.1019C8.638 16.272 8.47304 16.3953 8.28087 16.4459C7.98342 16.5229 7.6775 16.5623 7.37026 16.5632C7.063 16.5624 6.75707 16.523 6.45964 16.4459C6.26722 16.3958 6.10209 16.2724 5.99966 16.1019C5.75554 15.6878 5.62922 15.2148 5.63435 14.734ZM14.8912 14.734C14.8329 14.2083 14.9815 13.6806 15.3055 13.2625C15.6295 12.8444 16.1035 12.5689 16.6271 12.4941C17.1501 12.5698 17.6232 12.8457 17.9466 13.2636C18.27 13.6815 18.4183 14.2088 18.3603 14.734C18.3652 15.2145 18.2394 15.6874 17.9963 16.1019C17.8939 16.2724 17.7288 16.3958 17.5364 16.4459C17.2389 16.523 16.933 16.5624 16.6257 16.5632C16.3185 16.5623 16.0126 16.5229 15.7151 16.4459C15.5226 16.3954 15.3572 16.2721 15.2538 16.1019C15.0112 15.6873 14.8858 15.2144 14.8912 14.734Z" fill="black"/>
<path d="M7.28626 13.9856C7.29379 14.022 7.29737 14.0591 7.29693 14.0963C7.29068 14.254 7.22224 14.4029 7.10656 14.5103C6.99089 14.6177 6.8374 14.675 6.67963 14.6696C6.52222 14.6746 6.36921 14.6172 6.25404 14.5097C6.13887 14.4023 6.0709 14.2537 6.06499 14.0963C6.06557 13.9702 6.10986 13.8481 6.19032 13.751C6.54097 13.8336 6.90895 13.9136 7.28626 13.9856ZM16.5617 14.6696C16.4091 14.675 16.2602 14.6215 16.1459 14.5203C16.0315 14.4191 15.9603 14.2778 15.9471 14.1256C16.3471 14.0656 16.7551 13.9923 17.147 13.9123C17.1685 13.9721 17.1789 14.0354 17.1777 14.099C17.1718 14.2562 17.1038 14.4047 16.9886 14.5119C16.8734 14.6192 16.7204 14.6763 16.5631 14.6709L16.5617 14.6696Z" fill="#4F4F67"/>
<path d="M13.4689 11.362C13.4689 10.6634 13.7089 10.4287 14.1235 10.4287C14.5382 10.4287 14.7328 10.8607 14.8675 12.098C16.4656 11.9441 18.049 11.6646 19.6032 11.262C19.8699 11.262 20.0099 11.3953 20.0699 11.6793C20.1013 11.8055 20.0857 11.9388 20.0258 12.0542C19.9658 12.1696 19.8658 12.2591 19.7445 12.3059C17.9055 13.1691 15.9164 13.6672 13.8875 13.7725C13.6409 13.7725 13.4689 13.6392 13.4689 13.3019V11.362ZM9.13047 12.0913C9.26513 10.8607 9.46245 10.4287 9.87443 10.4287C10.2864 10.4287 10.5291 10.67 10.5291 11.362V13.2979C10.5291 13.6339 10.3571 13.7685 10.1104 13.7685C8.08159 13.6632 6.09248 13.1651 4.25341 12.3019C4.13296 12.2552 4.03358 12.1663 3.97375 12.0518C3.91391 11.9372 3.89769 11.8049 3.92809 11.6793C3.98809 11.394 4.13341 11.262 4.3934 11.262C5.94802 11.6646 7.53194 11.9441 9.13047 12.098V12.0913Z" fill="black"/>
<path d="M13.4689 11.362C13.4689 10.6634 13.7089 10.4287 14.1235 10.4287C14.5382 10.4287 14.7328 10.8607 14.8675 12.098C16.4656 11.9441 18.049 11.6646 19.6032 11.262C19.8699 11.262 20.0099 11.3953 20.0699 11.6793C20.1013 11.8055 20.0857 11.9388 20.0258 12.0542C19.9658 12.1696 19.8658 12.2591 19.7445 12.3059C17.9055 13.1691 15.9164 13.6672 13.8875 13.7725C13.6409 13.7725 13.4689 13.6392 13.4689 13.3019V11.362ZM9.13047 12.0913C9.26513 10.8607 9.46245 10.4287 9.87443 10.4287C10.2864 10.4287 10.5291 10.67 10.5291 11.362V13.2979C10.5291 13.6339 10.3571 13.7685 10.1104 13.7685C8.08159 13.6632 6.09248 13.1651 4.25341 12.3019C4.13296 12.2552 4.03358 12.1663 3.97375 12.0518C3.91391 11.9372 3.89769 11.8049 3.92809 11.6793C3.98809 11.394 4.13341 11.262 4.3934 11.262C5.94802 11.6646 7.53194 11.9441 9.13047 12.098V12.0913Z" fill="#E38200"/>
</g>
<defs>
<linearGradient id="paint0_linear_72_1059" x1="11.9993" y1="0" x2="11.9993" y2="23.9987" gradientUnits="userSpaceOnUse">
<stop stopColor="#F18829"/>
<stop offset="1" stopColor="#FDD75A"/>
</linearGradient>
<clipPath id="clip0_72_1059">
<rect width="23.9987" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
    )
}
