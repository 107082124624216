import {NavLink} from 'react-router-dom'
const ResetPassword = () => {
    return (
        <>
           <div className="main-container auth-container auth-container-reset vh-100 d-flex">
             <div className="auth-images-container d-flex f-column">
            <header className="auth-header">

                <NavLink
                        to="/login"
                    >
                        <img src="./images/logo.png" alt=""/>

                    </NavLink>
                
            </header>
            <div className="auth-text-container">
                <h1>Let Story Drive</h1>
                <p>Let your facebook story drive your leads. Most powerfull
                    tool for your business.</p>
            </div>
            <div className="auth-assets-container">
                <div className="auth-fb-stories">
                    <img src="./images/auth-facebook.png" alt=""/>
                </div>
                <div className="auth-dots">
                    <img src="./images/auth-ellipse.png" alt=""/>
                </div>
                <div className="auth-waterfall">
                    <img src="./images/icon-auth-waterfall.png" alt=""/>
                </div>
                <div className="auth-dashboard-group">
                    <img className="auth-dashboard" src="./images/auth-image-dash.png" alt=""/>
                    <img className="auth-dashboard-icon-1" src="./images/auth-user-1.png" alt=""/>
                    <img className="auth-dashboard-icon-2" src="./images/auth-user-2.png" alt=""/>
                </div>
            </div>
        </div>
        <div className="auth-forms-container d-flex f-column">
            <div className="auth-forms-content reset-form-content">
                <h4 className="header-text text-blue">
                    Reset Password
                    <p>Enter the email address associated with your account and we’ll send you a link to reset your password.</p>
                </h4>
                <div className="form-container reset-form-container">
                    <form>
                        <div className="form-group">
                            <label className="form-label">Email address</label>
                            <div className="form-element">
                                <input className="form-control" type="text" placeholder="gautam.kumar@lsd.in"/>
                            </div>
                        </div>
                        <div className="form-group form-footer">
                            <button className="btn btn-theme btn-blue w-100">Send</button>
                            <div className="form-footer-addition">
                                {/* <p>Don't havn't account?   <a href="" className="text-blue">Sign up</a></p> */}
                            </div>
                        </div>
                    </form>
                </div>

                {/* <!-- <div className="auth-reset-success text-center">
                    <h2>Email have been sent !</h2>
                    <figure className="auth-success-icon">
                        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.375 25L50 50L90.625 25" stroke="#00C31F" stroke-width="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M84.375 68.75L71.875 81.25L65.625 75" stroke="#00C31F" stroke-width="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M75 96.875C87.0812 96.875 96.875 87.0812 96.875 75C96.875 62.9188 87.0812 53.125 75 53.125C62.9188 53.125 53.125 62.9188 53.125 75C53.125 87.0812 62.9188 96.875 75 96.875Z" stroke="#00C31F" stroke-width="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M53.4375 78.125H21.875C15 78.125 9.375 72.5 9.375 65.625V21.875C9.375 15 15 9.375 21.875 9.375H78.125C85 9.375 90.625 15 90.625 21.875V59.6875" stroke="#00C31F" stroke-width="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>                            
                    </figure>
                    <p>Please check your inbox and click in the received link to reset password</p>
                    <a href="./login.html" className="btn btn-theme btn-blue w-100">Back to Login</a>
                </div> --> */}
            </div>
            <footer className="auth-footer">
                <p>Powered by <a href="">Tier5</a> {"&"} <a href="">Genius</a></p>
            </footer>
        </div>
        </div>
        </>
    )
}

export default ResetPassword