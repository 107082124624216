import ErrorImage from "../../assets/images/ErrorImage.png"
import { useState } from 'react'
import '../../assets/style/style.css'
import {extensionID, name} from '../../Config/env'              



const NoExtensionMessage = (props) => {
    document.title = `${name} - Install the extension`;
    const [appName,setAppname] = useState(name)

    console.log("Facccee",props)
    const redirectToInstallUrl = () =>{
        window.location.href = `https://chrome.google.com/webstore/detail/${appName ? appName
        .toLowerCase()
        .split(" ")
        .join("-") : "let-story-drive"}/${extensionID}`
    }   
    
    return (
        <div className="errorPage">
            <figure>
                <img src={ErrorImage} alt="Error image" />
            </figure>

            <h1>Oops!</h1>
         {!props.facebookShit?<>
            <p>
                Seems like you have not installed  <a
                    style={{ color: "#347FFF", textDecoration: "underline" }}
                    target="_blank"
                    href={`https://chrome.google.com/webstore/detail/${appName ? appName
                    .toLowerCase()
                    .split(" ")
                    .join("-") : "let-story-drive"}/${extensionID}`}
                    rel="noreferrer"
                >
                   {appName}
                </a> Chrome Extension in your browser
                {/* <br/>
                <br/>
                If you loged in to Facebook, click on <strong>Connect</strong> to continue */}
            </p>

            {/* <p>If you loged in to Facebook, click on <strong>Connect</strong> to continue</p> */}

            <div className="button-footer">
                <button onClick={redirectToInstallUrl} className="redirectButton">
                    {/* <span className="ico-left">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5" clip-path="url(#clip0_508_3192)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 10V14C15 15.1046 14.1046 16 13 16H2C0.89543 16 0 15.1046 0 14V3C0 1.89543 0.89543 1 2 1H6V3H2V14H13V10H15ZM13.9971 3.41421L7.70421 9.70711L6.28999 8.29289L12.5829 2H8.9971V0H15.9971V7H13.9971V3.41421Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_508_3192">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span> */}
                    {/* Connect */}
                    Install Now - {appName}
                    <span className="ico-right">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5" clip-path="url(#clip0_508_3192)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 10V14C15 15.1046 14.1046 16 13 16H2C0.89543 16 0 15.1046 0 14V3C0 1.89543 0.89543 1 2 1H6V3H2V14H13V10H15ZM13.9971 3.41421L7.70421 9.70711L6.28999 8.29289L12.5829 2H8.9971V0H15.9971V7H13.9971V3.41421Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_508_3192">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button>
            </div><br></br></>:<><button onClick={()=>window.location.href="https://facebook.com"} className="redirectButton">
                    {/* <span className="ico-left">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5" clip-path="url(#clip0_508_3192)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 10V14C15 15.1046 14.1046 16 13 16H2C0.89543 16 0 15.1046 0 14V3C0 1.89543 0.89543 1 2 1H6V3H2V14H13V10H15ZM13.9971 3.41421L7.70421 9.70711L6.28999 8.29289L12.5829 2H8.9971V0H15.9971V7H13.9971V3.41421Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_508_3192">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span> */}
                    {/* Connect */}
                    
                   Please Login to Facebook
                    <span className="ico-right">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5" clip-path="url(#clip0_508_3192)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 10V14C15 15.1046 14.1046 16 13 16H2C0.89543 16 0 15.1046 0 14V3C0 1.89543 0.89543 1 2 1H6V3H2V14H13V10H15ZM13.9971 3.41421L7.70421 9.70711L6.28999 8.29289L12.5829 2H8.9971V0H15.9971V7H13.9971V3.41421Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_508_3192">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button></>}<br></br>
            <p> If you already have, then please  </p><br></br>
            <button onClick={()=>{window.location.reload(false);}} className="redirectButton">
                    {/* <span className="ico-left">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5" clip-path="url(#clip0_508_3192)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 10V14C15 15.1046 14.1046 16 13 16H2C0.89543 16 0 15.1046 0 14V3C0 1.89543 0.89543 1 2 1H6V3H2V14H13V10H15ZM13.9971 3.41421L7.70421 9.70711L6.28999 8.29289L12.5829 2H8.9971V0H15.9971V7H13.9971V3.41421Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_508_3192">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span> */}
                    {/* Connect */}
                     Click here 
                    <span className="ico-right">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5" clip-path="url(#clip0_508_3192)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 10V14C15 15.1046 14.1046 16 13 16H2C0.89543 16 0 15.1046 0 14V3C0 1.89543 0.89543 1 2 1H6V3H2V14H13V10H15ZM13.9971 3.41421L7.70421 9.70711L6.28999 8.29289L12.5829 2H8.9971V0H15.9971V7H13.9971V3.41421Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_508_3192">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button>

            {/* <footer>
                Powered by <a href="">Tier5</a> & <a href="">Genius</a>
            </footer> */}
        </div>
    );
}
 
export default NoExtensionMessage;
