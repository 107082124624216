module.exports = {
  connectionError:
    "Cannot connect to the server check your Internet connection and try again",
  authError: "You are not authenticated",
  invalidEmail: "Please enter a proper email-id",
  noEmailEntered: "Email-id is mandatory.",
  noPasswordEntered: "Password is mandatory",
  updatePasswordLengthError: "The new password must be at least 6 characters.",
  emailRequire: "Please enter your email-id to proceed",
  updatePasswordMismatched:
    "New password and confirm password do not match.",
  updatePasswordError: "Failed to update the password.",
  resetPasswordError: "Failed to reset your password.",
  loginError: "Please check the credentials carefully.",
  globalError: "Something went wrong. Please refresh the page and try again",
  exportCsvErr: "Failed to export CSV.",
  serviceError: "Request cannot be processed now", //Sending mail failed

  // Super user
  dashboardStatFetch: "Failed to fetch dashboard statistics.",
  createCeo: "Failed to create CEO",
  suspendCeo: "Failed to suspend CEO",
  unSuspendCeo: "Failed to un-suspend CEO",
  deleteCeo: "Failed to delete CEO",

  // CEO and Partners
  extUserLimit: {
    max: "The extension user limit is overshooting the range",
    min: "The extension user limit is below the range",
    invalid: "Please enter a valid balance to add a partner.",
  },
  inactiveCeo: "Your account has been suspended",
  invalidBalanceValue:
    "Please enter a valid balance value. (Must be a whole number and greater than 0)",
  invalidPartnerBalance:
    "Please enter a valid partner balance value. (Must be a whole number)",
  assignPartnerToExt: "Failed to assign the extension to partner",
  removeAssignPartnerToExt: "Failed to remove the extension from partner",
  invalidPhoneNumber: "Please enter a valid Phone number",
  manageCeoForWLExtension: "Failed to manage CEOs for the current extension.",

  invalidFile: "Seems that the file you uploaded is invalid or corrupted",
  extVersionMissing: "Please provide a valid version for your extension",
  extFileMissing: "Please provide a valid file for your extension",
  uploadExtension: "Failed to upload your extension. Please try again later",

  createPartner: "Failed to create partner.",
  deletePartner: "Failed to delete partner.",
  suspendPartner: "Failed to suspend partner",
  unSuspendPartner: "Failed to un-suspend partner",
  manageExtension: "Failed to manage extension for current partner",
  assignExtension: "Failed to manage extension for current CEO",

  noActiveExtension: "You don't have any active extension assigned.",

  createExtension: "Failed to create the extension.",
  createExtUser: "Failed to create extension user",
  suspendExtUser: "Failed to suspend extension user",
  unSuspendExtUser: "Failed to un-suspend extension user",
  deleteExtUser: "Failed to delete extension user",
  activateExtUser: "Failed to activate extension user",

  failedToFetchExtInfo: "Failed to show the extension details.",
  failedToFetchBroadcastList:
    "Failed to load broadcast list. Please try again later.",

  emptyDataExportError: "No record to export.",

  extensionLogo: {
    invalid: "The current file type is invalid",
    small: "The image size should be 16px X 16px",
    medium: "The image size should be 48px X 48px",
    large: "The image size should be 128px X 128px",
  },
  createWhitelabelExtension: {
    name: "Insert the name of the extension",
    small_icon: "Upload the small icon(16 X 16)",
    medium_icon: "Upload the medium icon(48 X 48)",
    large_icon: "Upload the large icon(128 X 128)",
    primary_logo: "Upload the primary logo",
    secondary_logo: "Upload the secondary logo",
    short_desc: "Add a short description",
    long_desc: "Add a long description",
    mailTo: "Add a Valid email id in Mail To",
    category: "Select one extension category",
    youtubeLink: "Enter a valid URL for the Youtube link",
    youtubeLink2: "Enter a valid URL for the 2nd Youtube link",
    signupURL: "Enter a valid URL for sign up URL",
    noPlan: "Please add at least one plan to continue",
    invalidPlanName: "Please provide a valid plan name",
  },
  //create ceo frontend validation error message
  userEmail: "Please provide a valid Email",
  userPhone: "Please enter a valid Phone number",
  extensionBalance: "Please enter a valid number to extension balance",
  partnerBalance: "Please enter a valid number to partner balance",
  endUserBalance: "Please enter a valid number end-user balance",

  invalidFileTypes: {
    json: "Invalid JSON file.",
    noFile: "Please select a JSON file to upload.",
  },

  // Stripe
  fetchStripeKey : "Failed to fetch the stripe keys.",
  stripeKeyName: "The stripe account name is required",
  stripeSecretKeyRequired: "A stripe secret key is required",
  stripePublicKeyRequired: "A stripe public key is required",
  automationNameRequired : "Automation Name is required",
  stripeAccountRequired : "Please select a stripe account.",
  stripeProductRequired : "Please select a stripe product.",
  stripePlanRequired : "Please select a stripe plan.",
  extensionsRequired:"Please select at least one extension to this automation.",
  // automationNameRequired : "Automation Name is required",
};
